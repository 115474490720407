import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import FloatingInput from '../../../common/floatingInput/FloatingInput';
import PostcodeLookup from '../../../common/addressLookup/PostcodeLookup';
import * as userAddressActions from '../../../../actions/userAddressActions';
import Spinner from '../../../common/spinner/Spinner';
import { updateUserAddress } from '../../../../api/userAccountApi';

class EditAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddAddressPanel: false,
      alias: { value: props.address.alias, error: '' },
      addressLine1: { value: props.address.addressLine1, error: '' },
      addressLine2: { value: props.address.addressLine2, error: '' },
      county: { value: props.address.county, error: '' },
      town: { value: props.address.town, error: '' },
      postcode: { value: props.address.postcode, error: '' },
      deliveryInstructions: { value: props.address.deliveryInstructions, error: '' },
      newAddressError: '',
      addressSearched: false,
      manualAddressEntry: false,
      clearDownAddress: false,
      savingAddress: false,
    };

    this.onInputFieldChanged = this.onInputFieldChanged.bind(this);
    this.onAddressSelected = this.onAddressSelected.bind(this);
    this.onSaveAddress = this.onSaveAddress.bind(this);
  }

  resetNewAddress() {
    this.setState({
      showAddAddressPanel: false,
      alias: { value: '', error: '' },
      addressLine1: { value: '', error: '' },
      addressLine2: { value: '', error: '' },
      county: { value: '', error: '' },
      town: { value: '', error: '' },
      postcode: { value: '', error: '' },
      deliveryInstructions: { value: '', error: '' },
      newAddressError: '',
      addressSearched: false,
      manualAddressEntry: false,
      clearDownAddress: false,
      savingAddress: false,
    });
  }

  onInputFieldChanged(name, value) {
    this.setState({
      [name]: { value: value, error: false },
    });
  }

  onAddressSelected(address) {
    let { addressLine1, addressLine2, county, town, postcode } = this.state;
    addressLine1.value = address.Line1;
    addressLine2.value = address.Line2;
    town.value = address.City;
    county.value = address.ProvinceName;
    postcode.value = address.PostalCode;
    this.setState({ addressLine1, addressLine2, county, town, postcode });
  }

  onSaveAddress() {
    let address = this.props.address;
    let { addressLine1, addressLine2, county, town, postcode, alias, deliveryInstructions } = this.state;

    // Check an address was entered.
    if (addressLine1.value.length <= 0 && postcode.value.length <= 0) {
      this.setState({ newAddressError: 'Please enter address details.' });
      return;
    }

    address.alias = alias.value;
    address.deliveryInstructions = deliveryInstructions.value;
    address.addressLine1 = addressLine1.value;
    address.addressLine2 = addressLine2.value;
    address.town = town.value;
    address.county = county.value;
    address.postcode = postcode.value;

    this.editAddress(address);
  }

  editAddress(address) {
    this.setState({ savingAddress: true });
    updateUserAddress(address)
      .then(response => {
        if (response.status === 200) {
          if (response.data.Success) {
            toastr.success('Address was updated successfully');
            this.props.updateAddress(address);
            this.resetNewAddress();
            this.props.onAddressSaved();
          } else {
            toastr.error(response.data.ErrorMessage);
          }
        } else {
          toastr.error('Failed to update address. Please try again');
        }
        this.setState({ savingAddress: false });
      })
      .catch(error => {
        console.log('Unable to update address:', error);
        toastr.error('Failed to update address. Please try again');
        this.setState({ savingAddress: false });
      });
  }

  render() {
    const { newAddressError, savingAddress, alias, addressLine1, addressLine2, county, town, postcode } = this.state;

    return (
      <div className="edit-address">
        <FloatingInput
          label="Name (e.g. Home Work)"
          type="text"
          name="alias"
          error={alias.error}
          value={alias.value}
          inputChanged={this.onInputFieldChanged} />
        <PostcodeLookup 
          address={{ postcode: postcode }} 
          buttonTitle="Look it up"
          inverseButton
          includeExistingAddresses={false}
          clearDownAddress={this.state.clearDownAddress}
          onAddressSearched={() => this.setState({ addressSearched: true })} 
          onSelect={this.onAddressSelected}
          showPostcodeLookup={true}
        />

        {/* Manual address entry */}
        <div className="manual-address-fields">
          <div className="form-group">
            <FloatingInput
              label="Address line 1"
              type="text"
              name="addressLine1"
              error={addressLine1.error}
              value={addressLine1.value}
              inputChanged={this.onInputFieldChanged} />
          </div>
          <div className="form-group">
            <FloatingInput
              label="Address line 2"
              type="text"
              name="addressLine2"
              error={addressLine2.error}
              value={addressLine2.value}
              inputChanged={this.onInputFieldChanged} />
          </div>
          <div className="form-group">
            <FloatingInput
              label="Town"
              type="text"
              name="town"
              error={town.error}
              value={town.value}
              inputChanged={this.onInputFieldChanged} />
          </div>
          <div className="form-group">
            <FloatingInput
              label="County"
              type="text"
              name="county"
              error={county.error}
              value={county.value}
              inputChanged={this.onInputFieldChanged} />
          </div>
        </div>
        <div className={`alert alert-danger text-center ${newAddressError.length <= 0 ? 'hide' : ''}`}>
          {newAddressError}
        </div>
        <div className="form-group save-address">
          <button className="btn btn-primary btn-block spinner-button primary-button"
            onClick={this.onSaveAddress}>
            <span className="text">Save</span>
            <Spinner show={savingAddress} />
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAddress: address => dispatch(userAddressActions.updateAddress(address)),
  };
};

export default connect(null, mapDispatchToProps)(EditAddress);