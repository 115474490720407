import {
  GET_USER_DATA_REQUEST,
  GET_USER_DATA_ERROR,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  GET_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_LOCALLY,
  RESET_PASSWORD_SUCCESS,
  LOGOUT_SUCCESS,
  ACTIVATE_USER_REQUEST,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_ERROR,
} from '../actions/userActions';

const initialState = {
  loading: false,
  error: '',
  data: {
    isAuthenticated: false,
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_USER_DATA_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_USER_DATA_ERROR: {
    let errorMessage = 'An error was encountered on your last request. Please try again.';
    if (!action.error && action.payload && action.payload.status <= 200) {
      errorMessage = action.payload.message;
    }
    return {
      ...state,
      error: errorMessage,
      loading: false,
    };
  }
  case GET_USER_DATA_SUCCESS: {
    const User = action.payload;

    return {
      ...state,
      data: {
        ...User,
        isAuthenticated: User ? true : false,
      },
      loading: false,
    };
  }
  case ACTIVATE_USER_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case ACTIVATE_USER_ERROR: {
    let errorMessage = 'An error was encountered on your last request. Please try again.';
    if (!action.error && action.payload && action.payload.status <= 200) {
      errorMessage = action.payload.message;
    }
    return {
      ...state,
      error: errorMessage,
      loading: false,
    };
  }
  case ACTIVATE_USER_SUCCESS: {
    const Success = action.payload;
    return {
      ...state,
      data: {
        isActivated: Success ? true : false,
      },
      loading: false,
    };
  }
  case LOGIN_SUCCESS: {
    const { ErrorMessage, Token, Success, User } = action.payload;
    if (Success && Token) {
      sessionStorage.setItem('token', Token);
    }

    return {
      ...state,
      data: {
        ...User,
        isAuthenticated: Success ? true : false,
      },
      loading: false,
      error: ErrorMessage,
    };
  }
  case REGISTER_SUCCESS: 
  case UPDATE_USER_DATA_SUCCESS: {
    const { ErrorMessage } = action.payload;
    return {
      ...state,
      loading: false,
      error: ErrorMessage,
    };
  }
  case RESET_PASSWORD_SUCCESS: {
    return {
      ...state,
      loading: false,
    };
  }
  case UPDATE_USER_DATA_LOCALLY: {
    return {
      ...state,
      data: action.payload,
      loading: false,
      error: '',
    };
  }
  case LOGOUT_SUCCESS: {
    return {
      ...initialState,
      loading: false,
    };
  }
  default: {
    return state;
  }
  }
};

export default userReducer;