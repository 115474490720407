import React from 'react';
import AboutUs from './panels/aboutUs/AboutUs';
import Delivery from './panels/delivery/Delivery';
import JoinNow from './panels/joinNow/JoinNow';
import Products from './panels/products/Products';
import Quality from './panels/quality/Quality';
import Gallery from './panels/gallery/Gallery';
import ContactUs from './panels/contactUs/ContactUs';

const HomepageLoggedOut = () => {
  return (
    <div className="home-page logged-out">
      <JoinNow />
      <AboutUs />
      <Quality />
      <Delivery />
      <Products />
      <Gallery />
      <ContactUs />
    </div>
  );
};

export default HomepageLoggedOut;