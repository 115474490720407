import React from 'react';
import './AppDialog.css';

const AppDialog = ({
  title, 
  message, 
  children, 
  disable,
  onConfirm, 
  acceptBtnText = 'Confirm', 
  declineBtnText = 'Cancel' }) => (
  <div className="app-dialog-wrapper">
    <div className="app-dialog">
      <div className="dialog-title">{title}</div>
      <div className="dialog-content">{message && message.length > 0 ? message : children}</div>
      <div className="dialog-footer">
        <button className="btn inverse"
          disabled={disable}
          onClick={() => onConfirm(false)}>{declineBtnText}</button>
        <button className="btn inverse-button"
          disabled={disable}
          onClick={() => onConfirm(true)}>{acceptBtnText}</button>
      </div>
    </div>
  </div>
);

export default AppDialog;