import { RSAA } from 'redux-api-middleware';
import moment from 'moment';
import store from '../store/configureStore';
import apiRequestService from '../services/apiRequestService';

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export const getProducts = () => {
  const state = store().getState();
  
  // If this is not the first view of website, check last updated and call api if cache has expired.
  if (state.products.data.length > 0 && state.products.lastUpdated) {
    // Get current timestamps to compare.
    const timeNow = moment();
    const lastUpdated = moment(state.products.lastUpdated);

    // Check last updated is not greater than 24 hours ago.
    if (timeNow.diff(lastUpdated, 'hours') < 24) {
      return (dispatch) => {
        dispatch({
          type: GET_PRODUCTS_SUCCESS,
          payload: state.products.data,
        });
      };
    }
  }

  const headers = apiRequestService.getHeaders();

  return {
    [RSAA]: {
      endpoint: apiRequestService.getEndpoint('GetProducts'),
      method: 'GET',
      headers: headers,
      types: [
        GET_PRODUCTS_REQUEST,
        GET_PRODUCTS_SUCCESS,
        GET_PRODUCTS_ERROR,
      ],
    },
  };
};