import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import toastr from 'toastr';
import * as commonActions from '../../../actions/commonActions';
import Spinner from '../../common/spinner/Spinner';
import { addToCart, updateDeliveryDetails, removeFromCart, clearShoppingCart } from '../../../actions/shoppingCartAction';
import { openMiniBasket } from '../../../actions/miniBasketAction';
import Slider from 'react-slick';
import './ProductDetail.css';
import ProductQuantity from '../productQuantity/ProductQuantity';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { IoIosArrowBack } from 'react-icons/io';
import 'react-tabs/style/react-tabs.css';
import $ from 'jquery';
import priceUtils from '../../../shared/priceUtils';

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50px', height: '50px', borderRadius: '50%' }}
      onClick={onClick}
    ><span className="chevron-arrow-right-icon" /></div>
  );
}
  
function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50px', height: '50px', borderRadius: '50%' }}
      onClick={onClick}
    ><span className="chevron-arrow-left-icon" /></div>
  );
}

class ProductDetail extends Component {
  constructor(){
    super();

    this.state = {
      shoppingCart: {
        deliveryDate: '',
        items: [],
      },
      dialog: {
        title: '',
        message: '',
        type: '',
        item: {},
        isOpen: false,
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $('.pricePer').html($('.pricePer').text().replace(/(^\w+)/,'<b>$1</b>'));
  }

  resetDialog() {
    this.setState({
      dialog: {
        title: '',
        message: '',
        type: '',
        isOpen: false,
      },
    });
  }

  onDialogConfirm(status) {
    if (!status) {
      // Clear Shopping cart and continue.
      this.props.clearShoppingCart()
        .then(resp => {
          if (resp.payload && resp.payload.Success) {
            this.resetDialog();
          } else {
            toastr.error(resp.payload.ErrorMessage);
          }
        })
        .catch(() => {
          toastr.error('Unable to clear your shopping cart. Please try again or contact support.');
        });
    } else {
      this.props.history.push(`${process.env.PUBLIC_URL}/catalogue`);
    }
  }

  doAddToCart(params, orderID) {
    this.props.addToCart(params, orderID)
      .then(resp => {
        if (resp.payload.Success) {
          toastr.success('Your basket has been updated.');
        } else if (resp.payload.ErrorMessage) {
          toastr.error(resp.payload.ErrorMessage);
        }
      });
  }

  addToBasket(productOptionId, quantity) {
    this.props.addToBasket({
      productOptionId: productOptionId,
      quantity: quantity,
    },
    this.props.shoppingCart.orderId,
    );
  }

  doRemoveFromCart(productId) {
    this.props.removeFromCart(productId)
      .then((resp) => {
        if (resp.payload.Success) {
          toastr.success(`${this.props.product.title} has been removed from your basket.`);
        } else if (resp.payload.ErrorMessage) {
          toastr.error(resp.payload.ErrorMessage);
        }
      });
  }

  onUpdateRecipe(product, action) {
    const { shoppingCart } = this.state;
    let cartItems = [...shoppingCart.items];
    // Check product already exist in cart.
    const existingProduct = cartItems.find(item => item.recipeBoxID === product.recipeBoxID) || null;
    switch(action) {
    case 'decrease': {
      if (existingProduct) {
        cartItems = cartItems.map(item => {
          if (item.recipeBoxID === product.recipeBoxID) {
            item.quantity -= 1;
          }
          return item;
        }).filter(item => item.quantity > 0);
      }
      break;
    }
    case 'increase': {
      if (existingProduct) {
        cartItems = cartItems.map(item => {
          if (item.recipeBoxID === product.recipeBoxID) {
            item.quantity += 1;
          }
          return item;
        });
      }
      break;
    }
    case 'add': {
      if (!existingProduct) {
        cartItems.push({ ...product, quantity: 1 });
      }
      break;
    }
    default: {
      break;   
    }
    }

    this.setState({
      shoppingCart: {
        ...shoppingCart,
        items: cartItems,
      },
    });
  }

  getItemQuantityInCart() {
    const { shoppingCart, product } = this.props;
    const existingProduct = shoppingCart.items && (shoppingCart.items.find(item => item.recipeBoxID === product.recipeBoxID) || null);
    return existingProduct ? existingProduct.quantity : 0;
  }

    
  getTotalQuantityInBasket() {
    const { shoppingCart } = this.props;

    return shoppingCart.items.reduce((a, b) => {
      return a + b.quantity;
    }, 0);
  }

  isItemAddedToCart() {
    const { product } = this.props;
    return this.getItemQuantityInCart(product) > 0;
  }

  render() {
    const { loading, product, isAddedToCart, quantityInCart, user } = this.props;
    const { shoppingCart } = this.state;

    let sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      draggable: false,
      autoplay: false,
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />,
    };

    return (
      <div>
        <div className={`${user.isAuthenticated ? 'recipe-detail-wrapper logged-in' : 'recipe-detail-wrapper'} ${product ? product.productId : ''}`}>
          <div className="product-detail-banner"></div>
          {loading &&
                    <div className="spinner-wrapper"
                      style={{ padding: '30px' }}>
                      <Spinner show/>
                    </div>
          }
          {!loading && !product &&
                    <div className="recipe-not-found">
                      <h1 className="text-muted text-center"
                        style={{ margin: '60px 0 30px' }}>Product not found</h1>
                      <div className="text-center">
                        <NavLink to={`${process.env.PUBLIC_URL}/catalogue`}
                          className="home-button"
                          style={{ margin: 'auto' }}>Discover all products</NavLink>
                      </div>
                    </div>
          }
          {product && product.productId &&
                    <div className='recipe-detail-container container-fluid'>
                      <button className="btn back-btn"
                        onClick={() => this.props.history.goBack()}>
                        <IoIosArrowBack/>
                            &nbsp;&nbsp;Back
                      </button>
                      <div className="container">
                        <div className="left">
                          <div className="image-name-wrapper">
                            <div className="recipe-image-wrapper">
                              {product.allImageUrls &&
                                            <Slider {...sliderSettings}>
                                              {product.allImageUrls.map((img, index) => (
                                                <img key={index}
                                                  src={img}
                                                  alt={product.title}/>
                                              ))}
                                            </Slider>
                              }
                              {product.roundelImage && 
                                            <img src={product.roundelImage}
                                              className={`roundel-banner ${product.friendlyUrl}`}
                                              alt="new banner" />
                              }
                            </div>
                          </div>   
                        </div>
                        <div className="right">
                          <div className="title-wrapper">
                            <div className="title-left">
                              <h4>Category</h4>
                              <h1>{product.title}</h1>
                              <div className="recipe-info">
                                <div className="recipe-description">
                                  <p dangerouslySetInnerHTML={{ __html: product.description }} />
                                </div>
                                <p>SKU: {product.productCode}</p>
                              </div>

                            </div>
                            <div className="recipe-price">
                              <span>&pound;{product.price}</span> 
                            </div>
                          </div>
                          <div className="recipe-details">
                            <div className="info-left">
                              {product.ingredients && <p><span className="info-title">Ingredients:</span> {product.ingredients}</p>}
                              {product.body && <span><span className="info-title">Description:</span><p dangerouslySetInnerHTML={{ __html: product.body }} /></span>}
                              {product.origin && <span><span className="info-title">Origin:</span><p>{product.origin}</p></span>}
                              {product.pricePer && <p><span className="info-title">Price Per </span><span className="pricePer">{product.pricePer}</span></p>}
                              {product.weight && <p><span className="info-title">Weight: </span>{product.weight}</p>}
                            </div>
                            <div className="info-right">
                              <ProductQuantity
                                buttonText="Add to Order"
                                loading={loading}
                                product={product}
                                shoppingCart={shoppingCart}
                                onAddToCart={(productOptionId, quantity) => this.addToBasket(productOptionId, quantity)}
                                onRemoveFromCart={(product) => 
                                  this.doRemoveFromCart(product)}
                                doLogin={() => 
                                  this.props.toggleModal({ open: true, type: 'login' })}
                                isAddedToCart={isAddedToCart}
                                quantityInCart={quantityInCart}
                              />
                            </div>
                          </div> 
                          <div className="info-tabs">
                            <Tabs id="info-tabs">
                              <TabList>
                                <Tab key="storage">Storage</Tab>
                                <Tab key="cooking-instrctions">Cooking Instructions</Tab>
                                <Tab key="dietary-requiremets">Dietary Requirements</Tab>
                                <Tab key="allergy-advice">Allergy Advice</Tab>
                              </TabList>
                              <TabPanel key="storage">
                                <p>Keep refrigerated. 
                                            Once opened consume within 24 hours.<br/> 
                                            Suitable for home freezing.<br/>
                                            Freeze on day of purchase and use within 1 month. To defrost allow to stand in the refrigerator for approximately 8 hours. Ensure thoroughly defrosted before consumption. Once thawed do not refreeze.</p>
                              </TabPanel>
                              <TabPanel key="cooking-instrctions">
                                <p><span className="info-title">Cooking Time: </span>{product.cookingTime}</p>
                              </TabPanel>
                              <TabPanel key="dietary-requiremets">
                                <p><span className="info-title">Nutritional EnergyKJ PerPortion: </span>{product.nutritionalEnergyKJPerPortion}</p>
                                <p><span className="info-title">Nutritional EnergyKCAL PerPortion: </span>{product.nutritionalEnergyKCALPerPortion}</p>
                                <p><span className="info-title">Protein: </span>{product.protein}</p>
                                <p><span className="info-title">Dairy: </span>{product.dairy}</p>
                              </TabPanel>
                              <TabPanel key="allergy-advice">
                                <p><span className="info-title">Allergens: </span>{product.allergens}</p>
                                <p>{product.importantInformation}</p>
                              </TabPanel>
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </div>
          }
          {
            this.props.shoppingCart.itemsCount > 0 &&
            <div className="mobile-view-basket-button">
              <button 
                className="btn"
                onClick={() => this.props.openMiniBasket()}
              >
                View Basket
                {
                  this.props.shoppingCart.items.length > 0 && <> - {priceUtils.FormatCurrencyPence(this.props.shoppingCart.orderTotal)}</> 
                }
              </button>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const friendlyUrl = ownProps.match.params['id'] || 0;
  const products = state.products.data || [];

  const product = products.find(prod => prod.friendlyUrl === friendlyUrl || (prod.otherFriendlyUrl && prod.otherFriendlyUrl.indexOf(friendlyUrl) > -1));
  if (product && product.title) {
    document.title = product.title;
  }
  return {
    product,
    products,
    shoppingCart: state.foodBoxesShoppingCart.data,
    loading: state.products.loading || state.foodBoxesShoppingCart.loading,
    user: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToBasket: (params, orderID) => dispatch(addToCart(params, orderID)),
    updateDeliveryDetails: (params) => dispatch(updateDeliveryDetails(params)),
    removeFromCart: (id) => dispatch(removeFromCart(id)),
    onShowBasketTray: (status) => dispatch(commonActions.showBasketTray(status)),
    toggleModal: (modal) => dispatch(commonActions.onModalToggle(modal)),
    clearShoppingCart: () => dispatch(clearShoppingCart()),
    openMiniBasket: (shoppingCart) => dispatch(openMiniBasket(shoppingCart)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);