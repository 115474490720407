import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateActiveNav } from '../../actions/navigationAction';
import { contactUs } from '../../actions/contactUsActions';
import toastr from 'toastr';
import FloatingInput from '../../components/common/floatingInput/FloatingInput';
import FloatingTextArea from '../../components/common/floatingTextAra/FloatingTextArea';
import Spinner from '../../components/common/spinner/Spinner';
import './ContactUs.css';

class ContactUs extends Component {
  constructor() {
    super();

    this.state = {
      open: true,
      activeKey: '',
      subject: { value: '', error: '' },
      message: { value: '', error: '' },
    };  
    
    this.handleSelect = this.handleSelect.bind(this);
    this.onInputFieldChanged = this.onInputFieldChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.updateActiveNav(this.props.location);
    document.title = 'Contact Us - Big Blue Sea';
    let hash = this.props.location.hash.replace('#', '');
    if (hash) {
      const element = document.querySelector(`#${hash}`);
      if (element) {
        this.setState({ activeKey: hash });
        element.scrollIntoView();
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  onInputFieldChanged(name, value) {
    this.setState({
      [name]: { value: value, error: false },
    });
  }

  handleValidation() {
    const { subject, message } = this.state;

    let errorCount = 0;

    if (subject.value.length <= 0) {
      subject.error = 'Subject is required';
      errorCount += 1;
    }
    else if(subject.value.length > 75){
      subject.error = 'Subject cannot exceed 75 characters';
      errorCount += 1;
    } 

    if (message.value.length <= 0) {
      message.error = 'Message is required';
      errorCount += 1;
    } 
    else if(message.value.length > 500){
      message.error = 'Subject cannot exceed 500 characters';
      errorCount += 1;
    } 

    this.setState({ subject, message });

    return errorCount <= 0;
  }

  onSubmit(e) {
    e.preventDefault();     
    if (this.handleValidation()) {
      const { subject, message } = this.state;
      if(subject && message){
        let props = {
          toEmail: 'contact@bigbluesea.com',
          subject: subject.value,
          message: message.value,
        };
        this.props.contactUs(props)
          .then((response) => {
            const { Success } = response.payload;
            if (Success) { 
              toastr.success('Message successfully sent. We will be in touch.');
            }
            else {
              toastr.error('Something went wrong. Please try again later.');
            }
          })
          .catch((error) => {
            console.log('There was an error with your request', error);
          });
      }
    }
  }

  render() {
    const { subject, message } = this.state;
    const { loading } = this.props;
    return (
      <div className="contact-us">
        <div className="contact-banner"></div>
        <div className="contact-us-content container">
          <div className="contact-us-header">
            <h4>Contact info</h4>
            <h1>Get in touch</h1>
          </div>
          <div className="container">
            <div className="left">
              <div className="map-wrapper"></div>
              <div className="map-details">
                <h4>International Seafoods t/a Big Blue Seafood</h4>
                <p id="phone">+44 01472 256801</p>
                <p id="email">sales@bigblueseafood.co.uk</p>
                <p>Lakeside, Europarc,</p> 
                <p>Healing, Grimsby</p> 
                <p>DN37 9TW</p>
              </div>
            </div>
            <div className="right">
              <p>Please enter your contact details and a short message below and we will answer your query as soon as possible.</p>
              <form name="contactUsForm"
                onSubmit={this.onSubmit}>
                <FloatingInput
                  label="Subject"
                  type="text"
                  name="subject"
                  error={subject.error}
                  value={subject.value}
                  maxLength={75}
                  inputChanged={this.onInputFieldChanged} 
                />
                <FloatingTextArea
                  label="Message"
                  type="text"
                  name="message"
                  error={message.error}
                  value={message.value}
                  maxLength={500}
                  inputChanged={this.onInputFieldChanged} 
                />
                <div className="button-wrapper">
                  <button type="submit"
                    className="submit-btn">
                    <span className="text">Send</span>
                    <Spinner show={loading} />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    contactUs: (params) => dispatch(contactUs(params)),
    updateActiveNav: (nav) => dispatch(updateActiveNav(nav)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);