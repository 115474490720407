import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { activate } from '../../actions/userActions';
import Logo from '../../assets/images/login-logo.png';
import OrderlyLogo from '../../assets/images/orderly_logo.png';
import { toggleHeaderVisibility } from '../../actions/headerAction';

// Stylesheet
import './ActivateUser.css';

class ActivateUser extends Component {

  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();

    this.state = {
      isVerified: false,
    };
  }

  componentDidMount() {
    this.props.toggleHeaderVisibility(false);
    // eslint-disable-next-line no-useless-escape
    let name = 'token'.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(window.location.href);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    let token = decodeURIComponent(results[2].replace(/\+/g, ' '));

    let credentials = {
      token: token,
    };

    this.props.activate(credentials)
      .then((resp) => {
        const { Success } = resp.payload;
        if (Success) {
          this.setState({ isActivated: true });
        }
      });
  }

  componentWillUnmount() {
    this.props.toggleHeaderVisibility(true);
  }

  render() {
    const { isActivated } = this.state;
    return (
      <div id="createAccount"
        className="bbs-activate">
        <div className="activate-container">
          <div className="left">
            <img src={Logo} />
            <div className="text">
              <h2>Wholesale Ordering Portal</h2>
              <h4>Delivering Quality Sustainable Seafood to Businesses</h4>
            </div>
            <p className="orderly-info">
              Powered by Orderly. Our supply chain technology drives responsibility, generating long-term economic value and sustainability.
              <a href="https://orderly.io/"
                className="login-link"
                target="blank"> Learn More About Orderly.</a>
            </p>
          </div>
          <div className="right">
            <div className="activate-panel">
              { 
                isActivated &&
                <h3>Account Activated Successfully</h3>
              }
              {
                !isActivated &&
                <p>Please wait...</p>
              }
              { 
                isActivated &&    
                <p>Your account has been successfully activated.</p>
              }
              { 
                isActivated && 
                <p className="login-action">
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/login`}
                  >
                    Login
                  </NavLink>
                </p>
              }
              <input type="text"
                id="scrollOnError"
                style={{ opacity: 0, innerHeight: 0 }} />
            </div>
            <div className="orderly-logo">
              <a href="https://orderly.io/"
                className="login-link"
                target="blank">                         
                <img src={OrderlyLogo}
                  alt="Orderly logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activate: credentials => dispatch(activate(credentials)),
    toggleHeaderVisibility: (status) => dispatch(toggleHeaderVisibility(status)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivateUser));