import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import AppDialog from '../../../common/dialog/AppDialog';
import Spinner from '../../../common/spinner/Spinner';
import { deleteUserAddress } from '../../../../api/userAccountApi';
import * as userAddressActions from '../../../../actions/userAddressActions';
import EditAddress from './EditAddress';

class AddressItem extends Component {
  constructor() {
    super();

    this.state = {
      editMode: false,
      deletingAddress: false,
      dialog: {
        title: '',
        message: '',
        item: { id: '', meals: '' },
        isOpen: false,
      },
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.onDialogConfirm = this.onDialogConfirm.bind(this);
    this.resetDialog = this.resetDialog.bind(this);
    this.confirmDeleteAddress = this.confirmDeleteAddress.bind(this);
  }

  toggleEditMode() {
    this.setState({ editMode: !this.state.editMode });
  }

  resetDialog() {
    this.setState({
      dialog: {
        title: '',
        message: '',
        item: '',
        isOpen: false,
      },
    });
  }

  onDialogConfirm(e) {
    if (e) {
      this.deleteAddress();
    }
    this.resetDialog();
  }

  confirmDeleteAddress() {
    let dialog = this.state.dialog;
    dialog.title = 'Delete address';
    dialog.message = 'Are you sure you want to delete this address?';
    dialog.item = this.props.address.addressID;
    dialog.isOpen = true;
    this.setState({ dialog: dialog });
  }

  deleteAddress() {
    this.setState({ deletingAddress: true });
    deleteUserAddress({ addressID: this.props.address.addressID })
      .then(response => {
        if (response.status === 200) {
          if (response.data.Success) {
            toastr.success('Address removed successfully');
            this.props.deleteAddress(this.props.address.addressID);
          } else {
            toastr.error(response.data.ErrorMessage);
          }
        } else {
          toastr.error('Unable to delete this address. Please try again later.');
        }
        this.setState({ deletingAddress: false });
      })
      .catch(error => {
        console.log('Error deleting address:', error);
        toastr.error('Unable to delete this address. Please try again later.');
        this.setState({ deletingAddress: false });
      });
  }

  render() {
    const { editMode, deletingAddress, dialog } = this.state;
    const { address } = this.props;

    return (
      <div className="account-addresses">
        <p>
          {address.alias}
        </p>
        <div className="address-wrapper">
          <div className="icon">
            <span className="icon-home" />
          </div>
          <div className="entry">
            {address.addressLine1 && <span>{address.addressLine1},&nbsp;&nbsp;</span>}
            {address.addressLine2 && <span>{address.addressLine2},&nbsp;&nbsp;</span>}
            {address.town && <span>{address.town},&nbsp;&nbsp;</span>}
            {address.county && <span>{address.county},&nbsp;&nbsp;</span>}
            {address.postcode && <span>{address.postcode}.</span>}
            {address.defaultAddress && 
                            <strong style={{ marginLeft: '5px' }}>(Default Address)</strong>
            }
          </div>
        </div>
        <div className="edit-address-wrapper">
          <div className="button-wrapper">
            <button className={`btn btn-block inverse-button ${editMode ? 'bg-error' : ''}`}
              onClick={this.toggleEditMode}>
              {editMode ? 'Cancel' : 'Edit'}
            </button>
            <button className="btn btn-block inverse-button spinner-button"
              disabled={editMode || address.defaultAddress}
              onClick={this.confirmDeleteAddress}>
              <span className="text">Delete</span>
              <Spinner show={deletingAddress} />
            </button>
          </div>
          {editMode &&
                        <EditAddress address={address} 
                          onAddressSaved={() => this.setState({ editMode: false })} />
          }
        </div>
        {dialog.isOpen && 
                    <AppDialog title={dialog.title}
                      message={dialog.message}
                      onConfirm={this.onDialogConfirm} />
        } 
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAddresses: state.userAddresses,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteAddress: addressID => dispatch(userAddressActions.deleteAddress(addressID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressItem);