import React, { Component } from 'react';
import { connect } from 'react-redux';
import './AccountDetailsPage.css';
import CollapsiblePanel from '../../common/collapsiblePanel/CollapsiblePanel';
import PersonalDetail from './personalDetails/PersonalDetail';
import PasswordUpdate from './passwordUpdate/PasswordUpdate';
import AccountNameField from './personalDetails/AccountNameField';
import AccountTelephoneField from './personalDetails/AccountTelephoneField';
import * as userAccountActions from '../../../actions/userActions';
import * as userAddressActions from '../../../actions/userAddressActions';
import ProfileImage from './personalDetails/profileImage/ProfileImage';

class AccountDetailsPage extends Component {

  render() {

    return (
      <div className="details-wrapper">
        <CollapsiblePanel title="Personal details">
          <ProfileImage />
          <AccountNameField />
          <AccountTelephoneField />
          <PersonalDetail type="email" />
          <PasswordUpdate />
        </CollapsiblePanel>
      </div>
    );
  }
}

const mapDispatchToProps =(dispatch) => {
  return {
    getCurrentUser: () => dispatch(userAccountActions.getUserData()),
    getAddresses: () => dispatch(userAddressActions.fetchAddresses()),
  };
};

export default connect(null, mapDispatchToProps)(AccountDetailsPage);
