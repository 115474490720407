import React, { Component } from 'react';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import './ItemSelect.css';

class ItemSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      items: this.props.items,
      showItems: false,
      selectedAddressId: '',
    };
    this.onItemSelected = this.onItemSelected.bind(this);
  }

  handleClickOutside() {
    this.setState({ showItems: false });
  }

  onItemSelected(e) {
    this.props.addressSelected(e.target.dataset.id);
    this.setState({
      title: `${e.target.dataset.text} ${e.target.dataset.description}`,
      showItems: false,
      selectedAddressId: e.target.dataset.id,
    });
  }

  render() {
    const { title, showItems, selectedAddressId } = this.state;
    const { items } = this.props;
    return(
      <div className="item-select-wrapper">
        <div className="select-toggle"
          onClick={() => this.setState({ showItems: !this.state.showItems })}>
          <span className="title">{title}</span>
          <span className="chevron-arrow-down-icon" />
        </div>
        <ul className={`select-items ${showItems ? 'show': ''}`}>
          {items.map(item => 
            <li key={item.Id} 
              data-id={item.Id} 
              data-text={item.Text}
              data-description={item.Description}
              onClick={this.onItemSelected}>
              {item.Text} {item.Description}
              <span className={`checked-icon ${selectedAddressId === item.Id ? 'show': 'hide'}`} />
            </li>,
          )} 
          {items.length <= 0 &&
                        <li className="text-center">No address found.</li>
          }                 
        </ul>
      </div>
    );
  }
}

ItemSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default enhanceWithClickOutside(ItemSelect);