import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from './components/bbsHeader/Header';
import Home from './components/bbsHomepage/Homepage';
import Account from './components/account/Account';
import MobileNavPanelLoggedIn from './components/bbsHeader/mobileNavPanel/MobileNavPanelLoggedIn';
import MobileNavPanelLoggedOut from './components/bbsHeader/mobileNavPanel/MobileNavPanelLoggedOut';
import Faq from './components/bbsFaq/Faq';
import PageNotFound from './components/404/PageNotFound';
import ShoppingCartPage from './components/shoppingCart/ShoppingCartPage';
import Footer from './components/bbsFooter/Footer';
import PaymentConfirmation from './components/shoppingCart/paymentConfirmation/PaymentConfirmation';
import TermsAndConditions from './components/termsAndConditions/TermsAndConditions';
import AuthenticatedRoute from './components/auth/AuthenticatedRoute';
import ShoppingCartRoute from './components/auth/ShoppingCartRoute';
import ContactUs from './components/bbsContactUs/ContactUs';
import MiniBasket from './components/common/miniBasket/MiniBasket';
import Login from './components/bbsLogin/Login';
import ActivateUser from './components/bbsActivateUser/ActivateUser';
import ForgotPassword from './components/bbsForgotPassword/ForgotPassword';
import ResetPassword from './components/bbsResetPassword/ResetPassword';
import Register from './components/bbsRegister/Register';
import Products from './components/bbsProducts/Products';
import ProductDetail from './components/bbsProducts/productDetail/ProductDetail';
import CookieConsent from 'react-cookie-consent';
import './App.css';

class App extends Component {

  componentDidMount() {
    this.scrollBackToTop();
    $(window).scroll(() => {
      this.scrollBackToTop();
    });

    $('#scrollToTop').click(() => {
      $('body,html').animate({
        scrollTop: 0,
      }, 500);
    });
  }

  scrollBackToTop() {
    if($(window).scrollTop() >= 400) {
      $('#scrollToTop').fadeIn(400);
    } else {
      $('#scrollToTop').fadeOut(400);
    }
  }

  getTotalQuantityInBasket() {
    const { shoppingCart } = this.props;

    if (shoppingCart.items == null) return 0;

    return shoppingCart.items.reduce((a, b) => {
      return a + b.quantity;
    }, 0);
  }

  render() {

    return (
      <div className={`App ${this.getTotalQuantityInBasket() > 0 ? 'basket-not-empty' : ''}`}>
        <MiniBasket />
        <Header history={this.props.history} />
        <section className="site-content content-sticky">
          <Switch>
            <AuthenticatedRoute path={`${process.env.PUBLIC_URL}/account`}
              component={Account} />
            <AuthenticatedRoute path={`${process.env.PUBLIC_URL}/delivery-addresses`}
              component={Account} />
            <AuthenticatedRoute path={`${process.env.PUBLIC_URL}/delivery-addresses`}
              component={Account} />
            <AuthenticatedRoute path={`${process.env.PUBLIC_URL}/upcoming-orders`}
              component={Account} />
            <ShoppingCartRoute path={`${process.env.PUBLIC_URL}/shopping-cart`}
              component={ShoppingCartPage} />
            <ShoppingCartRoute path={`${process.env.PUBLIC_URL}/confirmation/:id`}
              component={PaymentConfirmation} />
            <Route exact
              path={`${process.env.PUBLIC_URL}/activate`}
              render={(props) => <ActivateUser {...props} />}/>
            <Route path={`${process.env.PUBLIC_URL}/faq`}
              render={(props) => <Faq {...props} />}/>
            {this.props.user && this.props.user.isAuthenticated && 
              <Route path={`${process.env.PUBLIC_URL}/contact-us`}
                render={(props) => <ContactUs {...props} />}/>
            }
            <Route path={`${process.env.PUBLIC_URL}/catalogue`}
              render={(props) => (<Products {...props}
                isCatalogue={true} />)}/>
            <Route exact
              path={`${process.env.PUBLIC_URL}/product/:id`}
              render={(props) => <ProductDetail {...props} />}/>
            <Route path={`${process.env.PUBLIC_URL}/terms-and-conditions`}
              render={(props) => <TermsAndConditions {...props} />}/>
            <Route exact
              path={`${process.env.PUBLIC_URL}/`}
              render={(props) => <Home {...props}/>} />
            <Route path={`${process.env.PUBLIC_URL}/login`}
              render={(props) => <Login {...props}/>} />
            <Route path={`${process.env.PUBLIC_URL}/forgot-password`}
              render={(props) => <ForgotPassword {...props}/>} />
            <Route path={`${process.env.PUBLIC_URL}/reset-password`}
              render={(props) => <ResetPassword {...props}/>} />
            <Route path={`${process.env.PUBLIC_URL}/register`}
              render={(props) => <Register {...props}/>} />
            <Route path="*"
              render={(props) => <PageNotFound {...props}/>}/>
          </Switch>
          {this.props.user.isAuthenticated && <MobileNavPanelLoggedIn history={this.props} /> }
          {!this.props.user.isAuthenticated && <MobileNavPanelLoggedOut history={this.props} /> }
        </section>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="BBSEssentialCookie"
          style={{ background: '#2B373B' }}
          buttonStyle={{
            background: '#0077BE',
            color: '#fff',
            fontSize: '13px',
            width: '150px',
          }}
          expires={150}>
            This website uses only strictly necessary cookies — these cookies are essential for you to browse the website and use its features, such as accessing secure areas of the site. 
        </CookieConsent>
        <div id="scrollToTop">Back to top</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shoppingCart: state.foodBoxesShoppingCart.data,
    user: state.user.data,
  };
};

export default withRouter(connect(mapStateToProps, null)(App));
