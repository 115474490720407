import * as apiResources from '../api/apiResources'; 
import { EWS_ORDER_ID, ORIGINAL_ORDER_ID_HEADER } from '../shared/sessionUtils';

const { apiBaseUrl, apiKey, companyId } = apiResources.apiResource();

const apiRequestService = {
  getHeaders: () => {
    const token = sessionStorage.getItem('token') || '';
    const orderId = sessionStorage.getItem(ORIGINAL_ORDER_ID_HEADER) || null;
    const currentOrderId = sessionStorage.getItem(EWS_ORDER_ID) || null;

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'ews-companyId': companyId,
      'ews-apiKey': apiKey,
      'X-Requested-With': 'XMLHttpRequest',
      'Expires': '-1',
      'If-Modified-Since': 'Thu, 13 Feb 1985 13:40:01 GMT',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'ews-orderId': currentOrderId,
    };

    if (orderId) {
      headers[ORIGINAL_ORDER_ID_HEADER] = orderId;
    }

    return headers;
  },
  getEndpoint: (url, params = null) => {
    let endpoint = `${apiBaseUrl}/${url}`;
    if (params) {
      let queryParams = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
      endpoint += `?${queryParams}`;
    }
    return endpoint;
  },
};

export default apiRequestService;