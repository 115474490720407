import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import toastr from 'toastr';
import './MobileNavPanel.css';
import { onMobileMenuToggle } from '../../../actions/mobileMenuAction';
import { onModalToggle } from '../../../actions/commonActions';
import { logout } from '../../../actions/userActions';
import { getProductCategories } from '../../../actions/categoriesAction';

class MobileNavPanelLoggedOut extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuOpen: this.props.menuOpen,
      showAccountMenu: false,
      showMorrisonsMore: false,
    };

    this.onCloseMenu = this.onCloseMenu.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.toggleAccountMenu = this.toggleAccountMenu.bind(this);
    this.toggleMorrisonsMoreMenu = this.toggleMorrisonsMoreMenu.bind(this);
  }

  toggleAccountMenu() {
    if (this.state.showMorrisonsMore) {
      this.setState({ showMorrisonsMore: false });
    }
    this.setState({ showAccountMenu: !this.state.showAccountMenu });
  }

  toggleMorrisonsMoreMenu() {
    if (this.state.showAccountMenu) {
      this.setState({ showAccountMenu: false });
    }
    this.setState({ showMorrisonsMore: !this.state.showMorrisonsMore });
  }

  onCloseMenu() {
    this.setState({
      menuOpen: false,
      showAccountMenu: false,
      showMorrisonsMore: false,
    });
    this.props.toggleMenu();
  }

  onLogout() {
    this.onCloseMenu();
    toastr.success('Logged out successfully.');
    this.props.logout();
    this.props.history.push(`${process.env.PUBLIC_URL}/`);
  }

  render() {
    const { menuOpen, user, toggleMenu, inQueue } = this.props;

    return (
      <div>
        <div className={`nav-mobile-view ${menuOpen ? 'show': ''}`}>
          {!inQueue && 
                      <div className={`mobile-login-wrapper ${user.isAuthenticated ? 'hide' : ''}`}>
                        <NavLink to={`${process.env.PUBLIC_URL}/login`}
                          activeClassName=""
                          onClick={toggleMenu}>
                          <span>
                                    Login
                          </span>
                        </NavLink>
                        <NavLink to={`${process.env.PUBLIC_URL}/register`}
                          activeClassName=""
                          onClick={toggleMenu}>
                          <span>
                                    Register
                          </span>
                        </NavLink>
                      </div>
          }
          <ul className="mobile-menu-list">
            <li className="mobile-nav-heading">
              <a href="/#home"
                className="nav-button  parent-button"
                onClick={toggleMenu}>Home</a>
            </li>
            <li className={'multi-menu concept-menu'}>
              <a href="/#about-us"
                className="nav-button  parent-button"
                onClick={toggleMenu}>About Us</a>
            </li>
            <li>
              <a href="/#products"
                className="nav-button  parent-button"
                onClick={toggleMenu}>Products</a>
            </li>     
            <li>
              <a href="/#gallery"
                className="nav-button  parent-button"
                onClick={toggleMenu}>Gallery</a>
            </li>
            <li>
              <a href="/#contact-us"
                className="nav-button  parent-button"
                onClick={toggleMenu}>Contact</a>
            </li>                      
          </ul>
        </div>
        <div className={`menu-overlay ${menuOpen ? 'show': ''}`}
          onClick={toggleMenu} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menuOpen,
    user: state.user.data,
    categories: state.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenu: () => dispatch(onMobileMenuToggle()),
    toggleModal: (modal) => dispatch(onModalToggle(modal)),
    logout: () => dispatch(logout()),
    getProductCategories: () => dispatch(getProductCategories()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileNavPanelLoggedOut));