import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import priceUtils from '../../../shared/priceUtils';
import './ShoppingCartItems.css';

class ShoppingCartItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  viewDetails(item) {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/product/${item.friendlyUrl}`,
    );
  }

  render() {
    const { shoppingCart } = this.props;

    if (shoppingCart.items.length > 0) {
      // Set quantity of each cart item.
      for (let i in shoppingCart.items) {
        shoppingCart.items[i].quantity = shoppingCart.items[i].quantity || 1;
      }
    }

    return (
      <div className="box-summary">
        {shoppingCart.items.length > 0 ? (
          shoppingCart.items.map((cartItem, index) => (
            <div key={index}
              className="basket-item">
              <div className="basket-item-info">
                <div
                  className="image"
                  data-id={cartItem.productID}
                  onClick={() => this.viewDetails(cartItem)}
                  style={{
                    backgroundImage: `url(${cartItem.imageUrl})`,
                  }}
                />
                <div className="name-wrapper">
                  <div
                    className="name"
                    data-id={cartItem.productID}
                    onClick={() => this.viewDetails(cartItem)}
                  >
                    {cartItem.quantity > 1
                      ? `${cartItem.quantity}x ${cartItem.title}`
                      : cartItem.title}
                  </div>
                </div>
                <div className="price">
                  {priceUtils.FormatCurrency(Number(cartItem.price * cartItem.quantity))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="empty-basket">Add a product to continue</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    boxType: state.boxType,
    shoppingCart: state.foodBoxesShoppingCart.data,
    loading: state.foodBoxesShoppingCart.loading,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withRouter(ShoppingCartItems));
