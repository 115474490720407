import React from 'react';
import { withRouter } from 'react-router-dom';
import Products from '../bbsProducts/Products';
import UserSummary from '../bbsUserSummary/UserSummary';
import UserSummarySecondary from '../bbsUserSummarySecondary/UserSummarySecondary';
import './Homepage.css';

const HomepageLoggedIn = () => {
  return (
    <div className="home-page logged-in">
      <UserSummary />
      <Products />
      <UserSummarySecondary />
    </div>
  );
};


export default withRouter(HomepageLoggedIn);
