import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './PersonalDetail.css';

class PersonalDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        addresses: [],
        email: '',
        firstName: '',
        lastName: '',
        isAuthenticated: true,
        phone: '',
        stripeID: '',
        title: '',
      },
      editMode: false,
    };
        
    this.onInputValueChanged = this.onInputValueChanged.bind(this);
  }

  onInputValueChanged(e) {
    let user = this.state.user;
    user[this.props.type] = e.target.value;
    this.setState({ User: user });
  }

  getTypeData() {
    switch(this.props.type) {
    case 'name':
      return { icon: 'user-icon', value: this.props.user.firstName + ' ' + this.props.user.lastName, label: 'Name', placeholder: 'Enter fullname' };
    case 'phone':
      return { icon: 'phone-icon', value: this.props.user.telephoneNumber ? this.props.user.telephoneNumber : 'None',  label: 'Phone number', placeholder: 'Enter telephone' };
    case 'email':
      return { icon: 'icon-mail', value: this.props.user.emailAddress,  label: 'Email', placeholder: 'Enter email address' };
    default:
      return { icon: '', value: '',  label: '', placeholder: '' };
    }
  }

  render() {
    const { editMode } = this.state;
    const { icon, value, label } = this.getTypeData();
    return (
      <div className="personal-detail">
        <div className="item">
          <div className="icon-wrapper">
            <span className={icon} />
          </div>
          <span className="title">{editMode ? label : value}</span>
        </div>
      </div>
    );
  }
}

PersonalDetail.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(PersonalDetail);