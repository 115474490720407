import * as actionType from '../actions/actionTypes';

const mobileFilterReducer = (state = false, action) => {
  switch(action.type) {
  case actionType.MOBILE_FILTER_TOGGLE_SUCCESS:
    return !state;
  default:
    return state;
  }
};

export default mobileFilterReducer;