import { BlobServiceClient } from '@azure/storage-blob';
import * as apiResources from '../../../../../api/apiResources';
const { storageAccountName, sasToken } = apiResources.apiResource();

const containerName = 'profile';
export const baseUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}`;

const getBlobsInContainer = async (containerClient) => {
  const returnedBlobUrls = [];

  Array.from(containerClient.listBlobsFlat()).forEach(function(blob) {
    returnedBlobUrls.push(
      `${baseUrl}/${blob.name}`,
    );
  });

  return returnedBlobUrls;
};

const createBlobInContainer = async (containerClient, file) => {
  const blobClient = containerClient.getBlockBlobClient(file.name);
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  await blobClient.uploadBrowserData(file, options);
};

const uploadFileToBlob = async (file) => {
  if (!file) {
    return [];
  }

  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`,
  );

  const containerClient = blobService.getContainerClient(containerName);
  await createBlobInContainer(containerClient, file);
  return getBlobsInContainer(containerClient);
};

export default uploadFileToBlob;