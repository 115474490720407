import React, { Component } from 'react';
import AccountDeliveriesPage from './AccountDeliveriesPage';

class UpcomingOrders extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <AccountDeliveriesPage filters={['upcoming']} />  
    );
  }
}

export default UpcomingOrders;