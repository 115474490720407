import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import enhanceWithClickOutside from 'react-click-outside';
import toastr from 'toastr';
import './Navigation.css';
import NavItem from './NavItem/NavItem.js';

// Actions
import { onMobileMenuToggle } from '../../../actions/mobileMenuAction';
import { onModalToggle } from '../../../actions/commonActions';
import { logout } from '../../../actions/userActions';
import { getProductCategories } from '../../../actions/categoriesAction';

class Navigation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hideAllSubMenu: false,
      showAccountMenu: false,
      showMorrisonsMore: false,
      showLoginModal: false,
      showRegisterModal: false,
    };

    this.onLogin = this.onLogin.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onNavChange = this.onNavChange.bind(this);
  }

  componentDidMount() {
    this.props.getProductCategories();
  }

  onLogin() {
    this.setState({ showLoginModal: !this.state.showLoginModal });
  }
    
  onRegister() {
    this.setState({ showRegisterModal: !this.state.showRegisterModal });
  }

  onLogout() {
    this.setState({ showAccountMenu: false });
    toastr.success('Logged out successfully.');
    this.props.logout();
    this.props.history.push(`${process.env.PUBLIC_URL}/`);
  }

  onNavChange() {
    this.setState({
      hideAllSubMenu: true,
    });
  }

  handleClickOutside() {
    this.setState({ showAccountMenu: false });
  }

  render() {
    const { menuOpen, user, activeNav } = this.props;

    return (
      <div className='nav-panel-wrapper fixed'>
        <div className="nav-desktop-view">
          <div className="container">
            <NavLink className="logo-wrapper"
              exact
              to={`${process.env.PUBLIC_URL}`} 
              data-id="/"
              onClick={() => this.onNavChange('/')}>
              <div className="logo" />
            </NavLink>
            <div className="left-menu">
              <ul className="nav">
                <a href="/#home"
                  className="nav-button  parent-button">Home</a>
                <a href="/#about-us"
                  className="nav-button  parent-button">About Us</a>
                <a href="/#products"
                  className="nav-button  parent-button">Products</a>
                <a href="/#gallery"
                  className="nav-button  parent-button">Gallery</a>
                <a href="/#contact-us"
                  className="nav-button  parent-button">Contact</a>
              </ul>
            </div>
            <div className="right-menu">
              <ul className="desktop-menu">
                <NavItem
                  name="Log In"
                  url="login"
                  activeNav={activeNav}
                  isParent={true}
                  onNavChange={this.onNavChange}
                  className={`nav-button parent-button login ${user.isAuthenticated ? 'hide' : ''}`}
                />
              </ul>
              <div className={`mobile-nav ${menuOpen ? 'change': ''}`}
                onClick={this.props.toggleMenu}>
                <div className="nav-burger-icon">
                  <div className="bar1" />
                  <div className="bar2" />
                  <div className="bar3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } 
}

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menuOpen,
    user: state.user.data,
    modal: state.modal,
    categories: state.categories,
    subCategories: state.subCategories,
    activeNav: state.navigation.activeNav,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenu: () => dispatch(onMobileMenuToggle()),
    toggleModal: (modal) => dispatch(onModalToggle(modal)),
    logout: () => dispatch(logout()),
    getProductCategories: () => dispatch(getProductCategories()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(enhanceWithClickOutside(Navigation)));