import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getShoppingCart } from '../../../actions/shoppingCartAction';

class DeliveryItems extends Component {
  constructor() {
    super();
  }

  render() {
    const { order } = this.props;
    return (
      <div className="delivery-items-wrapper">
        <hr/>
        <div className="item title">
          <div className="item-column product-name">Name</div>
          <div className="item-column product-price">Price</div>
          <div className="item-column product-price-per">Price Per</div>
          <div className="item-column product-quantity">Quantity</div>
          <div/>
        </div>
        {order.items && order.items.map((item, index) => 
          <div key={index}
            className="item">
            <div className="item-column product-name">{item.shortDescription}</div>
            <div className="item-column product-price">{item.price}</div>
            <div className="item-column product-price-per">Price per {item.pricePer}</div>
            <div className="item-column product-quantity">{item.quantity}</div>
            <div/>
          </div>)
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchShoppingCart: (orderID) => dispatch(getShoppingCart(orderID)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(DeliveryItems));