import React from 'react';
import ImageGallery from 'react-image-gallery';
import './Gallery.css';

import GalleryImage1 from '../../../../assets/galleryImages/Main web 1.jpg';
import GalleryImage2 from '../../../../assets/galleryImages/Main web 2.jpg';
import GalleryImage3 from '../../../../assets/galleryImages/Main web 3.jpg';
import GalleryImage4 from '../../../../assets/galleryImages/Main web 4.jpg';
import GalleryImage5 from '../../../../assets/galleryImages/Main web 5.jpg';
import GalleryImage6 from '../../../../assets/galleryImages/Main web 6.jpg';
import GalleryImage7 from '../../../../assets/galleryImages/Main web 7.jpg';
import GalleryImage8 from '../../../../assets/galleryImages/Main web 8.jpg';
import GalleryImage9 from '../../../../assets/galleryImages/Main web 9.jpg';
import GalleryImage10 from '../../../../assets/galleryImages/Main web 10.jpg';
import GalleryImage11 from '../../../../assets/galleryImages/Main web 11.jpg';
import GalleryImage12 from '../../../../assets/galleryImages/Main web 12.jpg';

const Gallery = () => {
  return (
    <div className="gallery"
      id="gallery">
      <div className="container text">
        <div className="italic title gold">Gallery</div>
      </div>
      <div className="container text">
        <div className="title">Finest Quality Products</div>
      </div>
      <div className="container text">
        <p>These photos show our fish straight from our production lines, where our unrivalled technical standards ensure the speediest catch to fork.</p>
      </div>
      <div className="container">
        <ImageGallery items={images}
          lazyLoad={true}/>
      </div>
    </div>
  );
};

export default Gallery;

const images = [
  {
    original: GalleryImage1,
    thumbnail: GalleryImage1,
  },       
  {
    original: GalleryImage2,
    thumbnail: GalleryImage2,
  },    
  {
    original: GalleryImage3,
    thumbnail: GalleryImage3,
  }, 
  {
    original: GalleryImage4,
    thumbnail: GalleryImage4,
  }, 
  {
    original: GalleryImage5,
    thumbnail: GalleryImage5,
  }, 
  {
    original: GalleryImage6,
    thumbnail: GalleryImage6,
  }, 
  {
    original: GalleryImage7,
    thumbnail: GalleryImage7,
  }, 
  {
    original: GalleryImage8,
    thumbnail: GalleryImage8,
  }, 
  {
    original: GalleryImage9,
    thumbnail: GalleryImage9,
  }, 
  {
    original: GalleryImage10,
    thumbnail: GalleryImage10,
  }, 
  {
    original: GalleryImage11,
    thumbnail: GalleryImage11,
  }, 
  {
    original: GalleryImage12,
    thumbnail: GalleryImage12,
  }, 
];