import React from 'react';
import { FaExpandArrowsAlt, FaLightbulb } from 'react-icons/fa';
import Boat2 from '../../../../assets/images/Pic2.jpg';

const Quality = () => {
  return (
    <div className="quality"
      id="quality">
      <div className="container">
        <div className="left">
          <img src={Boat2}
            className="img-responsive"
            alt="bbs-boat"/>
        </div>
        <div className="right">
          <div className="content">
            <div className="container-inline">
              <div className="left-inline">
                <FaExpandArrowsAlt/>
              </div>
              <div className="right-inline">
                <p>
                  We’re based across four well-invested factories in the heart of Grimsby & Cornwall, with a team of dedicated seafood specialists.
                </p>
              </div>
            </div>
            <div className="container-inline">
              <div className="left-inline">
                <FaLightbulb/>
              </div>
              <div className="right-inline">
                <p>
                  Our fishmongers are trained to the highest standards, working only with produce that exceeds the highest quality expectations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quality;