import React, { Component } from 'react';
import { connect } from 'react-redux';
import priceUtils from '../../../shared/priceUtils';
import './ShoppingCartSummary.css';

class ShoppingCartSummary extends Component {
  constructor() {
    super();
  }

  getRefundDescription() {
    const { shoppingCart } = this.props;
    if (!shoppingCart.originalOrderValue) {
      return 'Total:';
    }

    if (shoppingCart.priceToPay <= 0) {
      return 'Total to Refund:';
    }

    return 'Total to Pay:';
  }

  render() {
    const { shoppingCart } = this.props;
    return (
      <div className="shopping-cart-summary">
        <div className="box-total total-field">
          <div className="title">{shoppingCart.originalOrderValue ? 'Edited Order Total:' : 'Subtotal:'}</div>
          <div className="price">{priceUtils.FormatCurrencyPence(shoppingCart.orderTotal)}</div>
        </div>
        <div className="box-total total-field gold-underline">
          <div className="title">Delivery:</div>
          <div className="price">Free</div>
        </div>
        <div className="box-total total-field">
          <div className="title">{this.getRefundDescription()}</div>
          <div className="price">{priceUtils.FormatCurrencyPence(shoppingCart.priceToPay)}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shoppingCart: state.foodBoxesShoppingCart.data,
  };
};

export default connect(mapStateToProps, null)(ShoppingCartSummary);