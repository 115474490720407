import * as actionType from './actionTypes';

const mobileFilterToggleSuccess = () => {
  return {
    type: actionType.MOBILE_FILTER_TOGGLE_SUCCESS,
  };
};

export const onMobileFilterToggle = () => {
  return dispatch => {
    dispatch(mobileFilterToggleSuccess());
  };
};