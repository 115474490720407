import {
  GET_DELIVERIES,
  LOADING_DELIVERIES,
  REMOVE_DELIVERIES,
} from '../actions/deliveriesActions';

const initialState = [];

const deliveriesReducer = (state = initialState, action) => {
  switch(action.type) {
  case GET_DELIVERIES: {
    let deliveries = Object.assign([], action.deliveries).map((delivery) => {
      if (delivery.items.length > 0) {
        delivery.items = delivery.items.map((deliveryItem) => {
          if (delivery.items.length > 0) {
            const itemQuantity = delivery.items.find((itemQty) => itemQty.productID === deliveryItem.productID) || null;
            if (itemQuantity) {
              deliveryItem.quantity = itemQuantity.quantity;
            }
          }
          return deliveryItem;
        });
      }
      return delivery;
    });
    return deliveries;
  }

  case REMOVE_DELIVERIES:
    return initialState;

  default:
    return state;
  }
};

export const loadingDeliveriesReducer = (state = false, action) => {
  switch(action.type) {
  case LOADING_DELIVERIES:
    return action.status;

  default:
    return state;
  }
};

export default deliveriesReducer;