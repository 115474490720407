import {
  TOGGLE_HEADER_VISIBILITY,
} from '../actions/headerAction';

export const toggleHeaderVisibilityReducer = (state = true, action) => {
  switch(action.type) {
  case TOGGLE_HEADER_VISIBILITY:
    return action.status;

  default:
    return state;
  }
};

export default toggleHeaderVisibilityReducer;