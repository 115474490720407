import { RSAA } from 'redux-api-middleware';
import apiConfig from '../api/apiConfig';
import apiRequestService from '../services/apiRequestService';

export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_ERROR = 'CONTACT_US_ERROR';

export const contactUs = (params) => {
  const headers = apiRequestService.getHeaders();

  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/SendContactUsEmail`,
      method: 'POST',
      body: JSON.stringify(params),
      types: [
        CONTACT_US_REQUEST,
        CONTACT_US_SUCCESS,
        CONTACT_US_ERROR,
      ],
    },
  };
};