import React from 'react';

const Delivery = () => {
  return (
    <div className="delivery"
      id="delivery">
      <div className="container text">
        <p className="title">Delivery</p>
      </div>
      <div className="container text">
        <p>We work with a trusted next day courier service to ensure your product arrives in the best conditions in shortest time possible and can be tracked on route.</p>
      </div>
    </div>
  );
};

export default Delivery;