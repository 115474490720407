import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './PageNotFound.css';

class PageNotFound extends Component {
  componentDidMount() {
    this.props.history.push(`${process.env.PUBLIC_URL}/page-not-found`);
  }

  render() {
    return (
      <div className="page-not-found">
        <div className="banner">
          <div className="content">
            <h3>Sorry - that page seems to be a little over-cooked</h3>
            <p>Let&apos;s go back to the homepage and try again.</p>
            <NavLink to={`${process.env.PUBLIC_URL}/`}>
              <span>&#x2190;</span>
              <span className="text">Back home</span>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;