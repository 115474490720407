class PriceUtils {
  FormatCurrency(decimalPrice) {  
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      currencySign: 'standard',
    }).format(decimalPrice);
  }

  FormatCurrencyPence(priceInPence) {  
    return this.FormatCurrency(priceInPence / 100);
  }
}

export default new PriceUtils();