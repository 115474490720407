import { RSAA } from 'redux-api-middleware';
import apiConfig from '../api/apiConfig';
import apiRequestService from '../services/apiRequestService';

export const GET_PRODUCT_CATEGORIES_REQUEST = 'GET_PRODUCT_CATEGORIES_REQUEST';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_ERROR = 'GET_PRODUCT_CATEGORIES_ERROR';

export const getProductCategories = () => {
  const headers = apiRequestService.getHeaders();

  return {
    [RSAA]: {
      endpoint: `${apiConfig.apiBaseUrl}/GetCategories`,
      method: 'GET',
      headers: headers,
      types: [
        GET_PRODUCT_CATEGORIES_REQUEST,
        GET_PRODUCT_CATEGORIES_SUCCESS,
        GET_PRODUCT_CATEGORIES_ERROR,
      ],
    },
  };
};
