import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import FloatingInput from '../../components/common/floatingInput/FloatingInput';
import Spinner from '../../components/common/spinner/Spinner';
import LoginLogo from '../../assets/images/login-logo.png';
import OrderlyLogo from '../../assets/images/orderly_logo.png';
import { resetPassword } from '../../actions/userActions';
import { toggleHeaderVisibility } from '../../actions/headerAction';
import toastr from 'toastr';

// Stylesheet
import './ResetPassword.css';

class ResetPassword extends Component {
  constructor() {
    super();
        
    this.recaptchaRef = React.createRef();

    this.state = {
      password: '',
      confirmPassword: '',
      error: { value: false, message: '' },
      loading: false,
      loginError: '',
      revealPassword: false,
      passwordResetCompleted: false,
      resettingPassword: false,
    };

    this.onInputFieldChanged = this.onInputFieldChanged.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
    this.onPasswordInputChange = this.onPasswordInputChange.bind(this);
  }

  onPasswordInputChange(name, value) {
    this.setState({ [name]: value });
  }

  componentDidMount() {
    this.props.toggleHeaderVisibility(false);

    // eslint-disable-next-line no-useless-escape
    let name = 'token'.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    let token = decodeURIComponent(results[2].replace(/\+/g, ' '));

    this.setState({ token:token });

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.toggleHeaderVisibility(true);
  }

  resetForm() {
    this.setState({
      password: '',
      confirmPassword: '',
      error: { value: false, message: '' },
      loading: false,
      loginError: '',
      revealPassword: false,
      passwordResetCompleted: false,
      resettingPassword: false,
    });
  }

  onInputFieldChanged(name, value) {
    this.setState({
      [name]: { value: value, error: false },
      loginError: '',
    });
  }

  emailIsValid(email) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return regex.test(email);
  }

  onResetPassword(e) {
    e.preventDefault();
    const { token, password, confirmPassword, error } = this.state;

    // Check password and confirm password match.
    if (!password) {
      toastr.error('Password is required');
      return;
    }
    else if (password !== confirmPassword) {
      toastr.error('Passwords do not match');
      this.setState({ error: error });
      return;
    }

    let params = {
      userToken: token,
      password: password,
    };

    this.props.resetPassword(params)
      .then((resp) => {
        const { Success } = resp.payload;
        if (Success) { 
          this.props.history.push(process.env.PUBLIC_URL);
          toastr.success('Password successfully reset.');
          this.resetForm();
        }
        else {
          toastr.error(resp.payload.ErrorMessage);
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  }

  render() {
    const { loading } = this.props;

    return (
      <div className="bbs-reset-password">
        <div className="reset-password-container">
          <div className="left">
            <img src={LoginLogo}
              alt="Big Blue Sea Logo"/>
            <div className="text">
              <h2>Wholesale Ordering Portal</h2>
              <h4>Delivering Quality Sustainable Seafood to Businesses</h4>
            </div>
            <p className="orderly-info">
                            Powered by Orderly. Our supply chain technology drives responsibility, generating long-term economic value and sustainability.
              <a href="https://orderly.io/"
                className="login-link"
                target="blank"> Learn More About Orderly.</a>
            </p>
          </div>
          <div className="right">
            <div className="reset-password-panel">
              <h3>Reset Password</h3>
              <p>Please enter your new password.</p>
              <form name="resetPasswordForm"
                onSubmit={this.onResetPassword}>
                <FloatingInput
                  label="New password"
                  type="password"
                  name="password"
                  value={this.state.password}
                  inputChanged={this.onPasswordInputChange} />
                <FloatingInput
                  label="Confirm new password"
                  type="password"
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  inputChanged={this.onPasswordInputChange} />
                <div className="button-wrapper">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn-block spinner-button primary-button"
                  >
                    <span className="text">Submit</span>
                    <Spinner show={loading} />
                  </button>
                </div>
              </form>
              <div className="not-registered">
                <p>Remembered your password?</p>  
                <p> 
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/login`}
                  >
                                        Login
                  </NavLink>
                </p>
              </div>
            </div>
            <div className="orderly-logo">
              <a href="https://orderly.io/"
                className="login-link"
                target="blank">                         
                <img src={OrderlyLogo}
                  alt="Orderly logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (params) => dispatch(resetPassword(params)),
    toggleHeaderVisibility: (status) => dispatch(toggleHeaderVisibility(status)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));