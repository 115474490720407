import { RSAA } from 'redux-api-middleware';
import apiConfig from '../api/apiConfig';
import { removeSavedAddresses } from './userAddressActions';
import { removeDeliveriesSuccess } from './deliveriesActions';
import { removeShoppingCart } from './shoppingCartAction';

export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST';
export const GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'; 
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'; 
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS'; 
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const UPDATE_USER_DATA_LOCALLY = 'UPDATE_USER_DATA_LOCALLY';
export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_ERROR = 'ACTIVATE_USER_ERROR';

export const logout = () => {
  return dispatch => {
    sessionStorage.removeItem('token');
    dispatch({ type: LOGOUT_SUCCESS });
    dispatch(removeSavedAddresses());
    dispatch(removeDeliveriesSuccess());
    dispatch(removeShoppingCart());
  };
};

export const login = (params) => {
  return {
    [RSAA]: {
      endpoint: `${apiConfig.apiBaseUrl}/Login`,
      method: 'POST',
      headers: apiConfig.headers,
      body: JSON.stringify(params),
      types: [
        GET_USER_DATA_REQUEST,
        LOGIN_SUCCESS,
        GET_USER_DATA_ERROR,
      ],
    },
  };
};

export const register = (params) => {
  return {
    [RSAA]: {
      endpoint: `${apiConfig.apiBaseUrl}/CreateUser`,
      method: 'POST',
      headers: apiConfig.headers,
      body: JSON.stringify(params),
      types: [
        GET_USER_DATA_REQUEST,
        REGISTER_SUCCESS,
        GET_USER_DATA_ERROR,
      ],
    },
  };
};

export const activate = (params) => {
  return {
    [RSAA]: {
      endpoint: `${apiConfig.apiBaseUrl}/ActivateUser`,
      method: 'POST',
      headers: apiConfig.headers,
      body: JSON.stringify(params),
      types: [
        ACTIVATE_USER_REQUEST,
        ACTIVATE_USER_SUCCESS,
        ACTIVATE_USER_ERROR,
      ],
    },
  };
};

export const getUserData = () => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    ...apiConfig.headers,
    Authorization: `Bearer ${token}`,
  };
  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/GetCurrentUser`,
      method: 'GET',
      types: [
        GET_USER_DATA_REQUEST,
        GET_USER_DATA_SUCCESS,
        GET_USER_DATA_ERROR,
      ],
    },
  };
};

export const updateUserDataLocally = (user) => {
  return dispatch => {
    dispatch({
      type: UPDATE_USER_DATA_LOCALLY,
      payload: user,
    });
  };
};

export const updateUserData = (params) => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    ...apiConfig.headers,
    Authorization: `Bearer ${token}`,
  };
  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/UpdateCustomerDetails`,
      method: 'POST',
      body: JSON.stringify(params),
      types: [
        GET_USER_DATA_REQUEST,
        UPDATE_USER_DATA_SUCCESS,
        GET_USER_DATA_ERROR,
      ],
    },
  };
};

export const requestResetPassword = (email) => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    ...apiConfig.headers,
    Authorization: `Bearer ${token}`,
  };
  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/RequestPasswordReset`,
      method: 'POST',
      body: JSON.stringify({ emailAddress: email }),
      types: [
        GET_USER_DATA_REQUEST,
        RESET_PASSWORD_SUCCESS,
        GET_USER_DATA_ERROR,
      ],
    },
  };
};

export const resetPassword = (params) => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    ...apiConfig.headers,
    Authorization: `Bearer ${token}`,
  };

  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/ResetPassword`,
      method: 'POST',
      body: JSON.stringify({ token: params.userToken, password: params.password }),
      types: [
        GET_USER_DATA_REQUEST,
        RESET_PASSWORD_SUCCESS,
        GET_USER_DATA_ERROR,
      ],
    },
  };
};