import moment from 'moment';
import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
} from '../actions/productsActions';

const initialState = {
  loading: false,
  error: '',
  data: [],
  productAvailabilities: [],
  lastUpdated: '',
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_PRODUCTS_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_PRODUCTS_SUCCESS: {
    return {
      ...state,
      data: action.payload,
      loading: false,
      lastUpdated: moment(),
    };
  }
  case GET_PRODUCTS_ERROR: {
    return {
      ...state,
      error: action.payload,
      loading: false,
    };
  }
  default: {
    return state;
  }
  }
};

export default productsReducer;