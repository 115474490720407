const FAQContent = [
  {
    title: 'More about our fish where it comes from and traceability',
    body: `
      <p>
        We pride ourselves on using only responsibly sourced seafood from approved sources. We choose to only deal with a very select few suppliers of raw materials to ensure the best quality for our customers. All of our seafood can be traced directly back to its original source from the farms or vessels, this will be able to tell us when, how and where the fish was caught or harvested. Our Seafood team is trained heavily to ensure the best quality seafood will be supplied to you. 
      </p>
      <p>
        All products have a detailed description on the product description tab.
      </p>
      `,
  },
  {
    title: 'Product Information',
    body: `
      All products will be traceable by using the batch code and use by date; they will be labelled with the batch code, production date and use by date, best before and best before end. 
      Chilled products are best kept under chilled temperatures 0-5 degrees, for frozen products these are best kept under frozen temperatures at -18 degrees.
    `,
  },
  {
    title: 'Can I cancel or amend my order once it has been placed?',
    body: `
      <p>
        If you need to amend your order please contact <a style="color:blue;" href="mailto:sales@bigblueseafood.com">sales@bigblueseafood.com</a> and we will come back to you as soon as possible. 
      </p>
      <p>
        If you need to cancel your order you can do so via the Big Blue Sea website <a href="https://www.bigblueseafood.co.uk/">https://www.bigblueseafood.co.uk/</a> by contacting Morrisons Customers Services at any time before the relevant cut-off period at 12pm prior to your scheduled delivery date. For example, where your order is schedule to be delivered on a Wednesday, you can cancel your order at any time up until 12pm on the Tuesday before.
      </p>
    `,
  },
  {
    title: 'When will I receive my order?',
    body: `
      <p>
        If you require your order the next day please select ‘next day delivery’ at the checkout.
        Orders must be placed by <strong>12 PM</strong> for delivery the next day. For example, if you require delivery on a Wednesday, you must place your Order by 12pm on the Tuesday before. 
      </p>
      <p>
        If you require your order on another day, please view the delivery options available to you at the point of checkout and select the option most suited to your requirements. 
      </p>
      <p>
        Once you have selected your delivery date, our courier partners let you know on the day when your delivery time slot will be.
      </p>
    `,
  },
  {
    title: 'Can I reorder a previous order without having to add all items back to my basket?',
    body: `
      You can select ‘reorder’ from the order history section within your account to make ordering much easier!
    `,
  },
  {
    title: 'Can I place future orders?',
    body: `
      Yes, you can order up to 28 days in advance. The price at the point of order is fixed and cannot be changed once the Order has been confirmed. 
    `,
  },
  {
    title: 'Where do you deliver?',
    body: `
      <p>
        Big Blue Sea products can be delivered all across mainland England, mainland Scotland and mainland Wales.
      </p>
      <p>
        Unfortunately we are unable to deliver to Northern Ireland, Scottish Highlands, Scottish Islands, Channel Island, Isle of Wight, Isle of Man, Isles of Scilly, Republic of Ireland or Mainland Europe.
      </p>
    `,
  },
  {
    title: 'How much does delivery cost?',
    body: `
      We offer free delivery for orders of the value of £50.00 or above. For orders under £50.00, our delivery charges will be provided to you on our website before you confirm your order.
    `,
  },
  {
    title: 'Deliveries over Bank Holidays?',
    body: `
      Unfortunately deliveries cannot be made on bank holidays, deliveries will be made the following working day.
    `,
  },
  {
    title: 'Does my delivery need signing for?',
    body: `
      We would recommend that someone is present to accept the order so that this can be stored safely and kept fresh! If this is not possible, please let us know when ordering in the ‘notes’ section, ideally somewhere dry, cool and safe -  We cannot accept liability for any missing deliveries which are not signed for. 
    `,
  },
  {
    title: 'How do I change my delivery address?',
    body: `
      You can amend your delivery address under your my account section of the website. However, if a delivery has already been shipped, we are unable to change the delivery location. We are not liable or responsible for any loss suffered as a result of incorrect delivery details.
    `,
  },
  {
    title: 'Covid 19 Delivery procedures?',
    body: `
      Your delivery driver is required to take a photo of the parcel at the point of delivery as a proof of delivery. The parcel sender is expecting a signature as proof of delivery, instead of a signature, our driver takes a photo of the parcel in the doorway to prove it was handed over.  You do not need to be in the photo, so feel free to step back while the driver takes the photo.
    `,
  },
  {
    title: 'Part of my order is missing?',
    body: `
      Please email <a style="color:blue;" href="mailto:sales@bigblueseafood.com">sales@bigblueseafood.com</a> with your name, address and order number. Our customer services team will do their best to assist you. We reserve the right to ask you to send reasonable evidence of your delivery to confirm which part of your order is missing. 
    `,
  },
  {
    title: 'I am not satisfied with my order?',
    body: `
      If you have any issues with your order, please email <a style="color:blue;" href="mailto:sales@bigblueseafood.com">sales@bigblueseafood.com</a> with your name, address and order number. Our customer services team will do their best to assist you. We reserve the right to ask you to send reasonable evidence of any affected products.
    `,
  },
];

export default FAQContent;
