import React from 'react';
import Salmon from '../../assets/bbsImages/Smoked Salmon portion.jpg';
import Mackerel from '../../assets/bbsImages/Peppered Mackerel.jpg';
import { NavLink } from 'react-router-dom';
import './UserSummarySecondary.css';

const UserSummarySecondary = () => {
  return (
    <div className="user-summary-secondary">
      <div className="panel-background"/>
      <div className="container">
        <div className="featured-product widget">
          <p className="prod-badge">Premium Salmon</p>
          <img src={Salmon}/>
          <h1>Smoked in Grimsby</h1>
          <a href={`${process.env.PUBLIC_URL}/product/3550974`}>Order now</a>
        </div>
        <div className="learn-more widget">
          <p className="prod-badge">Sustainability</p>
          <img src={Mackerel}/>
          <h1>Sourcing Responsibly</h1>
          <a href='https://www.morrisons-corporate.com/cr/seafood/sustainable-sourcing/'
            target="_blank"
            rel="noreferrer">Learn More</a>
        </div>
        <div className="contact-us widget">
          <p>Contact Us</p>
          <h1>Questions & Queries?</h1>
          <NavLink exact
            to={`${process.env.PUBLIC_URL}/contact-us`}
            data-id="/contact-us">
              Ask Now
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default UserSummarySecondary;