import { getUserAddresses } from '../api/userAccountApi';

export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const REMOVE_SAVED_ADDRESSES = 'REMOVE_SAVED_ADDRESSES';

const fetchAddressesSuccess = (addresses) => {
  return {
    type: FETCH_ADDRESSES_SUCCESS,
    addresses,
  };
};

const addAddressSuccess = (address) => {
  return {
    type: ADD_ADDRESS_SUCCESS,
    address,
  };
};

const updateAddressSuccess = (address) => {
  return {
    type: UPDATE_ADDRESS_SUCCESS,
    address,
  };
};

const deleteAddressSuccess = (addressID) => {
  return {
    type: DELETE_ADDRESS_SUCCESS,
    addressID,
  };
};

export const removeSavedAddresses = () => {
  return {
    type: REMOVE_SAVED_ADDRESSES,
  };
};

export const fetchAddresses = (addresses = null) => {
  return dispatch => {
    if (addresses) {
      dispatch(fetchAddressesSuccess(addresses));
    } else {
      getUserAddresses()
        .then(response => {
          if (response.status === 200) {
            dispatch(fetchAddressesSuccess(response.data));
          }
        })
        .catch(error => {
          console.log('Error fetching user addresses:', error);
        });
    }
  };
};

export const addAddress = (address) => {
  return dispatch => {
    dispatch(addAddressSuccess(address));
  };
};

export const updateAddress = (address) => {
  return dispatch => {
    dispatch(updateAddressSuccess(address));
  };
};

export const deleteAddress = (addressID) => {
  return dispatch => {
    dispatch(deleteAddressSuccess(addressID));
  };
};