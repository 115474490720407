import axios from './axiosConfig';
import * as apiResources from './apiResources';
import apiRequestService from '../services/apiRequestService';

const { apiBaseUrl } = apiResources.apiResource();

export const getDeliveryServicesRequest = (postcode) => {
  const headers = apiRequestService.getHeaders();

  return axios.get(`${apiBaseUrl}/IsPostcodeSupported?postcode=${postcode}`, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const getPaymentResult = (orderId) => {
  const headers = apiRequestService.getHeaders();

  return axios.get(`${apiBaseUrl}/GetPaymentResult?orderID=${orderId}`, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const copyOrderRequest = (orderId, deliveryDate) => {
  const headers = apiRequestService.getHeaders();

  return axios.post(apiRequestService.getEndpoint('CopyOrder'), { orderId, deliveryDate }, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

