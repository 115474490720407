import React from 'react';
import OrderlyLogo from '../../assets/images/orderly_logo.png';
import './footerLoggedOut.css';

const FooterLoggedOut = () => {
  return (
    <div className="footer-logged-out">
      <div className="left">
        <p className="orderly-info">
                    Powered by Orderly. Our supply chain technology drives responsibility, generating long-term economic value and sustainability.
          <a href="https://orderly.io/"
            className="login-link"
            target="blank"> Learn More About Orderly.</a>
        </p>
      </div>
      <div className="right">
        <div className="orderly-logo">
          <a href="https://orderly.io/"
            className="login-link"
            target="blank">                         
            <img src={OrderlyLogo}
              alt="Orderly logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterLoggedOut;
