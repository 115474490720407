import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Footer.css';
import Logo from '../../assets/images/logo.png';

class Footer extends Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <footer>
        <div className="container">
          <div className="top">
            <div className="column">
              <img src={Logo}
                alt="BBS logo"
                className="img-responsive" />
            </div>
            <div className="column">
              <h4>Help & Information</h4>
              {this.props.user.isAuthenticated &&
                <a href={`${process.env.PUBLIC_URL}/contact-us`}>
                  Contact Us
                </a>
              }
              {!this.props.user.isAuthenticated &&
                <a href={`${process.env.PUBLIC_URL}/#contact-us`}>
                  Contact Us
                </a>
              }
              <a href={`${process.env.PUBLIC_URL}/faq`}
                target="_blank"
                rel="noreferrer">
                FAQs
              </a>
            </div>
            <div className="column">
              <h4>Terms</h4>
              <a
                href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
              <a href="https://my.morrisons.com/privacy-policy"
                target="_blank"
                rel="noreferrer">
                Privacy Policy
              </a>
              <a href="https://my.morrisons.com/accessibility/"
                target="_blank"
                rel="noreferrer">
                Accessibility
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showSiteWrapper: state.showSiteWrapper,
    user: state.user.data,
  };
};

export default withRouter(connect(mapStateToProps)(Footer));
