import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import FloatingInput from '../../components/common/floatingInput/FloatingInput';
import Spinner from '../../components/common/spinner/Spinner';
import LoginLogo from '../../assets/images/login-logo.png';
import OrderlyLogo from '../../assets/images/orderly_logo.png';
import { requestResetPassword } from '../../actions/userActions';
import { toggleHeaderVisibility } from '../../actions/headerAction';
import toastr from 'toastr';

// Stylesheet
import './ForgotPassword.css';

class ForgotPassword extends Component {
  constructor() {
    super();
        
    this.recaptchaRef = React.createRef();

    this.state = {
      emailAddress: { value: '', error: '' },
      loading: false,
      loginError: '',
      revealPassword: false,
      passwordResetCompleted: false,
      resettingPassword: false,
    };

    this.onInputFieldChanged = this.onInputFieldChanged.bind(this);
    this.onForgotPassword = this.onForgotPassword.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.toggleHeaderVisibility(false);
  }

  componentWillUnmount() {
    this.props.toggleHeaderVisibility(true);
  }

  resetForm() {
    this.setState({
      emailAddress: { value: '', error: '' },
      loading: false,
      loginError: '',
      revealPassword: false,
      passwordResetCompleted: false,
      resettingPassword: false,
    });
  }

  onInputFieldChanged(name, value) {
    this.setState({
      [name]: { value: value, error: false },
      loginError: '',
    });
  }

  emailIsValid(email) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return regex.test(email);
  }

  handleValidation() {
    const { emailAddress, password } = this.state;
    let errorCount = 0;

    if (emailAddress.value.length <= 0) {
      emailAddress.error = 'Email address is required';
      errorCount += 1;
    } else if(!this.emailIsValid(emailAddress.value)) {
      emailAddress.error = 'Invalid email address entered';
      errorCount += 1;
    }

    if (errorCount > 0) {
      this.setState({ emailAddress, password });
      return false;
    }
    return true;
  }

  onForgotPassword(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      const { emailAddress } = this.state;
      this.props.requestResetPassword(emailAddress.value)
        .then((resp) => {
          const { Success } = resp.payload;
          if (Success) { 
            this.props.history.push(process.env.PUBLIC_URL);
            toastr.success('Password reset request successfully sent. Please check your inbox.');
            this.resetForm();
          }
        })
        .catch((error) => {
          console.log('There was an error with your request', error);
        });
    }
  }

  render() {
    const { loading, error } = this.props;
    const { emailAddress } = this.state;

    return (
      <div className="bbs-forgot-password">
        <div className="forgot-password-container">
          <div className="left">
            <img src={LoginLogo}
              alt="Big Blue Sea Logo"/>
            <div className="text">
              <h2>Wholesale Ordering Portal</h2>
              <h4>Delivering Quality Sustainable Seafood to Businesses</h4>
            </div>
            <p className="orderly-info">
                            Powered by Orderly. Our supply chain technology drives responsibility, generating long-term economic value and sustainability.
              <a href="https://orderly.io/"
                className="login-link"
                target="blank"> Learn More About Orderly.</a>
            </p>
          </div>
          <div className="right">
            <div className="forgot-password-panel">
              <h3>Forgot Password</h3>
              <p>Please enter your email address.</p>
              <form name="forgotPasswordForm"
                onSubmit={this.onForgotPassword}>
                <FloatingInput
                  label="Email address"
                  type="email"
                  name="emailAddress"
                  error={emailAddress.error}
                  value={emailAddress.value}
                  inputChanged={this.onInputFieldChanged}
                  autoComplete="off" 
                />
                {error && 
                                    <div className={'alert alert-danger text-center'}>{error}</div>
                }
                                
                <div className="button-wrapper">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn-block spinner-button primary-button"
                  >
                    <span className="text">Submit</span>
                    <Spinner show={loading} />
                  </button>
                </div>
              </form>
              <div className="not-registered">
                <p>Remembered your password?</p>  
                <p> 
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/login`}
                  >
                                        Login
                  </NavLink>
                </p>
              </div>
            </div>
            <div className="orderly-logo">
              <a href="https://orderly.io/"
                className="login-link"
                target="blank">                         
                <img src={OrderlyLogo}
                  alt="Orderly logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestResetPassword: (params) => dispatch(requestResetPassword(params)),
    toggleHeaderVisibility: (status) => dispatch(toggleHeaderVisibility(status)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));