import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import moment from 'moment';
import FloatingInput from '../../common/floatingInput/FloatingInput';
import PostcodeLookup from '../../common/addressLookup/PostcodeLookup';
import AddressEntry from '../../common/addressEntry/AddressEntry';
import { updateDeliveryDetails, updateDeliveryAddressLocally } from '../../../actions/shoppingCartAction';
import { checkPostcodeIsSupportedRequest, checkDeliveryAddressAvailable } from '../../../api/deliveriesApi';

class DeliveryDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      address: {
        id: '',
        line1: { value: props.shoppingCart.deliveryAddress.addressLine1, error: '' },
        line2: { value: props.shoppingCart.deliveryAddress.addressLine2, error: '' },
        city: { value: props.shoppingCart.deliveryAddress.town, error: '' },
        county: { value: props.shoppingCart.deliveryAddress.county, error: '' },
        country: { value: '', error: '' },
        postcode: { value: props.shoppingCart.deliveryAddress.postcode, error: '' },
        recipientName: { value:`${props.user.firstName !== undefined ? props.user.firstName : ''} ${props.user.lastName !== undefined ? props.user.lastName : ''}` || '', error: '' },
      },
      billingAddress: {
        line1: { value: props.shoppingCart.deliveryAddress.addressLine1, error: '' },
        line2: { value: props.shoppingCart.deliveryAddress.addressLine2, error: '' },
        city: { value: props.shoppingCart.deliveryAddress.town, error: '' },
        country: { value: '', error: '' },
        postcode: { value: '', error: '' },
      },
      phone: { value: props.user.telephoneNumber, error: '' },
      instruction: { value: props.shoppingCart.deliveryAddress.deliveryInstructions, error: '' },
      addressSearched: false,
      manualAddressEntry: false,
      clearDownAddress: false,
      postcodeCheckLoading: false,
      billingAddressRequired: true,
      isValidDeliveryAddress: true,
    };

    this.onInputFieldChanged = this.onInputFieldChanged.bind(this);
    this.onAddressSelected = this.onAddressSelected.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.checkPostcodeIsSupported = this.checkPostcodeIsSupported.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.shoppingCart !== newProps.shoppingCart) {
      if (newProps.shoppingCart.deliveryAddress) {
        this.setState({
          address: {
            id: newProps.shoppingCart.deliveryAddress.addressID ? newProps.shoppingCart.deliveryAddress.addressID : '',
            line1: { value: newProps.shoppingCart.deliveryAddress.addressLine1, error: '' },
            line2: { value: newProps.shoppingCart.deliveryAddress.addressLine2, error: '' },
            city: { value: newProps.shoppingCart.deliveryAddress.town, error: '' },
            county: { value: newProps.shoppingCart.deliveryAddress.county, error: '' },
            country: { value: '', error: '' },
            postcode: { value: newProps.shoppingCart.deliveryAddress.postcode, error: '' },
            recipientName: { value: `${newProps.user.firstName} ${newProps.user.lastName}` || '', error: '' },
          },
          phone: { value: newProps.shoppingCart.deliveryAddress.contactNumber, error: '' },
        });
      }
    }
  }

  onInputFieldChanged(name, value) {
    if (name === 'phone' || name === 'instruction') {
      this.setState({
        [name]: { value: value, error: false },
      }, () => {
        this.props.updateDeliveryAddressLocally({
          addressID: this.state.address.id,
          addressLine1: this.state.address.line1.value,
          addressLine2: this.state.address.line2.value,
          contactNumber: this.state.phone.value,
          county: this.state.address.county.value,
          deliveryInstructions: '',
          postcode: this.state.address.postcode.value,
          recipientName: this.state.address.recipientName.value,
          town: this.state.address.city.value,
        });
      });
    } else {
      let { address } = this.state;
      address[name] = { value: value, error: false };
      this.setState({ address }, () => {
        this.props.updateDeliveryAddressLocally({
          addressID: this.state.address.id,
          addressLine1: this.state.address.line1.value,
          addressLine2: this.state.address.line2.value,
          contactNumber: this.state.phone.value,
          county: this.state.address.county.value,
          deliveryInstructions: '',
          postcode: this.state.address.postcode.value,
          recipientName: this.state.address.recipientName.value,
          town: this.state.address.city.value,
        });
      });
    }
  }

  postcodeIsValid(postcode) {
    let regex = /[A-Za-z]{1,2}[0-9][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}/g;
    return regex.test(postcode);
  }

  phoneNumberIsValid(phoneNumber) {
    let regex = /^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}|\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4}|\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3})|\d{5}\)?[\s-]?\d{4,5}|8(?:00[\s-]?11[\s-]?11|45[\s-]?46[\s-]?4\d))(?:(?:[\s-]?(?:x|ext\.?\s?|#)\d+)?)$/;
    return regex.test(phoneNumber);
  }

  handleValidation() {
    const { address, billingAddress, billingAddressRequired, phone } = this.state;
    let errorCount = 0;

    if (address.line1.value.trim().length <= 0) {
      address.line1.error = 'Address line 1 is required';
      errorCount += 1;
    } else if (address.line1.value.length > 40) {
      address.line1.error = 'Maximum character length of 40 required';
      errorCount += 1;
    }

    if (address.city.value.trim().length <= 0) {
      address.city.error = 'City is required';
      errorCount += 1;
    } else if (address.city.value.length > 40) {
      address.city.error = 'Maximum character length of 40 required';
      errorCount += 1;
    }

    if (address.postcode.value.trim().length <= 0) {
      address.postcode.error = 'Postcode is required';
      errorCount += 1;
    } else if (!this.postcodeIsValid(address.postcode.value)) {
      address.postcode.error = 'Invalid postcode entered';
      errorCount += 1;
    } else if (address.postcode.error.length > 0) {
      address.postcode.error = 'Unfortunately we do not deliver to your postcode';
      errorCount += 1;
    }

    if (!phone.value || phone.value.trim().length <= 0) {
      phone.error = 'Phone number is required';
      errorCount += 1;
    } else if (!this.phoneNumberIsValid(phone.value)) {
      phone.error = 'Invalid phone number entered';
      errorCount += 1;
    }

    if (!address.recipientName.value || !address.recipientName.value.trim()) {
      address.recipientName.error = 'Recipient name is required';
      errorCount += 1;
    }

    if (billingAddressRequired) {
      if (billingAddress.line1.value.trim().length <= 0) {
        billingAddress.line1.error = 'Address line 1 is required';
        errorCount += 1;
      } else if (billingAddress.line1.value.length > 40) {
        billingAddress.line1.error = 'Maximum character length of 40 required';
        errorCount += 1;
      }

      if (billingAddress.city.value.trim().length <= 0) {
        billingAddress.city.error = 'City is required';
        errorCount += 1;
      } else if (billingAddress.city.value.length > 40) {
        billingAddress.city.error = 'Maximum character length of 40 required';
        errorCount += 1;
      }

      if (billingAddress.postcode.value.trim().length <= 0) {
        billingAddress.postcode.error = 'Postcode is required';
        errorCount += 1;
      } else if (!this.postcodeIsValid(billingAddress.postcode.value)) {
        billingAddress.postcode.error = 'Invalid postcode entered';
        errorCount += 1;
      } else if (billingAddress.postcode.error.length > 0) {
        billingAddress.postcode.error = 'Unfortunately we do not deliver to your postcode';
        errorCount += 1;
      }
    }

    if (errorCount > 0) {
      this.setState({ address, phone });
      return false;
    } else {
      this.validateAddress();
    }
    return true;
  }

  onAddressSelected(address) {
    this.setState({
      address: {
        id: address.ID ? address.ID : '',
        line1: { value: address.Line1, error: false },
        line2: { value: address.Line2, error: false },
        city: { value: address.City, error: false },
        county: { value: address.ProvinceName, error: false },
        country: { value: address.CountryName, error: false },
        postcode: { value: address.PostalCode, error: false },
        recipientName: { value: address.RecipientName || '', error: '' },
      },
    }, () => {
      this.props.updateDeliveryAddressLocally({
        addressID: this.state.address.id,
        addressLine1: this.state.address.line1.value,
        addressLine2: this.state.address.line2.value,
        contactNumber: this.state.phone.value,
        county: this.state.address.county.value,
        deliveryInstructions: '',
        postcode: this.state.address.postcode.value,
        recipientName: this.state.address.recipientName.value,
        town: this.state.address.city.value,
      });
    });
  }

  onSubmit(e) {
    e.preventDefault();
    this.handleValidation();
  }

  validateAddress(){
    this.setState({ postcodeCheckLoading: true });
    const { address } = this.state;

    checkDeliveryAddressAvailable(address.id).then((response => {
      if (response.status === 200 && response.data) {
        this.checkPostcodeIsSupported();
      } else {
        window.scrollTo(0, 0);
        this.setState({
          isValidDeliveryAddress: false,
          postcodeCheckLoading: false, 
        });
      }
    }));
  }

  checkPostcodeIsSupported() {
    const { shoppingCart } = this.props;
    let { address } = this.state;

    if (!shoppingCart.deliveryDate) {
      toastr.error('Delivery date is not selected');
    }
    this.setState({ postcodeCheckLoading: true });
    checkPostcodeIsSupportedRequest(this.state.address.postcode.value)
      .then(response => {
        if (response.status === 200 && response.data) {
          this.updateDeliveryDetails();
        } else if (!response.data) {
          address.postcode.error = 'Unfortunately we do not deliver to your postcode';
          this.setState({ 
            address: address,
            postcodeCheckLoading: false, 
          });
        }
      })
      .catch(() => {
        this.setState({ postcodeCheckLoading: false });
      });
  }

  updateDeliveryDetails() {
    const { address, billingAddress, billingAddressRequired, phone, instruction } = this.state;
    const { shoppingCart } = this.props;

    if (!shoppingCart.deliveryDate) {
      toastr.error('Delivery date is not selected');
    }
    let params = {
      deliveryDate: moment(shoppingCart.deliveryDate).format('YYYY-MM-DD'),
      deliveryAddress: {
        addressLine1: address.line1.value,
        addressLine2: address.line2.value,
        town: address.city.value,
        county: address.county.value,
        postcode: address.postcode.value,
        contactNumber: phone.value,
        deliveryInstructions: instruction.value,
        recipientName: address.recipientName.value,
      },
      billingAddress: {
        addressLine1: address.line1.value,
        addressLine2: address.line2.value,
        town: address.city.value,
        county: '',
        postcode: address.postcode.value,
      },
    };

    if (billingAddressRequired) {
      params.billingAddress = {
        addressLine1: billingAddress.line1.value,
        addressLine2: billingAddress.line2.value,
        town: billingAddress.city.value,
        county: '',
        postcode: billingAddress.postcode.value,
      };
    }

    // check if address id is set.
    if (address.id) {
      params.deliveryAddress.addressID = address.id;
    }

    this.props.updateDeliveryDetails(params)
      .then(resp => {
        if (resp.payload.Success) {
          this.props.onProceedToPaymentDetails();
        } else if (resp.payload.ErrorMessage) {
          toastr.error(resp.payload.ErrorMessage);
        }
      });
  }

  render() {
    const { address, billingAddress, clearDownAddress, phone, postcodeCheckLoading, billingAddressRequired, isValidDeliveryAddress } = this.state;

    return (
      <div className="cart-create-account">
        <div className="sub-title text-center">Delivery details</div>
        {
          !isValidDeliveryAddress &&
          <p className="error-text">
              An order has already been placed with the delivery address and delivery date.
          </p>
        }
        <PostcodeLookup 
          address={address} 
          buttonTitle="Find address"
          includeExistingAddresses
          clearDownAddress={clearDownAddress}
          onAddressSearched={() => this.setState({ addressSearched: true })} 
          onSelect={this.onAddressSelected}
          showPostcodeLookup={false}
        />
        <form name="deliveryDetailForm">
          <div className="form-group">
            <FloatingInput
              label="Recipient Name"
              type="text"
              name="recipientName"
              error={address.recipientName.error}
              value={address.recipientName.value}
              inputChanged={this.onInputFieldChanged} 
            />
          </div>
          <div>
            <div className="form-group">           
              <FloatingInput
                label="Address line 1"
                type="text"
                name="line1"
                error={address.line1.error}
                value={address.line1.value}
                inputChanged={this.onInputFieldChanged} />
            </div>
            <div className="form-group">
              <FloatingInput
                label="Address line 2"
                type="text"
                name="line2"
                error={address.line2.error}
                value={address.line2.value}
                inputChanged={this.onInputFieldChanged} />
            </div>
            <div className="form-group">
              <FloatingInput
                label="City"
                type="text"
                name="city"
                error={address.city.error}
                value={address.city.value}
                inputChanged={this.onInputFieldChanged} />
            </div>
            <div className="form-group double-input-fields">
              <div className="left">
                <FloatingInput
                  label="Postcode"
                  type="text"
                  name="postcode"
                  error={address.postcode.error}
                  value={address.postcode.value}
                  inputChanged={this.onInputFieldChanged} />
              </div> 
            </div>
          </div>
          <div className="form-group">
            <FloatingInput
              label="Phone number"
              type="text"
              name="phone"
              error={phone.error}
              value={phone.value}
              inputChanged={this.onInputFieldChanged} />
          </div>
          <div className="sub-title billing-address">Billing Address</div>
          {
            billingAddressRequired &&
            <p className="registered-address">
                Please enter your credit/debit card&apos;s registered address.
            </p>
          }
          <div className="radio-wrapper">
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="billingAddressRequired"
                  checked={billingAddressRequired}
                  onChange={() => this.setState({ billingAddressRequired: true })}
                />
                  Use a different billing address
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="billingAddressRequired"
                  checked={!billingAddressRequired}
                  onChange={() => this.setState({ billingAddressRequired: false })}
                />
                  Same as delivery address
              </label>
            </div>
          </div>
          {
            billingAddressRequired &&
            <AddressEntry
              address={billingAddress}
              handleInputChange={(name, value) => this.setState({
                billingAddress: {
                  ...this.state.billingAddress,
                  [name]: {
                    value: value,
                    error: '',
                  },
                },
              })}
            />
          }
          <button
            type="button"
            className="btn btn-block checkout-button spinner-button"
            disabled={(this.props.shoppingCart && this.props.shoppingCart.items && this.props.shoppingCart.items.length <= 0) || postcodeCheckLoading || this.props.loading}
            onClick={this.onSubmit}>
            <span className="text">Next step - Payment</span>
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shoppingCart: state.foodBoxesShoppingCart.data,
    loading: state.foodBoxesShoppingCart.loading,
    user: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDeliveryDetails: (params) => dispatch(updateDeliveryDetails(params)),
    updateDeliveryAddressLocally: (address) => dispatch(updateDeliveryAddressLocally(address)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetails);