import * as actionType from './actionTypes';

const mobileMenuToggleSuccess = () => {
  return {
    type: actionType.MOBILE_MENU_TOGGLE_SUCCESS,
  };
};

export const onMobileMenuToggle = () => {
  return dispatch => {
    dispatch(mobileMenuToggleSuccess());
  };
};