import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { BiCog, BiUser } from 'react-icons/bi';
import OrderItemsMenu from './OrderItemsMenu';
import './UserSummary.css';
import { connect } from 'react-redux';
import { openMiniBasket } from '../../actions/miniBasketAction';
import { getShoppingCart, createNewCart, copyOrder } from '../../actions/shoppingCartAction';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import toastr from 'toastr';
import SessionUtils from '../../shared/sessionUtils';
import AppDialog from '../common/dialog/AppDialog';
import Spinner from '../common/spinner/Spinner';
import { getActiveOrders } from '../../actions/activeOrdersActions';
import DeliveriesItem from '../account/deliveries/DeliveriesItem';
import scrollToComponent from 'react-scroll-to-component';
import * as deliveriesActions from '../../actions/deliveriesActions';
import priceUtils from '../../shared/priceUtils';

class UserSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datePickerIsOpen: false,
      dialog: {
        title: '',
        message: '',
        type: '',
        item: {},
        isOpen: false,
        isDefaultDialog: true,
        isCopyOrderDialog: false,
        dialogDeliveryDate: '',
        children: '',
      },
      selectedOrder: '',
      selectedOrderIndex: '',
    };

    this.openDatePicker = this.openDatePicker.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
    this.onCopyOrderDateChanged = this.onCopyOrderDateChanged.bind(this);
    this.isBlocked = this.isBlocked.bind(this);
    this.onConfirmModal = this.onConfirmModal.bind(this);
    this.onDeliverySelected = this.onDeliverySelected.bind(this);
    this.onCancelDelivery = this.onCancelDelivery.bind(this);
    this.onEditDelivery = this.onEditDelivery.bind(this);
    this.onCopyDelivery = this.onCopyDelivery.bind(this);
    this.resetDialog = this.resetDialog.bind(this);
    this.onDialogConfirm = this.onDialogConfirm.bind(this);
    this.activeOrdersData = React.createRef();
  }

  onConfirmDeliveryDate(deliveryDate) {
    const order = {
      deliveryDate: deliveryDate,
      editingExistingOrder: false,
    };
    SessionUtils.SetSessionHeaders(order);
    this.props.fetchShoppingCart();
  }

  formatCalendarNameOfDay(nameOfDay) {
    switch (nameOfDay) {
    case 'Monday': {
      return 'M';
    }
    case 'Tuesday': {
      return 'T';
    }
    case 'Wednesday': {
      return 'W';
    }
    case 'Thursday': {
      return 'T';
    }
    case 'Friday': {
      return 'F';
    }
    case 'Saturday': {
      return 'S';
    }
    case 'Sunday': {
      return 'S';
    }
    default: {
      return '';
    }
    }
  }

  getDatePickerCustomHeader({
    date,
    changeMonth,
  }, index) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return (
      <div key={index}
        className="custom-date-picker-header">           
        <select
          value={months[moment(date).month()]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map(option => (
            <option key={option}
              value={option}>
              {option} {moment(date).year()}
            </option>
          ))}
        </select>
      </div>
    );
  }

  openDatePicker() {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  onDialogConfirm(e) {
    const { dialog } = this.state;
    const { cancelDelivery, dispatchCreateNewCart, dispatchCopyOrder } = this.props;
    if (e) {
      switch (dialog.type) {           
      case 'cancelDelivery': {    
        if (dialog.item) {
          cancelDelivery({ orderId: dialog.item.orderID });
        }
        break;
      }
      case 'viewOrdersForDeliveryDate':
      case 'createNewOrder': {    
        dispatchCreateNewCart(dialog.dialogDeliveryDate)
          .then(response => {
            if (response) {
              toastr.success('New order has been created successfully for ' + this.formatDate(moment(dialog.dialogDeliveryDate)));
            } else {
              toastr.error('Something went wrong. Please try again later.');
            }
          })
          .catch(error => {
            console.log('Error creating new order:', error);
          });
        break;
      }
      case 'copyDelivery': {
        dispatchCopyOrder(dialog.item.orderID, dialog.dialogDeliveryDate);
        break;
      }
      default:
        return;
      }
    }
    this.resetDialog();
  }

  resetDialog() {
    this.setState({
      dialog: {
        title: '',
        message: '',
        type: '',
        isOpen: false,
        isDefaultDialog: true,
        isCopyOrderDialog: false,
        dialogDeliveryDate: null,
        children: '',
      },
    });
  }

  onCancelDelivery(delivery) {
    this.resetDialog();
    let dialog = this.state.dialog;
    dialog.title = 'Cancel Order';
    dialog.message = 'Are you sure you want to cancel this order?';
    dialog.type = 'cancelDelivery';
    dialog.isOpen = true;
    dialog.isDefaultDialog = true;
    dialog.item = delivery;
    this.setState({ dialog: dialog });
  }

  onEditDelivery(delivery) {
    const { editDelivery, history } = this.props;

    let params = {
      orderId: delivery.orderID,
    };

    if (delivery.status === 'Editable'){
      const order = {
        orderId: delivery.orderID,
        editingExistingOrder: false,
      };
      SessionUtils.SetSessionHeaders(order);
      this.props.fetchShoppingCart();
    } else {
      editDelivery(params)
        .then(() => {
          history.push(`${process.env.PUBLIC_URL}/`);
        });
    }

    this.resetDialog();
  }

  onCopyDelivery(delivery){
    this.resetDialog();
    const minDeliveryDate = this.minDeliveryDate();
    const deliveryDate = moment(minDeliveryDate).format('YYYY-MM-DD');
    let dialog = this.state.dialog;
    dialog.title = 'Copy Order - #' + delivery.orderID;
    dialog.type = 'copyDelivery';
    dialog.isOpen = true;
    dialog.isCopyOrderDialog = true;
    dialog.item = delivery;
    dialog.dialogDeliveryDate = deliveryDate;
    this.setState({ dialog: dialog });
  }

  onDeliverySelected(itemIndex) {
    const { activeOrdersData } = this.props;
    const { selectedOrder, selectedOrderIndex } = this.state;

    let sameOrderSelected = selectedOrder == activeOrdersData[itemIndex] || selectedOrderIndex === Number(itemIndex);

    this.setState({
      selectedOrder: sameOrderSelected ? '' : this.activeOrdersData[itemIndex],
      selectedOrderIndex: sameOrderSelected ? '' : Number(itemIndex),
    }, () => {
      setTimeout(() => {
        scrollToComponent(this.activeOrdersData.current, {
          offset: -40,
        });
      }, 100);
    });
  }

  formatDate(date) {
    return `${date.format('dddd')} the ${date.format('Do')} of ${date.format('MMMM YYYY')}`;
  }

  onDateChanged(date) {
    const requestedDate = moment(date);
    const deliveryDate = requestedDate.format('YYYY-MM-DD');
    this.props.dispatchGetActiveOrders(deliveryDate)
      .then(() => {
        const { activeOrders , activeOrdersData } = this.props;
        if (activeOrders && activeOrdersData.length > 0) {
          let dialog = this.state.dialog;
          dialog.title = 'Orders for ' + this.formatDate(requestedDate);
          dialog.message = 'Are you sure you want to edit this order?';
          dialog.type = 'viewOrdersForDeliveryDate';
          dialog.isOpen = true;
          dialog.isDefaultDialog = false;
          dialog.dialogDeliveryDate = deliveryDate;
          this.setState({ dialog: dialog });
        }
        else {
          let dialog = this.state.dialog;
          dialog.title = 'Create new order';
          dialog.message = 'Are you sure you want to create a new order for ' + this.formatDate(requestedDate) + '?';
          dialog.type = 'createNewOrder';
          dialog.isOpen = true;
          dialog.isDefaultDialog = true;
          dialog.dialogDeliveryDate = deliveryDate;
          this.setState({ dialog: dialog });
        }
      });
  }

  onCopyOrderDateChanged(date){
    const deliveryDate = moment(date).format('YYYY-MM-DD');
    let dialog = this.state.dialog;
    dialog.dialogDeliveryDate = deliveryDate;
    this.setState({ dialog: dialog });
  }

  maxDeliveryDate() {
    const { deliveryDates } = this.props;

    if (deliveryDates.loading || deliveryDates.data.length <= 0) {
      return null;
    }

    return moment(deliveryDates.data[deliveryDates.data.length - 1].deliveryDate).toDate();
  }

  minDeliveryDate() {
    const { deliveryDates } = this.props;

    if (deliveryDates.loading || deliveryDates.data.length <= 0) {
      return null;
    }

    return moment(deliveryDates.data[0].deliveryDate).toDate();
  }

  timeUntilCutOff() {
    const { shoppingCart, deliveryDates } = this.props;

    if (deliveryDates.loading || deliveryDates.data.length <= 0 || shoppingCart.deliveryDate === null) {
      return '';
    }

    const currentDeliveryDate = moment(shoppingCart.deliveryDate);

    const index = deliveryDates.data.findIndex(x => moment(x.deliveryDate).isSame(currentDeliveryDate, 'day'));
    const deliveryDate = deliveryDates.data[index];

    var eventTime = moment(deliveryDate.cutOffDate).valueOf();
    var currentTime = moment().valueOf();
    var diffTime = eventTime - currentTime;
    var duration = moment.duration(diffTime, 'milliseconds');
    var interval = 1000;
        
    duration = moment.duration(duration - interval, 'milliseconds');

    return this.buildCutOffCountdown(diffTime, duration);
  }

  buildCutOffCountdown(diffTime, duration) {
    if (!diffTime) {
      return '';
    }

    var daysLeft = `${duration.days()} ${duration.days() === 1 ? ' day, ' : ' days, '}`;
    if (duration.days() === 0) {
      daysLeft = '';
    }

    var hoursLeft = `${duration.hours()} ${duration.hours() === 1 ? ' hour' : ' hours'} and `;
    if (duration.hours() === 0) {
      hoursLeft = '';
    }

    var minutesLeft = `${duration.minutes()} ${duration.minutes() === 1 ? ' minute' : ' minutes'}`;

    var prefix = 'are ';
    if (duration.days() === 1 || duration.days() === 0 && duration.hours() === 1) {
      prefix = 'is ';
    }

    if (daysLeft.startsWith('-') || hoursLeft.startsWith('-') || minutesLeft.startsWith('-')) {
      toastr.error('The order cut-off time for next-day delivery has passed.');
      return '';
    }

    return prefix + daysLeft + hoursLeft + minutesLeft;
  }

  isBlocked(day) {
    const { deliveryDates } = this.props;
    return deliveryDates.data.some(x => moment(x.deliveryDate).isSame(day, 'day'));
  }

  onConfirmModal() {
    this.setState({ dialog: { 
      ...this.state.dialog, 
      isOpen: false,
    } });
  }

  render() {
    const { shoppingCart, user, activeOrders, activeOrdersData, loadingActiveOrders, loadingDeliveries } = this.props;
    const { dialog, selectedOrderIndex } = this.state;

    const minDeliveryDate = this.minDeliveryDate();
    const maxDeliveryDate = this.maxDeliveryDate();
    const cutOffTimeLabel = this.timeUntilCutOff();

    return (
      <div className="user-summary">
        <Spinner show={loadingDeliveries || loadingActiveOrders} />
        <div className="panel-background" />
        <div className="container">
          <div className="user-info">
            <NavLink to={`${process.env.PUBLIC_URL}/account`}>
              <BiCog/>
            </NavLink>
            <div className="logo-wrapper">
              {
                user.userProfileImageUrl ? 
                  <img 
                    src={user.userProfileImageUrl}
                    alt="User logo" /> 
                  : 
                  <BiUser/> 
              }
            </div>
            <h1>Good Afternoon, {user.customerName}</h1>
            <div className="button-wrapper">
              <NavLink
                to={`${process.env.PUBLIC_URL}/account`}
                className="btn"
              >
                My Account
              </NavLink>
            </div>
          </div>
          <div className="order-info">
            <h1>You have <span className="items-no">{shoppingCart.itemsCount ? shoppingCart.itemsCount : 0} items</span> on order for delivery on {moment(shoppingCart.deliveryDate).format('MMMM Do')}:</h1>
            {shoppingCart.itemsCount > 0 && <OrderItemsMenu/>}
            {cutOffTimeLabel && <p className="orderTimeLeft">There <b>{cutOffTimeLabel} left</b> until order cut-off.</p>}
            <div className="button-wrapper">
              <div className="delivery-date-picker">
                {
                  minDeliveryDate && maxDeliveryDate && shoppingCart.deliveryDate &&
                  <DatePicker
                    formatWeekDay={nameOfDay => this.formatCalendarNameOfDay(nameOfDay)}
                    renderCustomHeader={(data) => this.getDatePickerCustomHeader(data)}
                    selected={moment(shoppingCart.deliveryDate)}
                    onChange={this.onDateChanged}
                    disabled={this.props.loading}
                    allowSameDay={true}
                    maxDate={maxDeliveryDate}
                    minDate={minDeliveryDate}
                    dateFormat="DD/MM/YYYY"
                    locale="en-gb"
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                      },
                    }}
                    withPortal
                    filterDate={this.isBlocked}
                  />
                }
              </div>
              <p className="order-total">Order Total: {priceUtils.FormatCurrencyPence(shoppingCart.orderTotal)}</p>
              <button
                className="btn"
                onClick={() => this.props.openMiniBasket()}
              >
                Submit Order
              </button>
            </div>
          </div>
        </div>
        {
          dialog.isOpen && !dialog.isDefaultDialog && !dialog.isCopyOrderDialog &&
          <AppDialog title={dialog.title}
            onConfirm={this.onDialogConfirm}
            acceptBtnText="Create new order"
            declineBtnText="Close">
            <div className="delivery-date-orders-dialog">
              <Spinner show={loadingActiveOrders} />
              {!loadingActiveOrders &&
                  <div className="account-deliveries-page">
                    <div className='delivery-item title'>
                      <div className="row">
                        <div className="column">Status</div>
                        <div className="column">Postcode</div>
                        <div className="column">Order</div>
                        <div className="column">Date</div>
                        <div className="column"></div>
                      </div>
                    </div>
                    {
                      activeOrders && activeOrdersData.map((delivery, index) => 
                        <DeliveriesItem key={index} 
                          index={index} 
                          isSelected={index === selectedOrderIndex}
                          delivery={delivery}
                          cancelDelivery={this.onCancelDelivery}
                          onDeliverySelected={this.onDeliverySelected} 
                          editDelivery={this.onEditDelivery} 
                          copyDelivery={this.onCopyDelivery}/>,
                      )
                    }
                  </div>
              }
            </div>
          </AppDialog>
        }
        {dialog.isOpen 
        && !dialog.isDefaultDialog 
        && dialog.isCopyOrderDialog 
        && dialog.dialogDeliveryDate  
        && minDeliveryDate 
        && maxDeliveryDate &&
          <AppDialog
            title={dialog.title}
            onConfirm={this.onDialogConfirm}>
            <div className="copy-order-date-picker">
              <p>Select delivery date</p>
              <DatePicker
                formatWeekDay={nameOfDay => this.formatCalendarNameOfDay(nameOfDay)}
                renderCustomHeader={(data) => this.getDatePickerCustomHeader(data)}
                selected={moment(dialog.dialogDeliveryDate)}
                onChange={this.onCopyOrderDateChanged}
                disabled={this.props.loading}
                allowSameDay={true}
                maxDate={moment(maxDeliveryDate)}
                minDate={moment(minDeliveryDate)}
                dateFormat="DD/MM/YYYY"
                locale="en-gb"
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                  },
                }}
                withPortal={true}
                filterDate={this.isBlocked}
                inline={true}
                shouldCloseOnSelect={false}
              />
            </div>
          </AppDialog>
        }
        {dialog.isOpen && dialog.isDefaultDialog && !dialog.isCopyOrderDialog &&
            <AppDialog title={dialog.title}
              onConfirm={this.onDialogConfirm}>
              <div dangerouslySetInnerHTML={{ __html: dialog.message }} />
            </AppDialog>
        }
      </div>
      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    shoppingCart: state.foodBoxesShoppingCart.data,
    activeOrders: state.activeOrders,
    activeOrdersData: state.activeOrders.data,
    loadingActiveOrders: state.activeOrders.loading,
    deliveryDates: state.deliveryDates,
    loadingDeliveries: state.loadingDeliveries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelDelivery: (delivery) => dispatch(deliveriesActions.cancelDelivery(delivery)),
    editDelivery: (delivery) => dispatch(deliveriesActions.editDelivery(delivery)),
    openMiniBasket: (shoppingCart) => dispatch(openMiniBasket(shoppingCart)),
    fetchShoppingCart: () => dispatch(getShoppingCart()),
    dispatchCopyOrder: (orderId,deliveryDate) => dispatch(copyOrder(orderId,deliveryDate)),
    dispatchCreateNewCart: (deliveryDate) => dispatch(createNewCart(deliveryDate)),
    dispatchGetActiveOrders: (deliveryDate) => dispatch(getActiveOrders(deliveryDate)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSummary));
