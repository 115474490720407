import React from 'react';

const Product = ({ product }) => {
  return (
    <div className="product"
      id="product">
      <div className="details-container">
        <p className="product-title">{product.name}</p>
        <p className="description">{product.desc}</p>
      </div>
      <div className="price-container">
        <p className="price">POA</p>
      </div>
    </div>
  );
};

export default Product;