import {
  GET_FOOD_BOXES_SHOPPING_CART_REQUEST,
  GET_FOOD_BOXES_SHOPPING_CART_SUCCESS,
  GET_FOOD_BOXES_SHOPPING_CART_ERROR,
  ADD_FOOD_BOX_SUCCESS,
  UPDATE_DELIVERY_DETAILS_SUCCESS,
  GET_PAYMENT_REQUEST_SUCCESS,
  GET_PAYMENT_CONFIRMATION_SUCCESS,
  UPDATE_DELIVERY_ADDRESS_LOCALLY,
  UPDATE_SHOPPING_CART_SUCCESS,
  REMOVE_SHOPPING_CART,
  CLEAR_SHOPPING_CART_REQUEST,
  CLEAR_SHOPPING_CART_SUCCESS,
  CLEAR_SHOPPING_CART_ERROR,
} from '../actions/shoppingCartAction';
import sessionUtils from '../shared/sessionUtils';

const initialState = {
  loading: false,
  error: '',
  data: { 
    couponCode: null, 
    customer: { 
      customerNumber: '', 
      emailAddress: '', 
      firstName: '', 
      hasSubscription: false, 
      lastName: '', 
      postcode: '', 
      telephoneNumber: '', 
      title: '', 
    }, 
    deliveryAddress: { 
      addressID: 0, 
      addressLine1: '', 
      addressLine2: '', 
      contactNumber: '', 
      county: '', 
      deliveryInstructions: '', 
      postcode: '', 
      town: '', 
    },
    deliveryType: '',
    deliveryCost: 'Free',
    deliveryDate: null, 
    discount: '£0.00', 
    items: [],
    orderId: '8629913', 
    orderTotal: 0, 
    subTotal: '£0.00', 
    cartLimitReached: false,
  },
};

const shoppingCartReducer = (state = initialState, action) => {
  switch (action.type) {
  case CLEAR_SHOPPING_CART_REQUEST:
  case GET_FOOD_BOXES_SHOPPING_CART_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case UPDATE_DELIVERY_ADDRESS_LOCALLY: {
    return {
      ...state,
      loading: false,
      error: '',
      data: {
        ...state.data,
        deliveryAddress: action.payload,
      },
    };
  }
  case CLEAR_SHOPPING_CART_ERROR: {
    return {
      ...state,
      error: action.payload.ErrorMessage,
      loading: false,
    };
  }
  case UPDATE_SHOPPING_CART_SUCCESS:
  case GET_FOOD_BOXES_SHOPPING_CART_SUCCESS: {
    const shoppingCart = action.payload;
    sessionUtils.SetSessionHeaders(shoppingCart);

    return {
      ...state,
      loading: false,
      data: {
        ...shoppingCart,
      },
    };
  }
  case CLEAR_SHOPPING_CART_SUCCESS:
  case ADD_FOOD_BOX_SUCCESS:
  case UPDATE_DELIVERY_DETAILS_SUCCESS: {
    const shoppingCart = action.payload.ShoppingCart || state.data;
    sessionUtils.SetSessionHeaders(shoppingCart);

    return {
      ...state,
      loading: false,
      data: {
        ...shoppingCart,
      },
    };
  }
  case GET_PAYMENT_CONFIRMATION_SUCCESS: 
  case GET_PAYMENT_REQUEST_SUCCESS: {
    return {
      ...state,
      loading: false,
    };
  }
  case GET_FOOD_BOXES_SHOPPING_CART_ERROR: {
    return {
      ...state,
      error: action.payload,
      loading: false,
    };
  }
  case REMOVE_SHOPPING_CART: {
    return initialState;
  }
  default: {
    return state;
  }
  }
};

export default shoppingCartReducer;