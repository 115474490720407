import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import toastr from 'toastr';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  let token = sessionStorage.getItem('token') || '';
  if (token.length <= 0) {
    toastr.error('You must sign in to access that page.', 'Access Denied');
  }
  return (
    <Route {...rest} 
      render={(props) => 
        token.length > 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: `${process.env.PUBLIC_URL}/`,
            state: { from: props.location },
          }}
          />
        )
      } 
    />
  );
};
  
export default AuthenticatedRoute;