import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import enhanceWithClickOutside from 'react-click-outside';

class NavItem extends Component {

  constructor() {
    super();

    this.state = {
      showSubMenu: false,
    };

    this.toggleSubMenu = this.toggleSubMenu.bind(this);
    this.navChanged = this.navChanged.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps && newProps.hideAllSubMenu) {
      this.setState({ showSubMenu: false });
    }
  }

  toggleSubMenu() {
    this.setState({ showSubMenu: !this.state.showSubMenu });
  }

  navChanged() {
    this.setState({ showSubMenu: false });
    this.props.onNavChange(this.props.url);
  }

  handleClickOutside() {
    this.setState({ showSubMenu: false });
  }

  isActiveNav() {
    
    const { url, activeNav } = this.props;    

    if (!activeNav) {
      return false;
    }

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      const activeUrlPaths = (activeNav.pathname).split('/');
      const menuUrl = url.split('/');
            
      if (activeUrlPaths.length > 0 && menuUrl.length > 0) {
        if (menuUrl[0] !== 'eatfresh') {
          return activeUrlPaths[1] === menuUrl[0];
        } else {
          return activeUrlPaths[2] === menuUrl[1];
        }
      }
    } else {
      const activeUrlPaths = (activeNav.pathname).split('/');
      const menuUrl = url.split('/');
            
      if (activeUrlPaths.length > 0 && menuUrl.length > 0) {
        if (menuUrl[1] !== 'eatfresh') {
          return activeUrlPaths[2] === menuUrl[1];
        } else {
          return activeUrlPaths[3] === menuUrl[2];
        }
      }
    }
        
    return false;
  }

  render() {
    const { name, url, isParent, subMenuPosition, children } = this.props;
    const { showSubMenu } = this.state;

    if (!children) {
      let parentButton = isParent ? 'parent-button' : '';

      if (this.props.isExternalLink) {
        return (
          <a href={url} 
            className={`nav-button ${this.isActiveNav() ? 'is-active' : ''} ${parentButton}`} 
            data-id={`/${url}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.navChanged}>
            {name}
          </a>
        );
      }

      return (
        <NavLink to={`${process.env.PUBLIC_URL}/${url}`} 
          className={`nav-button ${this.isActiveNav() ? 'is-active' : ''} ${parentButton}`} 
          data-id={`/${url}`}
          onClick={this.navChanged}>
          {name}
        </NavLink>
      );
    } else {
      let subMenuHorizontalPosition = subMenuPosition ? subMenuPosition : '';

      return (
        <div className="nested-menu">
          <div className={`nav-button menu-toggle parent-button ${(this.isActiveNav() || showSubMenu ) ? 'is-active' : ''}`}  
            onClick={this.toggleSubMenu}>
            {name}
            <span className="icon icon-arrow-bottom-white" />
          </div>
          <ul className={`sub-menu ${!showSubMenu ? 'hide' : ''} ${subMenuHorizontalPosition}`}>
            {
              (children && children.length) &&
                            children.map((child, index) => <li key={index}>{child}</li>)
            }
            {
              (children && !children.length) &&
                            <li>{children}</li>
            }
          </ul>
        </div>
      );
    }
  }
}

export default withRouter(enhanceWithClickOutside(NavItem));