import { RSAA } from 'redux-api-middleware';
import apiConfig from '../api/apiConfig';
import apiRequestService from '../services/apiRequestService';

export const GET_ACTIVE_ORDERS_REQUEST = 'GET_ACTIVE_ORDERS_REQUEST';
export const GET_ACTIVE_ORDERS_SUCCESS = 'GET_ACTIVE_ORDERS_SUCCESS';
export const GET_ACTIVE_ORDERS_ERROR = 'GET_ACTIVE_ORDERS_ERROR';

export const getActiveOrders = (deliveryDate) => {
  const headers = apiRequestService.getHeaders();
  
  return {
    [RSAA]: {
      endpoint: `${apiConfig.apiBaseUrl}/GetOrdersForDeliveryDate?deliveryDate=${deliveryDate}`,
      method: 'GET',
      headers: headers,
      types: [
        GET_ACTIVE_ORDERS_REQUEST,
        GET_ACTIVE_ORDERS_SUCCESS,
        GET_ACTIVE_ORDERS_ERROR,
      ],
    },
  };
};
