// User Account actions
export const LOAD_USER_ACCOUNT_SUCCESS = 'LOAD_USER_ACCOUNT_SUCCESS';
export const UPDATE_USER_ACCOUNT_SUCCESS = 'UPDATE_USER_ACCOUNT_SUCCESS';
export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_IS_AUTHENTICATED = 'USER_IS_AUTHENTICATED';
export const USER_LOGOUT = 'USER_LOGOUT';
export const FETCHING_USER_DATA_STATUS = 'FETCHING_USER_DATA_STATUS';


// Common actions
export const MOBILE_MENU_TOGGLE_SUCCESS = 'MOBILE_MENU_TOGGLE_SUCCESS';
export const MOBILE_FILTER_TOGGLE_SUCCESS = 'MOBILE_FILTER_TOGGLE_SUCCESS';
export const MODAL_TOGGLE_SUCCESS = 'MODAL_TOGGLE_SUCCESS';
export const SHOW_BASKET_TRAY = 'SHOW_BASKET_TRAY';
export const TOGGLE_SITE_WRAPPER = 'TOGGLE_SITE_WRAPPER';
export const FETCH_TERMS_AND_CONDITIONS = 'FETCH_TERMS_AND_CONDITIONS';
export const SHOW_DELIVERY_DATE_PICKER = 'SHOW_DELIVERY_DATE_PICKER';

// Shoppingcart actions
export const FETCH_SHOPPING_CART_SUCCESS = 'FETCH_SHOPPING_CART_SUCCESS';
export const ADD_ITEM_TO_CART_SUCCESS = 'ADD_ITEM_TO_CART_SUCCESS';
export const UPDATE_CART_ITEM_SUCCESS = 'UPDATE_CART_ITEM_SUCCESS';
export const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS';
export const EMPTY_CART_SUCCESS = 'EMPTY_CART_SUCCESS';
export const UPDATE_CART_LIMIT_SUCCESS = 'UPDATE_CART_LIMIT_SUCCESS';
export const UPDATE_CART_TOTAL_SUCCESS = 'UPDATE_CART_TOTAL_SUCCESS';
export const UPDATE_CART_STEP_SUCCESS = 'UPDATE_CART_STEP_SUCCESS';
export const SET_ONE_OFF_PAYMENT_SUCCESS = 'SET_ONE_OFF_PAYMENT_SUCCESS';
export const LOADING_SHOPPING_CART = 'LOADING_SHOPPING_CART';

export const UPDATE_SHOPPING_CART_SUCCESS = 'UPDATE_SHOPPING_CART_SUCCESS';
export const RESET_SHOPPING_CART = 'RESET_SHOPPING_CART';
