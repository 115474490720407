import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { MdPlayArrow } from 'react-icons/md';
import { connect } from 'react-redux';
import { getShoppingCart } from '../../actions/shoppingCartAction';
import { withRouter } from 'react-router-dom';
import { closeMiniBasket } from '../../actions/miniBasketAction';

class OrderItemsMenu extends Component {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(key) {
    this.setState({ selected: key });
  }

  navigateToProductDetails(product) {
    this.props.history.push(`${process.env.PUBLIC_URL}/product/${product.friendlyUrl}`);
    this.props.closeMiniBasket();
  }

  render() {
    const { shoppingCart } = this.props;
    const showArrows = shoppingCart && shoppingCart.itemsCount && shoppingCart.itemsCount > 10;

    return (
      <div>
        <ScrollMenu
          data={shoppingCart.items &&
            shoppingCart.items.map((item, index) => {
              return (
                <div key={item.title + index} 
                  className="menu-item" 
                  style={{ backgroundImage: `url(${item.imageUrl})` }}
                  onClick={() => this.navigateToProductDetails(item)}
                />
              );
            })
          }
          arrowLeft={showArrows && <div className="arrow-prev"><MdPlayArrow/></div>}
          arrowRight={showArrows && <div className="arrow-next"><MdPlayArrow/></div>}
          onSelect={this.onSelect}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shoppingCart: state.foodBoxesShoppingCart.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchShoppingCart: () => dispatch(getShoppingCart()),
    closeMiniBasket: () => dispatch(closeMiniBasket()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderItemsMenu));
