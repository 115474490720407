import Axios from 'axios';
import axios from './axiosConfig';
import * as apiResources from './apiResources';


const { apiBaseUrl, postcodeLookupApiUrl, postcodeLookupApiKey } = apiResources.apiResource();

export const getCurrentUser = () => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    'Authorization': `Bearer ${token}`,
  };
    
  return axios.get(`${apiBaseUrl}/GetCurrentUser`, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const getUserSubscription = () => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    'Authorization': `Bearer ${token}`,
  };
    
  return axios.get(`${apiBaseUrl}/GetSubscriptions`, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const updateUserDetails = (params) => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    'Authorization': `Bearer ${token}`,
  };  

  return axios.post(`${apiBaseUrl}/UpdateCustomerDetails`, params, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const getUserAddresses = () => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    'Authorization': `Bearer ${token}`,
  };
    
  return axios.get(`${apiBaseUrl}/GetSavedAddresses`, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const addUserAddress = (params) => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    'Authorization': `Bearer ${token}`,
  };  

  return axios.post(`${apiBaseUrl}/AddSavedAddress`, params, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const updateUserAddress = (params) => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    'Authorization': `Bearer ${token}`,
  };  

  return axios.post(`${apiBaseUrl}/UpdateSavedAddress`, params, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const deleteUserAddress = (params) => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    'Authorization': `Bearer ${token}`,
  };  

  return axios.post(`${apiBaseUrl}/DeleteSavedAddress`, params, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const login = (credentials) => {    
  return axios.post(`${apiBaseUrl}/Login`, credentials)
    .then(response => response)
    .catch(error => error);
};

export const register = (credentials) => {    
  return axios.post(`${apiBaseUrl}/CreateUser`, credentials)
    .then(response => response)
    .catch(error => error);
};

export const resetPasswordRequest = (email) => {
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    'Authorization': `Bearer ${token}`,
  };
  let params = {
    emailAddress: email,
  };

  return axios.post(`${apiBaseUrl}/ResetPassword`, params, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const contactUsRequest = (params) => {  
  const token = sessionStorage.getItem('token') || '';
  const headers = {
    'Authorization': `Bearer ${token}`,
  };  

  return axios.post(`${apiBaseUrl}/SendWebQuery`, params, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const findAddressesByPostcode = (postcode) => {
  return Axios.get(`${postcodeLookupApiUrl}Key=${postcodeLookupApiKey}&Text=${postcode}`)
    .then(response => {
      if (response.status === 200) {
        let containerId = response.data.Items[0].Id;
        return findAddressesByPostcodeAndContainerID(postcode, containerId);
      }
    })
    .catch(error => error);
};

const findAddressesByPostcodeAndContainerID = (postcode, containerId) => {
  return Axios.get(`${postcodeLookupApiUrl}Key=${postcodeLookupApiKey}&Text=${postcode}&Container=${containerId}`)
    .then(response => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch(error => error);
};

export const retrievePostcodeAddresses = (selectedAddressId) => {
  return Axios.get(`https://api.addressy.com/Capture/Interactive/Retrieve/v1.00/json3.ws?Key=${postcodeLookupApiKey}&Id=${selectedAddressId}`)
    .then(response => response.data)
    .catch(error => error);
};


export const verifyReCaptchaRequest = (secret, response) => {
  let params = {
    secret,
    response,
  };
  return Axios.post('https://www.google.com/recaptcha/api/siteverify', params)
    .then(response => response)
    .catch(error => error);
};
