import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as commonActions from '../../../actions/commonActions';
import { getPaymentResult } from '../../../api/shoppingCartApi';
import Spinner from '../../common/spinner/Spinner';
import PaymentResponse from '../../../models/paymentResponse';
import { getShoppingCart, clearShoppingCart, getCartForFirstDeliveryDate } from '../../../actions/shoppingCartAction';
import './PaymentConfirmation.css';
import * as moment from 'moment';
import priceUtils from '../../../shared/priceUtils';

class PaymentConfirmation extends Component {

  constructor() {
    super();

    this.state = {
      paymentResponse: PaymentResponse,
      loading: false,
    };

    this.onContinue = this.onContinue.bind(this);
    this.onTryAgain = this.onTryAgain.bind(this);
  }

  componentDidMount() {
    this.props.getCartForFirstDeliveryDate();

    if (this.props.match.params.id) {
      this.getPaymentConfirmation(this.props.match.params.id);
    }
  }
    
  getPaymentConfirmation(orderId) {    
    this.setState({ loading: true });    
    getPaymentResult(parseInt(orderId, 10))
      .then(response => {
        if (response.status === 200) {
          this.setState({
            paymentResponse: response.data, 
            loading: false,
          });

        }
        this.setState({ loading: false });
      })
      .catch(error => {
        console.log('Error getting payment response:', error);
        this.setState({ loading: false });
      });
  }

  getPaymentResponseMessage() {
    let response = {
      title: '',
      message: '',
      orderId: '',
    };
    switch(this.state.paymentResponse.status) {
    case 'AUTHORISED':
    case 'CAPTURED':
      response.title = 'Your payment was authorised';
      response.message = 'Thank you for shopping with us.';
      response.orderId = `Your order number is: ${this.state.paymentResponse.orderID}`;
      return response;
    case 'DECLINED':
    case 'FAIL':
      response.title = 'Your payment was declined';
      response.message = 'Please try again or contact your payment provider.';
      response.orderId = `Your order number is: ${this.state.paymentResponse.orderID}`;
      return response;
    case 'CANCELLED':
      response.title = 'Your payment was cancelled';
      response.message = 'Please try again or contact your payment provider.';
      response.orderId = `Your order number is: ${this.state.paymentResponse.orderID}`;
      return response;
    default:
      return response;
    }
  }

  onContinue() {
    this.props.fetchShoppingCart();
  }

  onTryAgain() {
    this.props.fetchShoppingCart();
    this.props.history.push(`${process.env.PUBLIC_URL}/`);
  }

  orderSummaryContent(success) {
    const { paymentResponse } = this.state;

    return (<div className="right">
      <div className="order-summary-wrapper">
        <div className="header">
          <h2>Order Summary</h2>
        </div>
        <div className="summary">
          <div className="item-row">
            <span className="item">Subtotal:</span>
            <span className="value">{priceUtils.FormatCurrency(paymentResponse.amount)}</span>
          </div>
          <div className="item-row">
            <span className="item">Delivery:</span>
            <span className="value">Free</span>
          </div>
          <div className="item-row total">
            <span className="item">Total:</span>
            <span className="value">{priceUtils.FormatCurrency(paymentResponse.amount)}</span>
          </div>
        </div>
        {
          success &&
          <>
            <div className="header">
              <h2>Delivery details</h2>
            </div>
            <div className="dpd-delivery-text">
              Our delivery partner, DPD, will let you know your 1-2 hour time slot from 7am on the morning your order is due.
            </div>
          </>
        }     
      </div>
    </div>);
  }

  render() {
    const { loading, paymentResponse } = this.state;
    const success = !(paymentResponse.transactionReference && (paymentResponse.status === 'CANCELLED' || paymentResponse.status === 'DECLINED' || paymentResponse.status === 'FAIL' || !paymentResponse.success));

    return (
      <div className="payment-confirmation-page-wrapper">
        <div className="contact-banner"></div>
        <div className="payment-confirmation-page">
          <div className="text-center">
            <Spinner show={loading}
              fontSize={25} 
            />
          </div>
          {
            !loading &&
            <div>
              <div className="cart-header">
                <ul>
                  <li className='active'>
                    <span>Delivery</span>
                    <span className='icon icon-checked' />
                  </li>
                  <li className='active'>
                    <span>Payment</span>
                    <span className='icon icon-checked' />
                  </li>
                  <li className='active'>
                    <span>Confirmation</span>
                  </li>
                </ul>
              </div>
              {
                success &&
                <div className="confirmation-page-content">
                  <div className="left">
                    <div className="thank-you-message">
                      <h1>
                        <span className="icon-orderconfirmation" />
                        Thank you for your order {this.props.user.firstName}
                      </h1>
                      <span className="text">
                        Your order number is 
                        <span className="order-number">{paymentResponse.orderID}</span>
                      </span>
                      <div>
                        <small>
                          We&quot;ll send you an order confirmation to your email address, please check your junk or spam folder.
                        </small>
                      </div>
                    </div>
                    {
                      paymentResponse && paymentResponse.customer && 
                      <div className="delivery-details">
                        <div className="content-wrapper right">
                          <div className="item">
                            <span className="icon icon-store" />
                            <div className="delivery-content">
                              <div className="name">{paymentResponse.customer.firstName} {paymentResponse.customer.lastName}</div>
                              <div className="details">
                                { 
                                  paymentResponse.deliveryAddress.addressLine1 && 
                                  <div>{paymentResponse.deliveryAddress.addressLine1}</div>
                                }
                                <div>
                                  {
                                    paymentResponse.deliveryAddress.addressLine2 &&
                                    <span className="address-details">
                                      {paymentResponse.deliveryAddress.addressLine2},
                                    </span>
                                  }
                                  {
                                    paymentResponse.deliveryAddress.town &&
                                    <span className="address-details">
                                      {paymentResponse.deliveryAddress.town},
                                    </span>
                                  }
                                </div>
                                {
                                  paymentResponse.deliveryAddress.postcode && 
                                  <div>{paymentResponse.deliveryAddress.postcode}</div>
                                }
                              </div>
                            </div>
                          </div>
                          {
                            paymentResponse.pickupLocation &&
                            <div className="item">
                              <span className="icon icon-location" />
                              <div className="delivery-content">
                                <div className="name">
                                  {paymentResponse.pickupLocation}    
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                        <div className="content-wrapper right">
                          <div className="item">
                            <span className="icon icon-calendar" />
                            <div className="delivery-content">
                              <div className="name">
                                {moment(paymentResponse.deliveryDate).format('dddd, Do MMMM')}
                              </div>
                            </div>
                          </div>
                          {
                            paymentResponse.timeslot &&
                            <div className="item">
                              <span className="icon icon-icon-clock" />
                              <div className="delivery-content">
                                <div className="name">
                                  {paymentResponse.timeslot}
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    }
                    <div className="button-wrapper">
                      <NavLink 
                        to={`${process.env.PUBLIC_URL}/`}
                        className="btn inverse-button">
                          Back to homepage
                      </NavLink>
                    </div>
                  </div>
                  {this.orderSummaryContent(success)}
                </div>
              }
              {
                !success &&
                <div className="confirmation-page-content">
                  <div className="left">
                    <div className="thank-you-message">
                      <h1>
                        {this.getPaymentResponseMessage().title}
                      </h1>
                      <span className="text">
                        Your order number is<span className="order-number">{paymentResponse.orderID}</span>
                      </span>
                      <div>
                        <small>
                          {this.getPaymentResponseMessage().message }
                        </small>
                      </div>
                      <div className="error-wrapper">
                        {
                          paymentResponse.errors &&
                          <div className="alert alert-danger">
                            <label>Payment request errors:</label>
                            <ul style={{ listStyle: 'none', padding: '0' }}>
                              {paymentResponse.errors.map((error, index) => <li key={index}>{error}</li>)}
                            </ul>
                          </div>
                        }
                      </div>
                      <div className="button-wrapper">
                        <button type="button" 
                          className="btn inverse-button" 
                          onClick={this.onTryAgain}>
                            Try again
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.orderSummaryContent(success)}
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shoppingCart: state.foodBoxesShoppingCart.data,
    user: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSiteWrapper: (status) => dispatch(commonActions.toggleSiteWrapper(status)),
    fetchShoppingCart: () => dispatch(getShoppingCart()),
    clearShoppingCart: () => dispatch(clearShoppingCart()),
    getCartForFirstDeliveryDate: () => dispatch(getCartForFirstDeliveryDate()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirmation);
