import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import FloatingInput from '../../../common/floatingInput/FloatingInput';
import PostcodeLookup from '../../../common/addressLookup/PostcodeLookup';
import * as userAddressActions from '../../../../actions/userAddressActions';
import { addUserAddress } from '../../../../api/userAccountApi';
import Spinner from '../../../common/spinner/Spinner';

class AddAddress extends Component {
  constructor() {
    super();

    this.state = {
      showAddAddressPanel: false,
      alias: { value: '', error: '' },
      addressLine1: { value: '', error: '' },
      addressLine2: { value: '', error: '' },
      county: { value: '', error: '' },
      town: { value: '', error: '' },
      postcode: { value: '', error: '' },
      deliveryInstructions: { value: '', error: '' },
      newAddressError: '',
      addressExistsError: '',
      addressSearched: false,
      manualAddressEntry: false,
      clearDownAddress: false,
      savingAddress: false,
    };

    this.onInputFieldChanged = this.onInputFieldChanged.bind(this);
    this.onAddressSelected = this.onAddressSelected.bind(this);
    this.onSaveAddress = this.onSaveAddress.bind(this);
  }

  resetNewAddress() {
    this.setState({
      showAddAddressPanel: false,
      alias: { value: '', error: '' },
      addressLine1: { value: '', error: '' },
      addressLine2: { value: '', error: '' },
      county: { value: '', error: '' },
      town: { value: '', error: '' },
      postcode: { value: '', error: '' },
      deliveryInstructions: { value: '', error: '' },
      newAddressError: '',
      addressExistsError: '',
      addressSearched: false,
      manualAddressEntry: false,
      clearDownAddress: false,
      savingAddress: false,
    });
  }

  onInputFieldChanged(name, value) {
    this.setState({
      [name]: { value: value, error: false },
    });
  }

  onAddressSelected(address) {
    let { addressLine1, addressLine2, county, town, postcode } = this.state;
    addressLine1.value = address.Line1;
    addressLine2.value = address.Line2;
    town.value = address.City;
    county.value = address.ProvinceName;
    postcode.value = address.PostalCode;
    this.setState({ addressLine1, addressLine2, county, town, postcode });
  }

  checkIfAddressAlreadyExists(addressLine1, postcode) {
    const { userAddresses } = this.props;
    if (userAddresses.length <= 0) {
      return false;
    }

        
    for (let i in userAddresses) {
      if (String(userAddresses[i].addressLine1).toLowerCase() === String(addressLine1).toLowerCase() 
                && String(userAddresses[i].postcode).toLowerCase() === String(postcode).toLowerCase()) {
        return true;
      }
    }
  }

  onSaveAddress() {
    const { user } = this.props;
    let { addressLine1, addressLine2, county, town, postcode, alias, deliveryInstructions } = this.state;

    // Check an address was entered.
    if (addressLine1.value.length <= 0 && postcode.value.length <= 0) {
      this.setState({ newAddressError: 'Please enter address details.' });
      return;
    } else if (this.checkIfAddressAlreadyExists(addressLine1.value.toLowerCase(), postcode.value.toLowerCase())) {
      // Check if address already exist in the list of addresses.
      this.setState({ addressExistsError: 'This address already exist in your list of addresses' });
      return;
    }

    if (!postcode.value) {
      toastr.error('Postcode is required. Please enter a postcode.');
      return;
    }

    let addressToSave = {
      alias: alias.value,
      firstName: user.firstName,
      lastName: user.lastName,
      deliveryInstructions: deliveryInstructions.value,
      addressLine1: addressLine1.value,
      addressLine2: addressLine2.value,
      town: town.value,
      county: county.value,
      postcode: postcode.value,
    };

    this.addAddress(addressToSave);
  }

  addAddress(address) {
    this.setState({ savingAddress: true });
    addUserAddress(address)
      .then(response => {
        if (response.status === 200) {
          if (response.data.Success) {
            toastr.success('New address was added successfully');
            this.props.addAddress(address);
            this.resetNewAddress();
            this.props.getUserAddresses();
          } else {
            toastr.error(response.data.ErrorMessage);
          }
        } else {
          toastr.error('Failed to add address. Please try again');
        }
        this.setState({ savingAddress: false });
      })
      .catch(error => {
        console.log('Unable to add address:', error);
        toastr.error('Failed to add address. Please try again');
        this.setState({ savingAddress: false });
      });
  }

  render() {
    const { addressSearched, newAddressError, addressExistsError, savingAddress, alias, postcode, clearDownAddress } = this.state;

    return (
      <div className="add-new-address">
        <label className="toggle-add-address"
          onClick={() => 
            this.setState({
              showAddAddressPanel: !this.state.showAddAddressPanel,
              clearDownAddress: !clearDownAddress,
              newAddressError: '',
              addressExistsError: '',
              addressSearched: false,
              manualAddressEntry: false,
              savingAddress: false,
              fullName: { value: '', error: '' },
              addressLine1: { value: '', error: '' },
              addressLine2: { value: '', error: '' },
              county: { value: '', error: '' },
              town: { value: '', error: '' },
              postcode: { value: '', error: '' },
            })}>
          <span>
            <span className="plus-icon" />&nbsp;&nbsp;
                        Add new address
          </span>
          <span className={`cancel ${this.state.showAddAddressPanel ? 'show' : 'hide'}`}>
                        Cancel &nbsp;&nbsp;<span className="times-icon" />
          </span>
        </label>
        <div className={`add-address-content ${this.state.showAddAddressPanel ? 'show' : 'hide'}`}>
          <FloatingInput
            label="Name (e.g. Home Work)"
            type="text"
            name="alias"
            error={alias.error}
            value={alias.value}
            inputChanged={this.onInputFieldChanged} />
          <PostcodeLookup 
            address={{ postcode: postcode }} 
            buttonTitle="Look it up"
            inverseButton
            includeExistingAddresses={false}
            clearDownAddress={this.state.clearDownAddress}
            onAddressSearched={() => this.setState({ addressSearched: true })} 
            onSelect={this.onAddressSelected} 
            showPostcodeLookup={true}
          />

          {addressExistsError.length > 0 && <div className="alert alert-danger text-center">
            {addressExistsError}
          </div>}

          <div className={`alert alert-danger text-center ${newAddressError.length <= 0 ? 'hide' : ''}`}>
            {newAddressError}
          </div>
          <div className={`form-group save-address ${addressSearched ? 'show' : 'hide'}`}>
            <button className="btn btn-block spinner-button primary-button"
              onClick={this.onSaveAddress}>
              <span className="text">Save</span>
              <Spinner show={savingAddress} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    userAddresses: state.userAddresses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAddress: address => dispatch(userAddressActions.addAddress(address)),
    getUserAddresses: () => dispatch(userAddressActions.fetchAddresses()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);