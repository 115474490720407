import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import $ from 'jquery';
import FloatingInput from '../common/floatingInput/FloatingInput';
import PostcodeLookup from '../common/addressLookup/PostcodeLookup';
import { register } from '../../actions/userActions';
import Spinner from '../common/spinner/Spinner';
import Logo from '../../assets/images/login-logo.png';
import OrderlyLogo from '../../assets/images/orderly_logo.png';
import { toggleHeaderVisibility } from '../../actions/headerAction';
import toastr from 'toastr';

// Stylesheet
import './Register.css';

class Register extends Component {

  constructor(props) {
    super(props);

    this.state = {
      emailAddress: { value: '', error: '' },
      password: { value: '', error: '' },
      customerName: { value: '', error: '' },
      firstName: { value: '', error: '' },
      lastName: { value: '', error: '' },
      phoneNumber: { value: '', error: '' },
      vatNumber: { value: '', error: '' },
      address: { value: '', error: '' },
      deliveryAddressLine1: { value: '', error: '' },
      deliveryAddressLine2: { value: '', error: '' },
      deliveryCounty: { value: '', error: '' },
      deliveryTown: { value: '', error: '' },
      deliveryPostcode: { value: '', error: '' },
      marketingAllowed: false,
      loading: false,
      registerError: '',
      revealPassword: false,
      showAddressError: false,
      addressErrorMessage: '',
    };

    this.onInputFieldChanged = this.onInputFieldChanged.bind(this);
    this.onAddressSelected = this.onAddressSelected.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.toggleHeaderVisibility(false);
  }

  componentWillUnmount() {
    this.props.toggleHeaderVisibility(true);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.clearDownForm !== this.props.clearDownForm) {
      this.resetForm();
    }
  }

  resetForm() {
    this.setState({
      emailAddress: { value: '', error: '' },
      password: { value: '', error: '' },
      customerName: { value: '', error: '' },
      firstName: { value: '', error: '' },
      lastName: { value: '', error: '' },
      phoneNumber: { value: '', error: '' },
      vatNumber: { value: '', error: '' },
      address: { value: '', error: '' },
      deliveryAddressLine1: { value: '', error: '' },
      deliveryAddressLine2: { value: '', error: '' },
      deliveryCounty: { value: '', error: '' },
      deliveryTown: { value: '', error: '' },
      deliveryPostcode: { value: '', error: '' },
      loading: false,
      registerError: '',
      revealPassword: false,
      marketingAllowed: false,
    });
  }

  onInputFieldChanged(name, value) {
    this.setState({
      [name]: { value: value, error: false },
      registerError: '',
    });
  }

  onLogin() {
    this.setState({ showLoginModal: !this.state.showLoginModal });
  }

  emailIsValid(email) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return regex.test(email);
  }

  vatNumberIsValid(vat){
    let regex = /^(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/;
    return regex.test(vat);
  }

  handleValidation() {
    const { emailAddress, 
      password, 
      customerName, 
      firstName, 
      lastName, 
      phoneNumber, 
      vatNumber, 
      address, 
      deliveryPostcode, 
      addressSearched,
      deliveryAddressLine1 } = this.state;
    let errorCount = 0;

    if (emailAddress.value.length <= 0) {
      emailAddress.error = 'Email address is required';
      errorCount += 1;
    } else if(!this.emailIsValid(emailAddress.value)) {
      emailAddress.error = 'Invalid email address entered';
      errorCount += 1;
    }

    let lowerCaseLettersRegex = /[a-z]/g;
    let upperCaseLettersRegex = /[A-Z]/g;
    let numbersRegex = /[0-9]/g;

    if (password.value.length <= 0) {
      password.error = 'Password is required';
      errorCount += 1;
    } else if (password.value.length < 8) {
      password.error = 'Password must be a minimum of 8 characters long';
      errorCount += 1;
    } else if (!lowerCaseLettersRegex.test(password.value)) {
      password.error = 'Password must contain a lowercase character';
      errorCount += 1;
    } else if (!upperCaseLettersRegex.test(password.value)) {
      password.error = 'Password must contain an uppercase character';
      errorCount += 1;
    } else if (!numbersRegex.test(password.value)) {
      password.error = 'Password must contain a number';
      errorCount += 1;
    }

    if (customerName.value.length <= 0) {
      customerName.error = 'Customer Name is required';
      errorCount += 1;
    }

    if (firstName.value.length <= 0) {
      firstName.error = 'First Name is required';
      errorCount += 1;
    }

    if (lastName.value.length <= 0) {
      lastName.error = 'Last Name is required';
      errorCount += 1;
    }

    var phoneNumberRegex = /^([\d() +-]+)$/;
    if (phoneNumber.value.length <= 0) {
      phoneNumber.error = 'Phone Number is required';
      errorCount += 1;
    }
    else if (!phoneNumber.value.match(phoneNumberRegex) || phoneNumber.value.length < 9) {
      phoneNumber.error = 'Phone Number is not valid';
      errorCount += 1;
    }

    if (vatNumber.value.length <= 0) {
      vatNumber.error = 'VAT Number is required';
      errorCount += 1;
    }
    if (vatNumber.value.length > 0 && !this.vatNumberIsValid(vatNumber.value)) {
      vatNumber.error = 'VAT Number is not valid';
      errorCount += 1;
    }
    

    if (address.value.length <= 0) {
      address.error = 'Registered Company Address is required';
      errorCount += 1;
    }

    if (deliveryPostcode.value.length <= 0) {
      deliveryPostcode.error = 'Delivery Postcode is required';
      errorCount += 1;
    }

    if(!addressSearched){
      this.setState({ showAddressError: true, addressErrorMessage: 'Please enter delivery postcode and search for available addresses' });
    }
    else if(addressSearched && deliveryAddressLine1.value.length <= 0){
      this.setState({ showAddressError: true, addressErrorMessage: 'Please select an address first' });
    }
    else {
      this.setState({ showAddressError: false, addressErrorMessage: '' });
    }

    if (errorCount > 0) {
      this.setState({ emailAddress, password, customerName, firstName, lastName, phoneNumber, vatNumber, address, deliveryPostcode });
      $('#scrollOnError').focus();
      return false;
    }
    return true;
  }

  onAddressSelected(address) {
    let { deliveryAddressLine1, deliveryAddressLine2, deliveryCounty, deliveryTown, deliveryPostcode } = this.state;
    deliveryAddressLine1.value = address.Line1;
    deliveryAddressLine2.value = address.Line2;
    deliveryTown.value = address.City;
    deliveryCounty.value = address.ProvinceName;
    deliveryPostcode.value = address.PostalCode;
    this.setState({ deliveryAddressLine1, deliveryAddressLine2, deliveryCounty, deliveryTown, deliveryPostcode });
  }

  onSubmit(e) {
    e.preventDefault();     
    if (this.handleValidation()) {
      const { 
        emailAddress, 
        password, 
        customerName, 
        firstName, 
        lastName, 
        phoneNumber, 
        vatNumber, 
        address, 
        deliveryAddressLine1, 
        deliveryAddressLine2, 
        deliveryCounty, 
        deliveryPostcode, 
        deliveryTown, 
        marketingAllowed } = this.state;

      let credentials = {
        emailAddress: emailAddress.value,
        password: password.value,
        customerName: customerName.value,
        firstName: firstName.value,
        lastName: lastName.value,
        phoneNumber: phoneNumber.value,
        vatNumber: vatNumber.value,
        address: address.value,
        deliveryAddressLine1: deliveryAddressLine1.value,
        deliveryAddressLine2: deliveryAddressLine2.value,
        deliveryPostcode: deliveryPostcode.value,
        deliveryCounty: deliveryCounty.value,
        deliveryTown: deliveryTown.value,
        marketingAllowed: marketingAllowed,
      };

      this.props.register(credentials)
        .then((resp) => {
          const { Success } = resp.payload;
          if (Success) {
            toastr.success('Request successfully sent. We will be in touch.');
            this.resetForm();                        
          }
        })
        .catch(error => {
          console.log('User registration error', error);
        });
    }
  }

  render() {
    const { emailAddress, 
      password, 
      customerName,
      firstName, 
      lastName,
      phoneNumber, 
      vatNumber, 
      address,
      revealPassword,
      deliveryPostcode,
      showAddressError,
      addressErrorMessage,
      marketingAllowed } = this.state;

    const { loading, error } = this.props;

    return (
      <div id="createAccount"
        className="bbs-register">
        <div className="register-container">
          <div className="left">
            <img src={Logo} />
            <div className="text">
              <h2>Wholesale Ordering Portal</h2>
              <h4>Delivering Quality Sustainable Seafood to Businesses</h4>
            </div>
            <p className="orderly-info">
                            Powered by Orderly. Our supply chain technology drives responsibility, generating long-term economic value and sustainability.
              <a href="https://orderly.io/"
                className="login-link"
                target="blank"> Learn More About Orderly.</a>
            </p>
          </div>
          <div className="right">
            <div className="register-panel">
              <h3>Register</h3>
              <p>Please register for an account to access the Big Blue Sea Portal.</p>
              <input type="text"
                id="scrollOnError"
                style={{ opacity: 0, innerHeight: 0 }} />
              <form name="createAccountForm"
                onSubmit={this.onSubmit}>
                <FloatingInput
                  label="Email address"
                  type="email"
                  name="emailAddress"
                  error={emailAddress.error}
                  value={emailAddress.value}
                  inputChanged={this.onInputFieldChanged} 
                />
                <div className="form-group password-wrapper">
                  <FloatingInput
                    label="Password"
                    type={revealPassword ? 'text' : 'password'}
                    name="password"
                    error={password.error}
                    value={password.value}
                    inputChanged={this.onInputFieldChanged} />
                  <div className="eye-icon"
                    onClick={() => this.setState({ revealPassword: !revealPassword })}>
                    <span className={revealPassword ? 'no-visibility-icon' : 'visibility-icon'} />
                  </div>
                </div>
                <FloatingInput
                  label="Customer Name"
                  type="text"
                  name="customerName"
                  error={customerName.error}
                  value={customerName.value}
                  inputChanged={this.onInputFieldChanged} 
                />
                <FloatingInput
                  label="Contact First Name"
                  type="text"
                  name="firstName"
                  error={firstName.error}
                  value={firstName.value}
                  inputChanged={this.onInputFieldChanged} 
                />
                <FloatingInput
                  label="Contact Last Name"
                  type="text"
                  name="lastName"
                  error={lastName.error}
                  value={lastName.value}
                  inputChanged={this.onInputFieldChanged} 
                />
                <FloatingInput
                  label="Phone Number"
                  type="text"
                  name="phoneNumber"
                  error={phoneNumber.error}
                  value={phoneNumber.value}
                  inputChanged={this.onInputFieldChanged} 
                />
                <FloatingInput
                  label="Company VAT Number"
                  type="text"
                  name="vatNumber"
                  error={vatNumber.error}
                  value={vatNumber.value}
                  inputChanged={this.onInputFieldChanged} 
                />
                <FloatingInput
                  label="Registered Company Address"
                  type="text"
                  name="address"
                  error={address.error}
                  value={address.value}
                  inputChanged={this.onInputFieldChanged} 
                />
                <PostcodeLookup 
                  address={{ postcode: deliveryPostcode }} 
                  buttonTitle="Look it up"
                  inverseButton
                  includeExistingAddresses={false}
                  clearDownAddress={this.state.clearDownAddress}
                  onAddressSearched={() => this.setState({ addressSearched: true })} 
                  onSelect={this.onAddressSelected} 
                  showPostcodeLookup={true}
                />
                {error && error.length > 0 && 
                  <div className={'alert alert-danger text-center '}>{error}</div>
                }
                {showAddressError && 
                  <div className={'alert alert-danger text-center '}>{addressErrorMessage}</div>
                }
                <div className="inline-checkbox">
                  <div className="checkbox-col">
                    <input
                      type="checkbox"
                      name="marketingAllowed"
                      value={marketingAllowed}
                      onChange={() => this.setState({ marketingAllowed: !marketingAllowed })} />
                  </div>
                  <div className="text-col">
                    <p>
                      We&apos;d love to send you tailored offers, coupons, and communications via email, at the till, through the post, online and in app. 
                      Tick the box if you&apos;d like to receive exclusive offers and communications.
                    </p>
                  </div>
                </div>
                <div className="button-wrapper">
                  <button type="submit"
                    className="btn-block spinner-button primary-button">
                    <span className="text">Register</span>
                    <Spinner show={loading} />
                  </button>
                </div>
              </form>
              <div className="not-registered">
                <p>Already have an account?</p>  
                <p> 
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/login`}
                  >
                    Login
                  </NavLink>
                </p>
              </div>
            </div>
            <div className="orderly-logo">
              <a href="https://orderly.io/"
                className="login-link"
                target="blank">                         
                <img src={OrderlyLogo}
                  alt="Orderly logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: credentials => dispatch(register(credentials)),
    toggleHeaderVisibility: (status) => dispatch(toggleHeaderVisibility(status)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));