import axios from './axiosConfig';
import * as apiResources from './apiResources';
import apiRequestService from '../services/apiRequestService';

const { apiBaseUrl } = apiResources.apiResource();

export const getDeliveriesRequest = () => {
  const headers = apiRequestService.getHeaders();
    
  return axios.get(`${apiBaseUrl}/GetDeliveries`, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const cancelDeliveriesRequest = (params) => {
  const headers = apiRequestService.getHeaders();

  return axios.post(`${apiBaseUrl}/CancelOrder`, params, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const editDeliveryRequest = (params) => {
  const headers = apiRequestService.getHeaders();

  return axios.post(`${apiBaseUrl}/EditOrder`, params, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const checkPostcodeIsSupportedRequest = (postcode) => {
  const headers = apiRequestService.getHeaders();

  return axios.get(`${apiBaseUrl}/IsPostcodeSupported?postcode=${postcode}`, { headers: headers })
    .then(response => response)
    .catch(error => error);
};

export const checkDeliveryAddressAvailable = (addressId) => {
  const headers = apiRequestService.getHeaders();

  return axios.get(`${apiBaseUrl}/checkDeliveryAddressAvailable?addressId=${addressId}`, { headers: headers })
    .then(response => response)
    .catch(error => error);
};