
import { RSAA } from 'redux-api-middleware';
import apiRequestService from '../services/apiRequestService';

export const GET_DELIVERY_DATES_REQUEST = 'GET_DELIVERY_DATES_REQUEST';
export const GET_DELIVERY_DATES_SUCCESS = 'GET_DELIVERY_DATES_SUCCESS';
export const GET_DELIVERY_DATES_ERROR = 'GET_DELIVERY_DATES_ERROR';

export const getDeliveryDates = () => {
  const headers = apiRequestService.getHeaders();
  
  return {
    [RSAA]: {
      headers,
      endpoint: apiRequestService.getEndpoint('GetDeliveryDates'),
      method: 'GET',
      types: [
        GET_DELIVERY_DATES_REQUEST,
        GET_DELIVERY_DATES_SUCCESS,
        GET_DELIVERY_DATES_ERROR,
      ],
    },
  };
};