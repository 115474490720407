import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavigationLoggedIn from './navigation/NavigationLoggedIn';
import NavigationLoggedOut from './navigation/NavigationLoggedOut';
import './navigation/Navigation.css';

class Header extends Component {

  constructor() {
    super();

    this.state = {
      hideHeader: false,
    };
  }

  render() {
    const { showSiteWrapper, isAuthenticated } = this.props;
    return (
      <header className={`${!showSiteWrapper ? 'hide' : null}`}>
        {
          isAuthenticated ? <NavigationLoggedIn/> : <NavigationLoggedOut/>
        }
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showSiteWrapper: state.showSiteWrapper,
    isAuthenticated: state.user.data.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Header);