import { RSAA } from 'redux-api-middleware';
import apiConfig from '../api/apiConfig';
import { copyOrderRequest } from '../api/shoppingCartApi';
import apiRequestService from '../services/apiRequestService';
import toastr from 'toastr';

export const GET_FOOD_BOXES_SHOPPING_CART_REQUEST = 'GET_FOOD_BOXES_SHOPPING_CART_REQUEST';
export const GET_FOOD_BOXES_SHOPPING_CART_SUCCESS = 'GET_FOOD_BOXES_SHOPPING_CART_SUCCESS';
export const GET_FOOD_BOXES_SHOPPING_CART_ERROR = 'GET_FOOD_BOXES_SHOPPING_CART_ERROR';

export const ADD_FOOD_BOX_SUCCESS = 'ADD_FOOD_BOX_SUCCESS';
export const UPDATE_DELIVERY_DETAILS_SUCCESS = 'UPDATE_DELIVERY_DETAILS_SUCCESS';
export const UPDATE_DELIVERY_ADDRESS_LOCALLY = 'UPDATE_DELIVERY_ADDRESS_LOCALLY';
export const UPDATE_SHOPPING_CART_SUCCESS = 'UPDATE_SHOPPING_CART_SUCCESS';
export const GET_PAYMENT_REQUEST_SUCCESS = 'GET_PAYMENT_REQUEST_SUCCESS';
export const GET_PAYMENT_CONFIRMATION_SUCCESS = 'GET_PAYMENT_CONFIRMATION_SUCCESS';
export const REMOVE_SHOPPING_CART = 'REMOVE_SHOPPING_CART';

export const CLEAR_SHOPPING_CART_REQUEST = 'CLEAR_SHOPPING_CART_REQUEST';
export const CLEAR_SHOPPING_CART_SUCCESS = 'CLEAR_SHOPPING_CART_SUCCESS';
export const CLEAR_SHOPPING_CART_ERROR = 'CLEAR_SHOPPING_CART_ERROR';

export const removeShoppingCart = () => {
  return {
    type: REMOVE_SHOPPING_CART,
  };
};

export const updateDeliveryAddressLocally = (address) => {
  return dispatch => {
    dispatch({
      type: UPDATE_DELIVERY_ADDRESS_LOCALLY,
      payload: address,
    });
  };
};

export const updateShoppingCart = (shoppingCart) => {
  return {
    type: UPDATE_SHOPPING_CART_SUCCESS,
    payload: shoppingCart,
  };
};

export const clearShoppingCart = () => {
  const headers = apiRequestService.getHeaders();

  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/ClearShoppingCart`,
      method: 'POST',
      types: [
        CLEAR_SHOPPING_CART_REQUEST,
        CLEAR_SHOPPING_CART_SUCCESS,
        CLEAR_SHOPPING_CART_ERROR,
      ],
    },
  };
}; 

export const createNewCart = (deliveryDate) => {
  const headers = apiRequestService.getHeaders();
  const params = {
    deliveryDate,
  };
  
  return {
    [RSAA]: {
      headers,
      endpoint: apiRequestService.getEndpoint('CreateNewCart'),
      method: 'POST',
      body: JSON.stringify(params),
      types: [
        GET_FOOD_BOXES_SHOPPING_CART_REQUEST,
        GET_FOOD_BOXES_SHOPPING_CART_SUCCESS,
        GET_FOOD_BOXES_SHOPPING_CART_ERROR,
      ],
    },
  };
};

export const copyOrder = (orderId, deliveryDate) => {
  return async dispatch => {
    try {
      const response = await copyOrderRequest(orderId, deliveryDate);

      if (response.status === 200) {
        const shoppingCart = response.data.ShoppingCart;

        dispatch(updateShoppingCart(shoppingCart));
        toastr.success('Your order has been copied successfully.');
      }
      else {
        toastr.error(response.data.ErrorMessage);
      }
    }
    catch (error) {
      console.log('Error editing order:', error);
    }
  };
};

export const getShoppingCart = () => {
  const headers = apiRequestService.getHeaders();

  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/GetShoppingCart`,
      method: 'GET',
      types: [
        GET_FOOD_BOXES_SHOPPING_CART_REQUEST,
        GET_FOOD_BOXES_SHOPPING_CART_SUCCESS,
        GET_FOOD_BOXES_SHOPPING_CART_ERROR,
      ],
    },
  };
};

export const getCartForFirstDeliveryDate = () => {
  const headers = apiRequestService.getHeaders();
  
  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/GetCartForFirstDeliveryDate`,
      method: 'GET',
      types: [
        GET_FOOD_BOXES_SHOPPING_CART_REQUEST,
        GET_FOOD_BOXES_SHOPPING_CART_SUCCESS,
        GET_FOOD_BOXES_SHOPPING_CART_ERROR,
      ],
    },
  };
};

export const addToCart = (params) => {
  const headers = apiRequestService.getHeaders();

  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/AddToCart`,
      method: 'POST',
      body: JSON.stringify(params),
      types: [
        GET_FOOD_BOXES_SHOPPING_CART_REQUEST,
        ADD_FOOD_BOX_SUCCESS,
        GET_FOOD_BOXES_SHOPPING_CART_ERROR,
      ],
    },
  };
};

export const removeFromCart = (id) => {
  const headers = apiRequestService.getHeaders();
  const params = { productOptionId: id };
  
  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/RemoveFromCart`,
      method: 'POST',
      body: JSON.stringify(params),
      types: [
        GET_FOOD_BOXES_SHOPPING_CART_REQUEST,
        ADD_FOOD_BOX_SUCCESS,
        GET_FOOD_BOXES_SHOPPING_CART_ERROR,
      ],
    },
  };
};

export const updateQuantityInCart = (params) => {
  const headers = apiRequestService.getHeaders();

  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/UpdateQuantityInCart`,
      method: 'POST',
      body: JSON.stringify(params),
      types: [
        GET_FOOD_BOXES_SHOPPING_CART_REQUEST,
        ADD_FOOD_BOX_SUCCESS,
        GET_FOOD_BOXES_SHOPPING_CART_ERROR,
      ],
    },
  };
};

export const updateDeliveryDetails = (params) => {
  const headers = apiRequestService.getHeaders();

  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/UpdateDeliveryDetails`,
      method: 'POST',
      body: JSON.stringify(params),
      types: [
        GET_FOOD_BOXES_SHOPPING_CART_REQUEST,
        UPDATE_DELIVERY_DETAILS_SUCCESS,
        GET_FOOD_BOXES_SHOPPING_CART_ERROR,
      ],
    },
  };
};

export const getPaymentRequest = () => {
  const headers = apiRequestService.getHeaders();

  let params = {
    redirectUrl: `${window.location.origin}/order-confirmation`,
  };

  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/PaymentRequest`,
      method: 'POST',
      body: JSON.stringify(params),
      types: [
        GET_FOOD_BOXES_SHOPPING_CART_REQUEST,
        GET_PAYMENT_REQUEST_SUCCESS,
        GET_FOOD_BOXES_SHOPPING_CART_ERROR,
      ],
    },
  };
};

export const getPaymentConfirmation = (orderId) => {
  const headers = apiRequestService.getHeaders();

  return {
    [RSAA]: {
      headers,
      endpoint: `${apiConfig.apiBaseUrl}/GetPaymentResult?orderID=${orderId}`,
      method: 'GET',
      types: [
        GET_FOOD_BOXES_SHOPPING_CART_REQUEST,
        GET_PAYMENT_CONFIRMATION_SUCCESS,
        GET_FOOD_BOXES_SHOPPING_CART_ERROR,
      ],
    },
  };
};