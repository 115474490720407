const paymentResponse = {
  amount: 0,
  customer: {},
  deliveryAddress: {},
  deliveryCost: '',
  deliveryDate: '',
  discount: 0,
  errors: null,
  items: [],
  meals: 0,
  morePoints: 0,
  orderID: '',
  orderTotal: 0,
  portions: 0,
  status: '',
  subTotal: 0,
  subscriptionType: '',
  success: null,
  tax: 0,
  transactionReference: '',
};

export default paymentResponse;