import * as actionType from '../actions/actionTypes';

const initialModalState = {
  open: false,
  type: '',
  clearDownForm: false,
  registrationSuccess: false,
};

const termsAndConditionsInitialState = {
  vourcherCodeText: 'Voucher code FRESH50 valid on first orders only. Baskets above £25.',
};

export const modalToggleReducer = (state = initialModalState, action) => {
  switch(action.type) {
  case actionType.MODAL_TOGGLE_SUCCESS:
    // return {...state, modal: action.modal};
    return Object.assign({}, action.modal);

  default:
    return state;
  }
};

export const showBasketTrayReducer = (state = false, action) => {
  switch(action.type) {
  case actionType.SHOW_BASKET_TRAY:
    return action.status;

  default:
    return state;
  }
};

export const toggleSiteWrapperReducer = (state = true, action) => {
  switch(action.type) {
  case actionType.TOGGLE_SITE_WRAPPER:
    return action.status;

  default:
    return state;
  }
};

export const fetchingUserDataStatusReducer = (state = false, action) => {
  switch(action.type) {
  case actionType.FETCHING_USER_DATA_STATUS:
    return action.status;

  default:
    return state;
  }
};

export const fetchTermsAndConditionsReducer = (state = termsAndConditionsInitialState, action) => {
  switch(action.type) {
  case actionType.FETCH_TERMS_AND_CONDITIONS:
    return action.data;

  default:
    return state;
  }
};

export const showDeliveryDatePickerReducer = (state = false, action) => {
  switch(action.type) {
  case actionType.SHOW_DELIVERY_DATE_PICKER:
    return action.status;

  default:
    return state;
  }
};