import React, { Component } from 'react';
import AccountDeliveriesPage from './AccountDeliveriesPage';

class OrderHistory extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <AccountDeliveriesPage filters={[]} />  
    );
  }
}

export default OrderHistory;