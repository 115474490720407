import { GET_DELIVERY_DATES_ERROR, GET_DELIVERY_DATES_REQUEST, GET_DELIVERY_DATES_SUCCESS } from '../actions/deliveryDatesActions';

const initialState = {
  loading: false,
  error: '',
  data: [],
};

const deliveryDatesReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_DELIVERY_DATES_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_DELIVERY_DATES_SUCCESS: {
    return {
      ...state,
      loading: false,
      data: action.payload,
    };
  }
  case GET_DELIVERY_DATES_ERROR: {
    return {
      ...state,
      error: action.payload,
    };
  }
  default:
    return state;
  }
};

export default deliveryDatesReducer;