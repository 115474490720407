import {
  FETCH_ADDRESSES_SUCCESS,
  ADD_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_SUCCESS,
  REMOVE_SAVED_ADDRESSES,
} from '../actions/userAddressActions';

const initialState = [];

const userAddressesReducer = (state = initialState, action) => {
  switch(action.type) {
  case FETCH_ADDRESSES_SUCCESS:
    return Object.assign([], action.addresses);

  case ADD_ADDRESS_SUCCESS:
    let addresses = Object.assign([], state);
    addresses.push(action.address);
    return Object.assign([], addresses);

  case UPDATE_ADDRESS_SUCCESS:
    let addressesList = Object.assign([], state);            
    //Match existing address with update.
    if (addressesList.length > 0) {
      for (let i in addressesList) {
        if (addressesList[i].addressID === action.address.addressID) {
          addressesList[i] = action.address;
        }
      }
    }
    return Object.assign([], addressesList);

  case DELETE_ADDRESS_SUCCESS:
    let allAddresses = Object.assign([], state);
    let result = [];
    if (allAddresses.length > 0) {
      for (let i in allAddresses) {
        if (allAddresses[i].addressID !== action.addressID) {
          result.push(allAddresses[i]);
        }
      }
    }
    return Object.assign([], result);

  case REMOVE_SAVED_ADDRESSES:
    return initialState;

  default:
    return state;
  }
};

export default userAddressesReducer;