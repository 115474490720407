import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import enhanceWithClickOutside from 'react-click-outside';
import * as userAccountApi from '../../../api/userAccountApi';
import ItemSelect from '../../common/itemSelect/ItemSelect';
import FloatingInput from '../../common/floatingInput/FloatingInput';
import Spinner from '../../common/spinner/Spinner';
import { getDeliveryServicesRequest } from '../../../api/shoppingCartApi';
import { checkPostcodeIsSupportedRequest } from '../../../api/deliveriesApi';
import { fetchAddresses } from '../../../actions/userAddressActions';
import './PostcodeLookup.css';

class PostcodeLookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postcode: {
        value: this.props.address.postcode ? this.props.address.postcode.value : '',
        error: this.props.address.postcode ? this.props.address.postcode.error : '',
      },
      postcodeLookupResults: {
        hasError: false,
        addresses: [],
        searched: false,
        title: '--- Please select your address ---',
        showAddresses: false,
      },
      searching: false,
      invalidPostcode: false,
      manualAddressEntry: false,
      showExistingAddresses: false,
      existingAddressTitle: this.props.loadingUserAddresses ? 'Fetching your existing addresses': 'Select from your existing addresses',
      selectedLookupAddressExists: '',
    };

    this.onPostcodeChange = this.onPostcodeChange.bind(this);
    this.checkPostcodeIsSupported = this.checkPostcodeIsSupported.bind(this);
    this.findAddressByPostcode = this.findAddressByPostcode.bind(this);
    this.onAddressSelected = this.onAddressSelected.bind(this);
    this.checkDeliveryIsAvailable = this.checkDeliveryIsAvailable.bind(this);
    this.onExistingAddressSelected = this.onExistingAddressSelected.bind(this);
  }

  componentDidMount() {
    const { user, getUserAddresses } = this.props;

    if (user.isAuthenticated) {
      getUserAddresses();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.clearDownAddress !== this.props.clearDownAddress) {
      this.resetLookupData();
    }
    if (newProps.address && newProps.address.postcode && newProps.address.postcode.value !== this.props.address.postcode.value) {
      this.setState({
        postcode: {
          value: newProps.address.postcode ? newProps.address.postcode.value : '',
        },
        postcodeLookupResults: {
          hasError: false,
          addresses: [],
          searched: false,
          title: '--- Please select your address ---',
          showAddresses: false,
        },
        selectedLookupAddressExists: '',
      });
    }
  }

  resetLookupData() {
    let { postcode, postcodeLookupResults } = this.props;
    postcode = {
      value: '',
      error: '',
    };
    postcodeLookupResults = {
      hasError: false,
      addresses: [],
      searched: false,
      title: '--- Please select your address ---',
      showAddresses: false,
    };
    this.setState({ postcode, postcodeLookupResults, selectedLookupAddressExists: '' });
  }

  onExistingAddressSelected(e) {
    const { userAddresses } = this.props;
    let address = null;
    for (let i in userAddresses) {
      if (userAddresses[i].addressID + '' === e.target.dataset.id + '') {
        address = userAddresses[i];
      }
    }

    if (address) {
      this.setState({
        showExistingAddresses: false,
        existingAddressTitle: `${address.addressLine1} ${address.addressLine2} ${address.town} ${address.county} ${address.postcode}`,
      });
      this.props.onSelect({
        ID: address.addressID,
        Line1: address.addressLine1,
        Line2: address.addressLine2,
        City: address.town,
        ProvinceName: address.county,
        PostalCode: address.postcode,
      });
      this.resetLookupData();
    }
  }

  onPostcodeChange(name, value) {
    this.setState({
      [name]: {
        value: value,
        error: false,
      },
    });
  }

  postcodeIsValid(postcode) {
    let regex = /[A-Za-z]{1,2}[0-9][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}/g;
    return regex.test(postcode);
  }

  checkDeliveryIsAvailable(e) {
    e.preventDefault();
    if (this.state.postcode.value.length <= 0) {
      this.setState({
        postcode: {
          value: this.state.postcode.value,
          error: 'Postcode is required',
        },
      });
      return;
    }
    // Regex to check postcode format.
    if (!this.postcodeIsValid(this.state.postcode.value)) {
      this.setState({
        postcode: {
          value: this.state.postcode.value,
          error: 'Invalid postcode entered',
        },
      });
      return;
    }

    // If postcode is ok update state and search postcode lookup api.
    let postcodeLookupResults = this.state.postcodeLookupResults;
    postcodeLookupResults['addresses'] = [];
    postcodeLookupResults['searched'] = false;

    this.setState({
      searching: true,
      postcodeLookupResults: postcodeLookupResults,
      manualAddressEntry: false,
    });
    getDeliveryServicesRequest(this.state.postcode.value)
      .then(response => {
        if (response.status === 200) {
          if (response.data.GetDeliveryServicesResult.NextWorkingDay) {
            this.findAddressByPostcode(e);
          } else {
            this.setState({
              postcode: { error: 'Sorry, we do not deliver to this postcode' },
              searching: false,
            });
          }
        } else {
          toastr.error('Failed to get delivery service');
          this.setState({ searching: false });
        }
      })
      .catch(error => {
        console.error('Failed to get delivery service:', error);
        toastr.error('Failed to get delivery service');
        this.setState({ searching: false });
      });
  }

  checkPostcodeIsSupported(e) {
    if (!this.state.postcode.value) {
      return;
    }
    e.preventDefault();
    this.setState({ 
      postcodeLookupResults: {
        hasError: false,
        addresses: [],
        searched: false,
        title: '--- Please select your address ---',
        showAddresses: false,
      },
      selectedLookupAddressExists: '', 
      searching: true, 
    });
    checkPostcodeIsSupportedRequest(this.state.postcode.value)
      .then(response => {
        if (response.status === 200 && response.data) {
          // Find address if postcode is supported.
          this.findAddressByPostcode();
        } else if (!response.data) {
          this.setState({ 
            selectedLookupAddressExists: `Unfortunately we do not deliver to your postcode: ${this.state.postcode.value}`,
            searching: false, 
          });
        }
      })
      .catch(() => {
        this.setState({ searching: false });
      });
  }
    
  findAddressByPostcode() {
    this.setState({ 
      searching: true, 
    });
    userAccountApi.findAddressesByPostcode(this.state.postcode.value)
      .then(response => {
        let { postcodeLookupResults } = this.state;
        postcodeLookupResults['addresses'] = response.Items;
        postcodeLookupResults['searched'] = true;

        // Update state with new data.
        this.setState({
          postcodeLookupResults: postcodeLookupResults ? postcodeLookupResults : [],
          searching: false,
        });
        this.props.onAddressSearched();
      })
      .catch(() => {
        this.setState({ searching: false });
      });
  }

  onAddressSelected(id) {
    userAccountApi.retrievePostcodeAddresses(id)
      .then(response => {
        if (this.checkAddressExists(response.Items[0].Line1, response.Items[0].PostalCode)) {
          this.setState({
            showExistingAddresses: false,
            existingAddressTitle: 'Select from your existing addresses',
            selectedLookupAddressExists: 'This address already exists in your list of addresses',
          });
        } else {
          this.props.onSelect(response.Items[0]);
          this.setState({
            showExistingAddresses: false,
            existingAddressTitle: 'Select from your existing addresses',
            selectedLookupAddressExists: '',
          });
        }
      });
  }

  checkAddressExists(addressLine1, postcode) {
    const { userAddresses } = this.props;
    if (userAddresses.length <= 0) {
      return false;
    }

    let matchFoundCount = 0;

    // Loop through individual addresses to find a match.
    for(let i in userAddresses) {
      // Convert to lowercases and Strip spaces.
      let existingAddressLine1 = userAddresses[i].addressLine1.toLowerCase().split(' ').join('');
      let newAddressLine1 = addressLine1.toLowerCase().split(' ').join('');
      let existingPostcode = userAddresses[i].postcode.toLowerCase().split(' ').join('');
      let newPostcode = postcode.toLowerCase().split(' ').join('');

      // Check for existence.
      if (existingAddressLine1 === newAddressLine1 && existingPostcode === newPostcode) {
        matchFoundCount += 1;
      }
    }

    return matchFoundCount > 0;
  }

  handleClickOutside() {
    this.setState({ showExistingAddresses: false });
  }

  render() {
    const { postcode, postcodeLookupResults, searching, manualAddressEntry, existingAddressTitle, showExistingAddresses, selectedLookupAddressExists } = this.state;
    const { userAddresses, loadingUserAddresses, includeExistingAddresses, showPostcodeLookup } = this.props;
    return (
      <div className="postcode-lookup">
        {
          searching &&
          <div className="spinner-wrapper">
            <Spinner show
              fontSize={25} />
          </div>
        }
        {
          !loadingUserAddresses && userAddresses.length > 0 && includeExistingAddresses && 
          <div className="existing-address-select">
            <div className="select-title"
              onClick={() => this.setState({ showExistingAddresses: !showExistingAddresses })}>
              <div className="text"
                title={existingAddressTitle}>{existingAddressTitle}</div>
              <div className="address-icon">
                {!loadingUserAddresses && <span className="chevron-arrow-down-icon" />}
                <Spinner show={loadingUserAddresses} />
              </div>
            </div>
            {
              showExistingAddresses &&
              <ul className="existing-address-list">
                {
                  userAddresses.map(address => 
                    <li key={address.addressID}>
                      <div className="content">
                        {address.addressLine1 && <span>{address.addressLine1}&nbsp;</span>}
                        {address.addressLine2 && <span>{address.addressLine2}&nbsp;</span>}
                        {address.town && <span>{address.town},&nbsp;</span>}
                        {address.county && <span>{address.county}.&nbsp;</span>}
                        {address.postcode && <span>{address.postcode}</span>}
                      </div>
                      <div className="address-target"
                        data-id={address.addressID} 
                        onClick={this.onExistingAddressSelected} />
                    </li>,
                  )
                }
                {
                  userAddresses.length <= 0 &&
                  <li>
                    <div className="text-muted">No existing address found</div>
                  </li>
                }
              </ul>
            }
          </div>
        }
        {
          !loadingUserAddresses && userAddresses.length > 0 && includeExistingAddresses && showPostcodeLookup &&
          <div className=""
            style={{
              width: '100%',
              padding: '0 0 20px',
              textAlign: 'center',
            }}>or</div>
        }
        {
          showPostcodeLookup &&
          <div className="form-group-wrapper">
            <div className="form-group floating-input">
              <div style={{
                position: 'absolute',
                top: '13px',
                right: '15px',
                width: 'auto',
              }} />
              <FloatingInput
                label="Delivery Postcode"
                type="text"
                name="postcode"
                error={postcode.error}
                value={postcode.value}
                inputChanged={this.onPostcodeChange} />
            </div>
            <div className="button-wrapper">
              <button type="button"
                className={`btn btn-primary btn-block ${this.props.inverseButton ? 'inverse-button' : ''}`}
                onClick={this.checkPostcodeIsSupported}>
                {this.props.buttonTitle}
              </button>
            </div>
          </div>
        }
        <div className={postcodeLookupResults.searched && !manualAddressEntry ? 'show' : 'hide'}>
          <ItemSelect 
            title={postcodeLookupResults.title} 
            items={postcodeLookupResults.addresses} 
            showAddresses={postcodeLookupResults.showAddresses}
            addressSelected={this.onAddressSelected} />
        </div>
        <div className={`alert alert-danger text-center ${selectedLookupAddressExists.length <= 0 ? 'hide' : ''}`}>
          {selectedLookupAddressExists}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAddresses: state.userAddresses,
    loadingUserAddresses: state.loadingUserAddresses,
    user: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserAddresses: () => dispatch(fetchAddresses()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(enhanceWithClickOutside(PostcodeLookup));
