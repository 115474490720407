import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div>
      <h1>Terms &amp; Conditions</h1>
    </div>
  );
};
export default TermsAndConditions;