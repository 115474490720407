import * as actionType from '../actions/actionTypes';

const mobileMenuReducer = (state = false, action) => {
  switch(action.type) {
  case actionType.MOBILE_MENU_TOGGLE_SUCCESS:
    return !state;
  default:
    return state;
  }
};

export default mobileMenuReducer;