import * as apiResources from './apiResources';

const { apiBaseUrl, apiKey, companyId } = apiResources.apiResource();

const apiConfig = {
  apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'ews-companyId': companyId,
    'ews-apiKey': apiKey,
    'X-Requested-With': 'XMLHttpRequest',
    'Expires': '-1',
    'If-Modified-Since': 'Thu, 13 Feb 1985 13:40:01 GMT',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
  },
};

export default apiConfig;
