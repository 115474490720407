export const EWS_ORDER_ID = 'ews-orderId';
export const ORIGINAL_ORDER_ID_HEADER = 'original-order-id';

class SessionUtils {
  RemoveOriginalOrderId() {
    sessionStorage.removeItem(ORIGINAL_ORDER_ID_HEADER);
  }

  SetSessionHeaders(order) {
    sessionStorage.setItem(EWS_ORDER_ID, order.orderId);

    if (order.editingExistingOrder) {
      sessionStorage.setItem(ORIGINAL_ORDER_ID_HEADER, order.originalOrderId);
    } else {
      this.RemoveOriginalOrderId();
    }
  }
}

export default new SessionUtils();