import React from 'react';
import ReactDOM from 'react-dom';
import storeSynchronize from 'redux-localstore';
import { BrowserRouter } from 'react-router-dom';
import { unregisterServiceWorker } from './registerServiceWorker';
import toastr from 'toastr';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';
import 'classlist.js'; 
import 'core-js/es6/reflect';
import 'core-js/es7/reflect';
import 'web-animations-js';

import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { getProducts } from './actions/productsActions';
import { getProductCategories } from './actions/categoriesAction';
import { getCartForFirstDeliveryDate } from './actions/shoppingCartAction';
import { getUserData } from './actions/userActions';

import 'toastr/build/toastr.css';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/scss/styles.css';
import App from './App';
import { getDeliveryDates } from './actions/deliveryDatesActions';

toastr.options.preventDuplicates = true;

const store = configureStore();
storeSynchronize(store, { storage: 'sessionStorage' });

// If token exists set user as authenticated.
let token = sessionStorage.getItem('token') || '';

// If user is logged in get current user details and shopping cart.
if (token !== 'undefined' && token !== undefined 
    && token !== 'null' && token !== null 
    && token.length > 0) {
  store.dispatch(getUserData()).then(() => {
    store.dispatch(getCartForFirstDeliveryDate());
    store.dispatch(getDeliveryDates());
    store.dispatch(getProducts());
    store.dispatch(getProductCategories());
  });
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>, 
  document.getElementById('root'));

$(window).resize(function() {
  // Close mobile menu if it is open on desktop
  if ($(window).width() > 992) {
    // hide mobile menu.
    if($('.nav-mobile-view').hasClass('show')){
      $('.nav-mobile-view').removeClass('show');
    }
    // Hide overlay-display.
    if($('.menu-overlay').hasClass('show')){
      $('.menu-overlay').removeClass('show');
    }
  }
  if ($(window).width() < 992) {
    // Change mobile burger menu icon to default.
    if($('.mobile-nav').hasClass('change')){
      $('.nav-mobile-view').addClass('show');
      $('.menu-overlay').addClass('show');
    }
  }

  // Close mobile menu if it is open on desktop
  if ($(window).width() > 992) {
    // hide mobile menu.
    if($('.products-filter').hasClass('show')){
      $('.products-filter').removeClass('show');
    }
    // Hide overlay-display.
    if($('.filter-overlay').hasClass('show')){
      $('.filter-overlay').removeClass('show');
    }
  }
  if ($(window).width() < 992) {
    // Change mobile burger menu icon to default.
    if($('.mobile-filter').hasClass('change')){
      $('.products-filter').addClass('show');
      $('.filter-overlay').addClass('show');
    }
  }
});

window.addEventListener('message', function(e) {
  if (e.origin === 'https://easywebstore-api.azurewebsites.net'
    || e.origin === 'https://easywebstore-test-api.azurewebsites.net'
    || (window.location.hostname === 'localhost')) {
    if (e.data.name === 'reloadShoppingCart') {
      window.location = `${window.location.origin}/confirmation/${e.data.id}`;
    }
  }
});

unregisterServiceWorker();
