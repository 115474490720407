import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as deliveriesActions from '../../../actions/deliveriesActions';
import DeliveryItems from './DeliveryItems';

class DeliveriesItem extends Component {
  constructor() {
    super();

    this.onItemSelected = this.onItemSelected.bind(this);
    this.onCancelDelivery = this.onCancelDelivery.bind(this);
    this.onEditDelivery = this.onEditDelivery.bind(this);
    this.onCopyDelivery = this.onCopyDelivery.bind(this);
  }

  onItemSelected() {
    const { onDeliverySelected, index } = this.props;
    onDeliverySelected(index);
  }

  onCancelDelivery() {
    const { cancelDelivery, delivery, index } = this.props;
    cancelDelivery(delivery, index);
  }

  onEditDelivery() {
    const { editDelivery, delivery, index } = this.props;
    editDelivery(delivery, index);
  }

  onCopyDelivery() {
    const { copyDelivery, delivery, index } = this.props;
    copyDelivery(delivery, index);
  }

  dropdownItem() {
    const { isSelected, delivery } = this.props;
    
    if (delivery.itemsCount === 0) {
      return null;
    }

    return isSelected ? <div className="chevron-arrow-down-icon" /> : <div className="chevron-arrow-right-icon" />;
  }

  render() {
    const { delivery, isSelected } = this.props;    
    return (
      <div className={`delivery-item ${isSelected ? 'active' : ''} ${delivery.orderType ? delivery.orderType : ''}`}>
        <div className="row"
          onClick={delivery.itemsCount === 0 ? null : this.onItemSelected}>
          <div className="column">
            {delivery.status}                      
          </div>
          <div className="column">{delivery.deliveryPostcode ? delivery.deliveryPostcode : '-'}</div>
          <div className="column">
            <div>{delivery.orderID !== 0 ? `#${delivery.orderID}` : 'TBC'}</div>
            <div>{`${delivery.itemsCount} ${delivery.itemsCount === 1 ? 'Item' : 'Items'}`}</div>
            <div>{`Value: £${delivery.orderTotal}`}</div>
          </div>
          <div className="column">
            {moment(delivery.deliveryDate, 'DD/MM/YYYY').format('DD/MM/YYYY')}
          </div>
          <div className="column">
            {this.dropdownItem()}
          </div>
        </div>
        {isSelected && delivery && <DeliveryItems order={delivery}/>}
        <div className="button-wrapper">
          {
            delivery.isEditable &&
            <button className='btn edit-btn'
              onClick={this.onEditDelivery}>
              Edit Order
            </button>
          }
          <button className='btn copy-btn'
            onClick={this.onCopyDelivery}>
              Copy Order
          </button>
          {
            delivery.isCancellable &&
            <button className="btn cancel-btn"
              onClick={this.onCancelDelivery}>
              Cancel Order
            </button>
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveries: () => dispatch(deliveriesActions.getDeliveries()),
  };
};

export default connect(null, mapDispatchToProps)(DeliveriesItem);
