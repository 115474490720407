import toastr from 'toastr';
import { getDeliveriesRequest, cancelDeliveriesRequest, editDeliveryRequest } from '../api/deliveriesApi';
import { updateShoppingCart } from '../actions/shoppingCartAction';

export const GET_DELIVERIES = 'GET_DELIVERIES';
export const REMOVE_DELIVERIES = 'REMOVE_DELIVERIES';
export const LOADING_DELIVERIES = 'LOADING_DELIVERIES';

const getDeliveriesSuccess = (deliveries) => {
  return {
    type: GET_DELIVERIES,
    deliveries,
  };
};

const loadingDeliveriesSuccess = (status) => {
  return {
    type: LOADING_DELIVERIES,
    status,
  };
};

export const removeDeliveriesSuccess = () => {
  return {
    type: REMOVE_DELIVERIES,
  };
};

export const getDeliveries = () => {
  return async dispatch => {
    dispatch(loadingDeliveriesSuccess(true));
    try {
      const response = await getDeliveriesRequest();
      if (response.status === 200) {
        dispatch(getDeliveriesSuccess(response.data));
      }
      dispatch(loadingDeliveriesSuccess(false));
    }
    catch (error) {
      console.log('Error fetching deliveries:', error);
      dispatch(loadingDeliveriesSuccess(false));
    }
  };
};

export const cancelDelivery = (delivery) => {
  return async dispatch => {
    dispatch(loadingDeliveriesSuccess(true));
    try {
      const response = await cancelDeliveriesRequest(delivery);

      if (response.status === 200 && response.data && response.data.Success) {
        toastr.success('Order has been canceled.');
        dispatch(getDeliveries());
      }
      else {
        toastr.error(response.data?.ErrorMessage);
        dispatch(loadingDeliveriesSuccess(false));
      }
    }
    catch (error) {
      console.log('Error canceling order:', error);
      dispatch(loadingDeliveriesSuccess(false));
    }
  };
};

export const editDelivery = (delivery) => {
  return async dispatch => {

    try {
      const response = await editDeliveryRequest(delivery);

      if (response.status === 200) {
        const shoppingCart = response.data.ShoppingCart;

        // We have a valid order
        dispatch(updateShoppingCart(shoppingCart));
        toastr.success('Order has been placed into edit mode.');
      }
      else {
        toastr.error(response.data.ErrorMessage);
      }
    }
    catch (error) {
      console.log('Error editing order:', error);
    }
  };
};