import React from 'react';

const ContactUs = () => {
  return (
    <div className="contact-us"
      id="contact-us">
      <div className="container text">
        <div className="italic title gold">Get in Touch</div>
      </div>
      <div className="container text">
        <div className="title"
          id="contactus">Contact Us</div>
      </div>
      <div className="container text">
        <p>International Seafoods t/a Big Blue Seafood</p>
      </div>
      <div className="container text">
        <p><b>A</b>: Lakeside, Europarc, Healing, Grimsby DN37 9TW</p>
      </div>
      <div className="container text">
        <p onClick={() =>
          window.open('mailto:sales@bigblueseafood.co.uk', '_blank')
        }
        className="sales-email"><b>E</b>: sales@bigblueseafood.co.uk</p>
      </div>
    </div>
  );
};

export default ContactUs;