import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddressItem from './AddressItem';
import AddAddress from './AddAddress';
import { fetchAddresses } from '../../../../actions/userAddressActions';
import './AddressDetails.css';

class AddressDetails extends Component { 
    
  componentDidMount() {
    this.props.getUserAddresses();
  }
    
  render() {        
    const { userAddresses } = this.props;
    let defaultAddress = userAddresses[0];
    if (userAddresses.length == 1) {
      defaultAddress.defaultAddress = true;
    }
    return (
      <div className="">
        <h3>Delivery Addresses</h3>
        {userAddresses.length <= 0 &&
                    <div className="text-center text-muted"><h5>No address found. Add a new address below</h5></div>
        }
        {userAddresses.length == 1 &&
                    <div className="address-list">
                      <AddressItem key={0} 
                        address={defaultAddress} />
                    </div>
        }
        {userAddresses.length > 1 &&
                    <div className="address-list">
                      {userAddresses.map((address, index) => 
                        <AddressItem key={index} 
                          address={address} />,
                      )}
                    </div>
        }
                
        <AddAddress />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAddresses: state.userAddresses,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserAddresses: () => dispatch(fetchAddresses()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressDetails);