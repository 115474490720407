import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DeliveriesItem from './DeliveriesItem';
import * as deliveriesActions from '../../../actions/deliveriesActions';
import { copyOrder } from '../../../actions/shoppingCartAction';
import './AccountDeliveriesPage.css';
import AppDialog from '../../common/dialog/AppDialog';
import * as commonActions from '../../../actions/commonActions';
import scrollToComponent from 'react-scroll-to-component';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';

class AccountDeliveriesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleDeliveryId: '',
      visibleDeliveries: 4,
      filteredDeliveries: [],
      activeDeliveryFilter: '',
      hasMoreDeliveries: true,
      noDeliveries: 'You have no orders',
      selectedOrder: '',
      selectedOrderIndex: '',
      dialog: {
        title: '',
        message: '',
        type: '',
        item: {},
        isOpen: false,
        isDefaultDialog: true,
        isCopyOrderDialog: false,
        dialogDeliveryDate: '',
        children: '',
      },
    };

    this.onFilterDeliveries = this.onFilterDeliveries.bind(this);
    this.doFilterDeliveries = this.doFilterDeliveries.bind(this);
    this.onDeliverySelected = this.onDeliverySelected.bind(this);
    this.onViewMoreDeliveries = this.onViewMoreDeliveries.bind(this);
    this.onCopyOrderDateChanged = this.onCopyOrderDateChanged.bind(this);
    this.onCancelDelivery = this.onCancelDelivery.bind(this);
    this.resetDialog = this.resetDialog.bind(this);
    this.onDialogConfirm = this.onDialogConfirm.bind(this);
    this.deliveryItems = React.createRef();
    this.onEditDelivery = this.onEditDelivery.bind(this);
    this.onCopyDelivery = this.onCopyDelivery.bind(this);
  }

  componentDidMount() {
    this.props.getDeliveries();       
  }

  UNSAFE_componentWillReceiveProps() {
    this.onFilterDeliveries(this.state.activeDeliveryFilter);
  }

  onFilterDeliveries(e) {
    this.setState(
      { 
        activeDeliveryFilter: e,
        visibleDeliveries: 8,
        selectedOrder: '',
        selectedOrderIndex: '',
      }, 
      () => this.doFilterDeliveries(),
    );
  }

  doFilterDeliveries() {
    let { activeDeliveryFilter, noDeliveries, hasMoreDeliveries, visibleDeliveryId } = this.state;
    const { deliveries, filters } = this.props;
    let filteredDeliveries = [];
        
    //Filter deliveries by status.
    filteredDeliveries = deliveries.filter((delivery) => {
      if (filters.indexOf('upcoming') > -1) {
        return moment(delivery.deliveryDate, 'DD/MM/YYYY').isAfter(moment());
      } else {
        return (delivery.status.toLowerCase() !== 'editable' && moment(delivery.deliveryDate, 'DD/MM/YYYY').isSameOrBefore(moment()));
      }
    });

    const checkForMoreDeliveries = filteredDeliveries.length;

    // Filter deliveries by visible deliveries count.
    filteredDeliveries = filteredDeliveries.filter((delivery, index) => {
      if (visibleDeliveryId && visibleDeliveryId === delivery.orderID) {
        this.setState({
          selectedOrder: delivery,
          selectedOrderIndex: index,
        });
      }
      return index < this.state.visibleDeliveries;
    });
    if (filteredDeliveries.length <= 0) {
      if (!activeDeliveryFilter) {
        noDeliveries = 'You have no orders';
      } else if (activeDeliveryFilter === 'delivered') {
        noDeliveries = 'You have no deliveries with the status: Completed';
      } else {
        noDeliveries = `You have no deliveries with the status: ${activeDeliveryFilter.charAt(0).toUpperCase() + activeDeliveryFilter.substr(1)}`;
      }
    }

    hasMoreDeliveries = checkForMoreDeliveries > filteredDeliveries.length;

    this.setState({
      ...this.state,
      filteredDeliveries,
      noDeliveries,
      hasMoreDeliveries,
    });
  }

  onViewMoreDeliveries() {
    this.setState({
      visibleDeliveries: (this.state.visibleDeliveries + 4),
      selectedOrder: '',
      selectedOrderIndex: '',
    }, 
    () => this.doFilterDeliveries());
  }

  onDeliverySelected(itemIndex) {
    const { selectedOrder, selectedOrderIndex } = this.state;
    let sameOrderSelected = selectedOrder == this.props.deliveries[itemIndex] || selectedOrderIndex === Number(itemIndex);

    this.setState({
      selectedOrder: sameOrderSelected ? '' : this.props.deliveries[itemIndex],
      selectedOrderIndex: sameOrderSelected ? '' : Number(itemIndex),
    }, () => {
      setTimeout(() => {
        scrollToComponent(this.deliveryItems.current, {
          offset: -40,
        });
      }, 100);
    });
  }

  onCancelDelivery(delivery, itemIndex) {
    this.onDeliverySelected(itemIndex);
    
    let dialog = this.state.dialog;
    dialog.title = 'Cancel Order';
    dialog.message = 'Are you sure you want to cancel this order?';
    dialog.type = 'cancelDelivery';
    dialog.isOpen = true;
    dialog.isDefaultDialog = true;
    dialog.isCopyOrderDialog = false;
    dialog.item = delivery;
    this.setState({ dialog: dialog });
  }

  onEditDelivery(delivery, itemIndex) {
    this.onDeliverySelected(itemIndex);

    let dialog = this.state.dialog;
    dialog.title = 'Edit Order';
    dialog.message = 'Are you sure you want to edit this order?';
    dialog.type = 'editDelivery';
    dialog.isOpen = true;
    dialog.isDefaultDialog = true;
    dialog.isCopyOrderDialog = false;
    dialog.item = delivery;
    this.setState({ dialog: dialog });
  }

  onCopyDelivery(delivery) {
    this.resetDialog();
    const minDeliveryDate = this.minDeliveryDate();
    const deliveryDate = moment(minDeliveryDate).format('YYYY-MM-DD');
    let dialog = this.state.dialog;
    dialog.title = 'Copy Order - #' + delivery.orderID;
    dialog.type = 'copyDelivery';
    dialog.isOpen = true;
    dialog.isDefaultDialog = false;
    dialog.isCopyOrderDialog = true;
    dialog.item = delivery;
    dialog.dialogDeliveryDate = deliveryDate;
    this.setState({ dialog: dialog });
  }

  formatCalendarNameOfDay(nameOfDay) {
    switch (nameOfDay) {
    case 'Monday': {
      return 'M';
    }
    case 'Tuesday': {
      return 'T';
    }
    case 'Wednesday': {
      return 'W';
    }
    case 'Thursday': {
      return 'T';
    }
    case 'Friday': {
      return 'F';
    }
    case 'Saturday': {
      return 'S';
    }
    case 'Sunday': {
      return 'S';
    }
    default: {
      return '';
    }
    }
  }

  getDatePickerCustomHeader({
    date,
    changeMonth,
  }, index) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return (
      <div key={index}
        className="custom-date-picker-header">           
        <select
          value={months[moment(date).month()]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map(option => (
            <option key={option}
              value={option}>
              {option} {moment(date).year()}
            </option>
          ))}
        </select>
      </div>
    );
  }


  resetDialog() {
    this.setState({
      dialog: {
        title: '',
        message: '',
        type: '',
        isOpen: false,
        isDefaultDialog: true,
        isCopyOrderDialog: false,
        dialogDeliveryDate: null,
        children: '',
      },
    });
  }

  onDialogConfirm(e) {
    const { dialog } = this.state;
    const { cancelDelivery, history, dispatchEditDelivery, dispatchCopyOrder }= this.props;

    let params = {
      orderId: dialog.item.orderID,
    };

    if (e) {
      switch (dialog.type) {           
      case 'cancelDelivery': {   
        cancelDelivery(params);
        this.setState({ selectedOrder: '' });
        break;
      }
      case 'editDelivery' : {
        dispatchEditDelivery(params)
          .then(() => {
            this.setState({ selectedOrder: '' });
            history.push(`${process.env.PUBLIC_URL}/`);
          });
        break;
      }
      case 'copyDelivery': {
        dispatchCopyOrder(dialog.item.orderID, dialog.dialogDeliveryDate)
          .then(() => {
            this.setState({ selectedOrder: '' });
            history.push(`${process.env.PUBLIC_URL}/`);
          });
        break;
      }
      default:
        return;
      }
    }
    this.resetDialog();
  }

  onCopyOrderDateChanged(date){
    const deliveryDate = moment(date).format('YYYY-MM-DD');
    let dialog = this.state.dialog;
    dialog.dialogDeliveryDate = deliveryDate;
    this.setState({ dialog: dialog });
  }

  maxDeliveryDate() {
    const { deliveryDates } = this.props;

    if (deliveryDates.loading || deliveryDates.data.length <= 0) {
      return null;
    }

    return moment(deliveryDates.data[deliveryDates.data.length - 1].deliveryDate).toDate();
  }

  minDeliveryDate() {
    const { deliveryDates } = this.props;

    if (deliveryDates.loading || deliveryDates.data.length <= 0) {
      return null;
    }

    return moment(deliveryDates.data[0].deliveryDate).toDate();
  }

  render() {
    const { filteredDeliveries, selectedOrderIndex, noDeliveries, hasMoreDeliveries, dialog } = this.state;
    const { loadingDeliveries, filters } = this.props;

    const minDeliveryDate = this.minDeliveryDate();
    const maxDeliveryDate = this.maxDeliveryDate();

    return (
      <div className="account-deliveries-page-wrapper">
        {
          filters.indexOf('upcoming') <= -1 &&
          <h3>Order History</h3>
        }
        {
          filters.indexOf('upcoming') > -1 &&
          <h3>Upcoming Orders and Baskets</h3>
        }
        {
          filteredDeliveries.length > 0 && 
          <div className="account-deliveries-page">
            <div className='delivery-item title'>
              <div className="row">
                <div className="column">Status</div>
                <div className="column">Postcode</div>
                <div className="column">Order</div>
                <div className="column">Date</div>
                <div className="column"></div>
              </div>
            </div>
            {
              filteredDeliveries.map((delivery, index) => 
                <DeliveriesItem key={index} 
                  index={index} 
                  isSelected={index === selectedOrderIndex}
                  delivery={delivery}
                  cancelDelivery={this.onCancelDelivery}
                  onDeliverySelected={this.onDeliverySelected} 
                  editDelivery={this.onEditDelivery}
                  copyDelivery={this.onCopyDelivery} />,
              )
            }
          </div>
        }

        {
          !loadingDeliveries && filteredDeliveries.length <= 0 && 
          <h4 className="text-center">{noDeliveries}</h4>
        }
        {
          hasMoreDeliveries && 
          <div className="load-more-deliveries">
            <button className="btn inverse"
              onClick={this.onViewMoreDeliveries}>Load more deliveries</button>
          </div>
        }
        {
          dialog.isOpen && dialog.isDefaultDialog && !dialog.isCopyOrderDialog &&
          <AppDialog title={dialog.title}
            onConfirm={this.onDialogConfirm}>
            <div dangerouslySetInnerHTML={{ __html: dialog.message }} />
          </AppDialog>
        }
        {
          dialog.isOpen 
          && !dialog.isDefaultDialog 
          && dialog.isCopyOrderDialog 
          && dialog.dialogDeliveryDate  
          && minDeliveryDate 
          && maxDeliveryDate &&
          <AppDialog
            title={dialog.title}
            onConfirm={this.onDialogConfirm}>
            <div className="copy-order-date-picker">
              <p>Select delivery date</p>
              <DatePicker
                formatWeekDay={nameOfDay => this.formatCalendarNameOfDay(nameOfDay)}
                renderCustomHeader={(data) => this.getDatePickerCustomHeader(data)}
                selected={moment(dialog.dialogDeliveryDate)}
                onChange={this.onCopyOrderDateChanged}
                disabled={this.props.loading}
                allowSameDay={true}
                maxDate={moment(maxDeliveryDate)}
                minDate={moment(minDeliveryDate)}
                dateFormat="DD/MM/YYYY"
                locale="en-gb"
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                  },
                }}
                withPortal={true}
                filterDate={this.isBlocked}
                inline={true}
                shouldCloseOnSelect={false}
              />
            </div>
          </AppDialog>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    user: state.user.data,
    deliveries: state.deliveries,
    loadingDeliveries: state.loadingDeliveries,
    deliveryDates: state.deliveryDates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: modal => dispatch(commonActions.onModalToggle(modal)),
    getDeliveries: () => dispatch(deliveriesActions.getDeliveries()),
    cancelDelivery: (delivery) => dispatch(deliveriesActions.cancelDelivery(delivery)),
    dispatchEditDelivery: (delivery) => dispatch(deliveriesActions.editDelivery(delivery)),
    dispatchCopyOrder: (orderId,deliveryDate) => dispatch(copyOrder(orderId,deliveryDate)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountDeliveriesPage));
