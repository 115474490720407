import React from 'react';
import { connect } from 'react-redux';
import HomepageLoggedIn from './HomepageLoggedIn';
import HomepageLoggedOut from './HomepageLoggedOut';
import './Homepage.css';
import './panels/Panels.css';
import { updateActiveNav } from '../../actions/navigationAction';

class Homepage extends React.Component {
  constructor() {
    super();

    this.state = {
      showSelectDate: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.updateActiveNav(this.props.location);
  }

  render() {

    return (

      <div className="homepage">
        {this.props.user.isAuthenticated && <HomepageLoggedIn/> }
        {!this.props.user.isAuthenticated && <HomepageLoggedOut/> }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showSelectDate: state.showSelectDate,
    user: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveNav: (nav) => dispatch(updateActiveNav(nav)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
