import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ProductItem from './productItem/ProductItem';
import Spinner from '../common/spinner/Spinner';
import * as commonActions from '../../actions/commonActions';
import { getProductCategories } from '../../actions/categoriesAction';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FiFilter } from 'react-icons/fi';
import { onMobileFilterToggle } from '../../actions/mobileFilterAction';
import $ from 'jquery';
import priceUtils from '../../shared/priceUtils';

class ProductsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allFilterSelected: true,
      activeCategory: null,
      categories: [],
      filterOpen: this.props.filterOpen,
    };
  }

  componentDidMount() {
    this.props.getProductCategories();
  }

  onCategorySelected(item, e) {

    var dropdowns = document.getElementsByClassName('dropdown-btn');
        
    var i;

    for (i = 0; i < dropdowns.length; i++) {
      if (dropdowns[i] != e.target) {
        var dropdownContent = dropdowns[i].nextElementSibling;
        if(dropdownContent){
          dropdownContent.style.display = 'none';
        }
      }
    }

    e.target.classList.toggle('active');

    var dropdownMenuContent = e.target.nextElementSibling;

    if(dropdownMenuContent){
      if (dropdownMenuContent.style.display === 'block') {
        dropdownMenuContent.style.display = 'none';
      } else {
        dropdownMenuContent.style.display = 'block';
      }    
    }
        
    if (e.target.classList.contains('active')) {
      this.setState({ activeCategory: item });
    }
    else {
      this.setState({ activeCategory: null });
    }
  }

  getItemQuantityInCart(product) {
    const { shoppingCart } = this.props;
    if (shoppingCart.items == null) return 0;
    const existingProduct = shoppingCart.items.find(item => item.productOptionId === product.productOptionId) || null;
    return existingProduct ? existingProduct.quantity : 0;
  }

  getBasketTotal() {
    const { shoppingCart } = this.props;
    return shoppingCart.items.reduce((a, b) => {
      return a + (b.price * b.quantity);
    }, 0);
  }

  getFilteredProducts() {
    const { activeCategory } = this.state;

    var filteredProducts = [];
    var category = activeCategory;
    this.props.products && this.props.products.filter(function(product){
      var i = filteredProducts.findIndex(x => x.productId == product.productId);
      if(i <= -1 && (category == null ? true : product.primaryCategoryId === category.categoryId)) {
        filteredProducts.push(product);
      }
      return null;
    });

    filteredProducts.sort((a, b) => (a.displayPriority < b.displayPriority) ? 1 : -1);
        
    return filteredProducts || [];
  }

  onCloseFilter() {
    this.setState({
      filterOpen: false,
    });
    this.props.toggleFilter();
  }

  viewDetails(friendlyUrl) {
    this.props.history.push(`${process.env.PUBLIC_URL}/product/${friendlyUrl}`);
    $('body,html').animate({
      scrollTop: 0,
    }, 500);
  }

  render() {
    const { filterOpen, toggleFilter, categories, isCatalogue, loadingProducts, loadingCategories } = this.props;
    const { activeCategory } = this.state;
    let filteredProducts = this.getFilteredProducts();

    return (
      <div className="subscription-recipes">
        <div className= {`recipes-wrapper ${isCatalogue ? 'catalogue': ''}`}>
          {loadingProducts && loadingCategories &&
            <div className="spinner-wrapper">
              <Spinner show
                fontSize={25} />
            </div>
          }
          <div className="container">
            <div className={`mobile-filter ${filterOpen ? 'change': ''}`}
              onClick={toggleFilter}>
              <FiFilter/>
            </div>
            <div className={`filter-overlay ${filterOpen ? 'show': ''}`}
              onClick={toggleFilter} />

            <div className={`left products-filter ${filterOpen ? 'show': ''}`}>
              <h1>Product Catalogue</h1>
              <div className="product-categories-filter">
                {activeCategory && <div onClick={() => this.setState({ activeCategory: null })}
                  className="clear-filter-button">Clear filter</div>}
                {
                  categories.data && categories.data.map((category, index) => [
                    <button 
                      key={index}
                      className={(category === activeCategory) ? 'active dropdown-btn' : 'dropdown-btn'}
                      onClick={(e) => this.onCategorySelected(category, e)}
                    >
                      {category.description}
                      <MdKeyboardArrowRight/>
                    </button>,
                    <div className="dropdown-container"
                      key={index + 1000}>
                      {category.subCategories && category.subCategories.map((subcategory, index) =>
                        <a key={index}>
                          {subcategory.description}
                          <MdKeyboardArrowRight/>
                        </a>,
                      )}
                    </div>],
                  )
                }
              </div>
            </div>
            <div className="right">
              {!loadingProducts && filteredProducts.length > 0 ?
                <div className="recipes">
                  {filteredProducts.map((product) => 
                    <ProductItem
                      key={product.productId}
                      editMode={true}
                      product={product}
                      onViewDetails={(friendlyUrl) => this.viewDetails(friendlyUrl)}
                      doLogin={() => this.props.toggleModal({ open: true, type: 'login' })}
                    />,
                  )}
                </div> :
                <div className="no-recipes">
                  <h3>No products available.</h3>
                </div>
              }
            </div>
          </div>
        </div>
        {this.props.shoppingCart.itemsCount > 0 &&
          <div className="mobile-view-basket-button">
            <button 
              className="btn"
              onClick={() => this.props.onShowMiniBasket()}
            >
                  View Basket
              {
                this.props.shoppingCart.items.length > 0 && <> - {priceUtils.FormatCurrencyPence(this.props.shoppingCart.orderTotal)}</> 
              }
            </button>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingProducts: state.products.loading,
    products: state.products.data,
    categories: state.categories,
    user: state.user.data,
    shoppingCart: state.foodBoxesShoppingCart.data,
    loadingCategories: state.categories.loading,
    filterOpen: state.filterOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (modal) => dispatch(commonActions.onModalToggle(modal)),
    getProductCategories: () => dispatch(getProductCategories()),
    toggleFilter: value => dispatch(onMobileFilterToggle(value)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsPage));