import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import AppDialog from '../../common/dialog/AppDialog';
import { connect } from 'react-redux';
import './ProductQuantity.css';
import { getShoppingCart, updateQuantityInCart } from '../../../actions/shoppingCartAction';
import $ from 'jquery';

const ProductQuantity = (props) => {
  let [quantity, updateQuantity] = useState(props.product && props.product.quantity ? props.product.quantity : 1);
  const [itemIsInCart, updateItemIsInCart] = useState(false);
  const [dialog, updateDialog] = useState({
    title: '',
    message: '',
    type: '',
    isOpen: false,
  });
  let [product, setProduct] = useState(props.product);
  let [otherProductSelected, SetOtherProductSelected] = useState(false);
  let [optionDescription, setOptionDescription] = useState();

  useEffect(() => {
    if(!otherProductSelected){
      setProduct(props.product);
    }
    checkIfItemInCart();
  }, [props,product]);

  const checkIfItemInCart = () => {
    if(product && props.shoppingCart){
      let foundIt;
      if (product.productOption) {
        foundIt = props.shoppingCart.items.find(x=>x.productOptionId === product.productOption.productOptionId);
      }
      else {
        foundIt = props.shoppingCart.items.find(x=>x.productId === product.productId);
      }
      if (foundIt) {
        updateQuantity(foundIt.quantity);
        updateItemIsInCart(true);
      }
      else {
        updateQuantity(1);
        updateItemIsInCart(false);
      }
    }
  };
  
  const onDecreaseQuantity = () => {
    console.log(props.product);
    console.log(product);
    // Ignore request if item is not already in cart.
    if (!itemIsInCart) {
      if (quantity > 1) {
        updateQuantity(quantity - 1);
      }
      return;
    }
    // If quantity is already 1 confirm item will be removed.
    if (quantity === 1) {
      confirmRemoveFromCart();
      return;
    } 
    else {
      if (product.productOption) {
        props.updateQuantityInCart({
          productOptionId: product.productOption.productOptionId,
          quantity: quantity - 1,
        }).then((resp) => {
          if (!resp.payload.Success) toastr.error(resp.payload.ErrorMessage);
        });
      }
      else {
        props.updateQuantityInCart({
          productOptionId: product.productId,
          quantity: quantity - 1,
        }).then((resp) => {
          if (!resp.payload.Success) toastr.error(resp.payload.ErrorMessage);
        });
      }
      return;
    }
  };

  const onIncreaseQuantity = () => {

    // If item not already in cart add it
    if (!itemIsInCart) {
      updateQuantity(quantity + 1);
      return;
    }

    if (product.productOption) {
      props.updateQuantityInCart({
        productOptionId: product.productOption.productOptionId,
        quantity: quantity + 1,
      }).then((resp) => {
        if (!resp.payload.Success) toastr.error(resp.payload.ErrorMessage);
      });
    }
    else {
      props.updateQuantityInCart({
        productOptionId: product.productId,
        quantity: quantity + 1,
      }).then((resp) => {
        if (!resp.payload.Success) toastr.error(resp.payload.ErrorMessage);
      });
    }
  };

  const addToCart = (quantityToAdd) => {
    const token = sessionStorage.getItem('token') || '';
    if (!token) {
      props.doLogin();
      return;
    }
    if (product.productOption) {
      props.onAddToCart(product.productOption.productOptionId, quantityToAdd);
    }
    else {
      props.onAddToCart(product.productId, quantityToAdd);
    }
    props.fetchShoppingCart();
  };

  const confirmRemoveFromCart = () => {
    // Show modal to confirm remove from cart.
    updateDialog({
      title: 'Remove Product',
      message: `Are you sure you want to remove ${product.title} from your basket?`,
      type: 'removeItem',
      isOpen: true,
    });
  };

  const removeFromCart = (status) => {
    if (status) {
      if (product.productOption) {
        props.onRemoveFromCart(product.productOption.productOptionId);
      } else {
        props.onRemoveFromCart(product.productId);
      }
    }
    updateDialog({
      title: '',
      message: '',
      type: '',
      isOpen: false,
    });
  };

  const hasOptions = () => {
    var options = props.products.filter(prod => prod.productId === product.productId);
    return options.length > 1;
  };

  const onSelectOption = (e) => {
    var allOptions = props.products.filter(prod => prod.productId === product.productId);
    var selectedOption = allOptions.find(prod => prod.productOption.description === e.target.value);
    setProduct(selectedOption);
    setOptionDescription(selectedOption.productOption.description);
    SetOtherProductSelected(true);
    $('.' + selectedOption.productId + ' .recipe-price span').html('£'+selectedOption.price);
    $('.' + selectedOption.productId + ' .price p').html('£'+selectedOption.price);
  };

  return (
    <div className="product-quantity add-to-basket">
      {product && props.products.filter(prod => prod.productId === product.productId) &&
       hasOptions() &&
        <select onChange={onSelectOption}
          value={optionDescription}
          className="weight-select">
          {props.products.filter(prod => prod.productId === product.productId).map(option => (
            option.productOption &&
              <option key={option.productOption.productOptionId}
                value={option.productOption.description}>
                {option.productOption.description}
              </option>
          ))}
        </select>
      }
      {    
        <div className="add-to-basket-wrapper">
          <div className="basket-quantity">
            <button
              disabled={props.loading || quantity <= 0}
              type="button"
              className="quantity-decrement btn btn-default"
              onClick={() => onDecreaseQuantity()}
            >
            -
            </button>
            <input
              type="number"
              maxLength="3"
              className="quantity-count"
              value={quantity}
              readOnly
            />
            <button
              disabled={props.loading}
              type="button"
              className="quantity-increment btn btn-default"
              onClick={() => onIncreaseQuantity()}
            >
            +
            </button>
          </div>
        </div>
      }
      {
        <button
          type="button"
          disabled={props.loading || itemIsInCart}
          className={itemIsInCart ? 'hidden add-to-basket-button btn btn-default' : 'add-to-basket-button btn btn-default'}
          onClick={() => addToCart(quantity)}
        >
          {props.buttonText}
        </button>
      }
      {dialog.isOpen &&
        <AppDialog title={dialog.title}
          message={dialog.message}
          onConfirm={(status) => removeFromCart(status)} />
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    shoppingCart: state.foodBoxesShoppingCart.data,
    products: state.products.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchShoppingCart: () => dispatch(getShoppingCart()),
    updateQuantityInCart: (params) => dispatch(updateQuantityInCart(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductQuantity);


