import {
  GET_PRODUCT_CATEGORIES_REQUEST,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_CATEGORIES_ERROR,
} from '../actions/categoriesAction';

const initialState = {
  loading: false,
  error: '',
  data: [],
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_PRODUCT_CATEGORIES_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_PRODUCT_CATEGORIES_SUCCESS: {
    let categories = [];
    if (action.payload.length > 0) {
      categories = action.payload.map((category) => {
        return category;
      });
    }
    return {
      ...state,
      loading: false,
      data: categories,
    };
  }
  case GET_PRODUCT_CATEGORIES_ERROR: {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  }
  default:
    return state;
  }
};

export default categoriesReducer;