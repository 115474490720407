import React from 'react';
import { NavLink } from 'react-router-dom';
import FooterLoggedOut from '../../../bbsFooterLoggedOut/footerLoggedOut';

const JoinNow = () => {
  return (
    <div className="join-now"
      id="home">
      <div className="container">
        <div className="sinceYear">Since 2012</div>
      </div>
      <div className="container text">
        <h2>Delivering Quality Sustainable</h2>
      </div>
      <div className="container text">
        <h2>Seafood to Businesses</h2>
      </div>
      <div className="container">
        <NavLink
          to={`${process.env.PUBLIC_URL}/register`}
          className="btn"
        >
          Get an Account
        </NavLink>
      </div>
      <FooterLoggedOut />
    </div>
  );
};

export default JoinNow;
