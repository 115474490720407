export const OPEN_MINI_BASKET = 'OPEN_MINI_BASKET';
export const CLOSE_MINI_BASKET = 'CLOSE_MINI_BASKET';

export const openMiniBasket = (shoppingCart) => {
  return dispatch => 
    dispatch({
      type: OPEN_MINI_BASKET,
      payload: shoppingCart,
    });
};

export const closeMiniBasket = () => {
  return dispatch => 
    dispatch({
      type: CLOSE_MINI_BASKET,
    });
};