import React, { Component } from 'react';

class CollapsiblePanel extends Component {
  // constructor(props) {
  //     super(props);
  // }

  componentDidMount() {
    let togglePanels = document.getElementsByClassName('togglePanel');
    for (let i = 0; i < togglePanels.length; i++) {
      togglePanels[i].addEventListener('click', function() {
        this.classList.toggle('show');
        let content = this.nextElementSibling;
        if (content.style.maxHeight){
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = 600 + 'px';
          // content.style.maxHeight = "unset";
          // content.style.minHeight = "50px";
        }
      });
    }
  }

  render() {
    return (
      <div className="personal-details">
        <h3 className="toggle togglePanel">{this.props.title}</h3>
        <div className="content">{this.props.children}</div>
      </div>
    );
  }
}

export default CollapsiblePanel;