export const apiResource = () => {
  const localEnvironment = {
    apiBaseUrl: 'http://localhost:40452/BigBlueSeaApi.svc',
    apiKey: '18ec2bf3-1572-4bde-ba31-fe2ff6c42a57',
    companyId: '13312',
    postcodeLookupApiUrl: 'https://api.addressy.com/Capture/Interactive/Find/v1.00/json3.ws?',
    postcodeLookupApiKey: 'GG55-HH63-RF76-MY33',
    storageAccountName: 'bigblueseadevstore',
    sasToken: '?sv=2020-08-04&ss=b&srt=sco&sp=rwdlacitfx&se=2221-08-27T21:05:55Z&st=2021-08-27T13:05:55Z&spr=https&sig=T89QqPiTmNfUv2s9P2fnin3bW4EYaoUUUegg7h8nuCA%3D',
  };

  const devEnvironment = {
    apiBaseUrl: 'https://easywebstore-test-api.azurewebsites.net/BigBlueSeaApi.svc',
    apiKey: '18ec2bf3-1572-4bde-ba31-fe2ff6c42a57',
    companyId: '13312',
    postcodeLookupApiUrl: 'https://api.addressy.com/Capture/Interactive/Find/v1.00/json3.ws?',
    postcodeLookupApiKey: 'GG55-HH63-RF76-MY33',
    storageAccountName: 'bigblueseadevstore',
    sasToken: '?sv=2020-08-04&ss=b&srt=sco&sp=rwdlacitfx&se=2221-08-27T21:05:55Z&st=2021-08-27T13:05:55Z&spr=https&sig=T89QqPiTmNfUv2s9P2fnin3bW4EYaoUUUegg7h8nuCA%3D',
  };

  const qaEnvironment = {
    apiBaseUrl: 'https://easywebstore-test-api.azurewebsites.net/BigBlueSeaApi.svc',
    apiKey: 'a07253cf-f5f4-4c33-a0b4-c994f6d5575b',
    companyId: '13313',
    postcodeLookupApiUrl: 'https://api.addressy.com/Capture/Interactive/Find/v1.00/json3.ws?',
    postcodeLookupApiKey: 'GG55-HH63-RF76-MY33',
    storageAccountName: 'bigblueseaqastore',
    sasToken: '?sv=2020-08-04&ss=b&srt=sco&sp=rwdlacitfx&se=2221-08-27T21:54:01Z&st=2021-08-27T13:54:01Z&spr=https&sig=CEM4J%2F0PitX6b3UMKmAOkJ6LGIsLD%2FPY4%2Ba2JtiwSYA%3D',
  };

  const productionEnvironment = {
    apiBaseUrl: 'https://easywebstore-api.azurewebsites.net/BigBlueSeaApi.svc',
    apiKey: '5968815a-b4ff-4e42-9eb9-09722889fcaa',
    companyId: '13311',
    postcodeLookupApiUrl: 'https://api.addressy.com/Capture/Interactive/Find/v1.00/json3.ws?',
    postcodeLookupApiKey: 'GG55-HH63-RF76-MY33',
    storageAccountName: 'bigblueseaprodstore',
    sasToken: '?sv=2020-08-04&ss=b&srt=sco&sp=rwdlacx&se=2221-08-27T22:11:14Z&st=2021-08-27T14:11:14Z&spr=https&sig=kmSqje8%2F8Rdtu6PkBFRWATCo1fKrk3f3Ev8XCvzderY%3D',
  };

  switch(window.location.hostname) { 
  case 'localhost':
  case '127.0.0.1':
    return localEnvironment;

  case 'bigbluesea-dev.orderly.io':
    return devEnvironment;

  case 'bigbluesea-qa.orderly.io':
    return qaEnvironment;

  case 'bigblueseafood.co.uk':
  case 'bigbluesea-prod.orderly.io':
  case 'www.bigblueseafood.co.uk':
    return productionEnvironment;

  default:
    return devEnvironment;
  }
};
