import React from 'react';
import { connect } from 'react-redux';
import noImage from '../../../assets/images/placeholder-rectangle.png';
import Spinner from '../spinner/Spinner';
import { addToCart, removeFromCart } from '../../../actions/shoppingCartAction';
import ProductQuantity from '../../bbsProducts/productQuantity/ProductQuantity';
import { withRouter } from 'react-router-dom';
import { closeMiniBasket } from '../../../actions/miniBasketAction';
import toastr from 'toastr';

class MiniBasketItem extends React.Component {
    
  addToBasket(productOptionId, quantity) {
    this.props.addToBasket({
      productOptionId: productOptionId,
      quantity: quantity,
    },
    this.props.shoppingCart.orderId,
    );
  }

  doRemoveFromCart(productOptionId) {
    this.props.removeFromCart(productOptionId)
      .then((resp) => {
        if (resp.payload.Success) {
          let productItem = this.props.products.find(p => p.productOption && p.productOption.productOptionId == productOptionId || p.productId == productOptionId);
          if(productItem){
            toastr.success(`${productItem.title} has been removed from your basket.`);
          }
        } else if (resp.payload.ErrorMessage) {
          toastr.error(resp.payload.ErrorMessage);
        }
      });
  }

  navigateToProductDetails(product) {
    this.props.history.push(`${process.env.PUBLIC_URL}/product/${product.friendlyUrl}`);
    this.props.closeMiniBasket();
  }

  render() {
    const { product, products, quantityInCart, shoppingCart, isAddedToCart, loading } = this.props;

    var productItem = product;
    if (product.productOptionId > 0) {
      productItem = products.find(p => p.productOption && p.productOption.productOptionId == product.productOptionId);
    }
    return (
      <div className="mini-basket-item">
        {
          loading &&
          <div className="spinner-wrapper"
            style={{ padding: '30px' }}>
            <Spinner show/>
          </div>
        }
        <div className="image-wrapper">
          <img
            src={product.imageUrl ? product.imageUrl : noImage} 
            alt={product.title} 
            onError={($event) => $event.target.src = noImage} 
            className="img-responsive"
            onClick={() => this.navigateToProductDetails(product)}
          />
        </div>
        <div className="basket-detail-wrapper">
          <div className="name-wrapper">
            <div className="name"
              onClick={() => this.navigateToProductDetails(product)}>
              {product.title}
            </div>
          </div>
          <ProductQuantity
            loading={loading}
            product={productItem}
            shoppingCart={shoppingCart}
            onAddToCart={(productOptionId, quantity) => this.addToBasket(productOptionId, quantity)}
            onRemoveFromCart={(productOptionId) => this.doRemoveFromCart(productOptionId)}
            isAddedToCart={isAddedToCart}
            quantityInCart={quantityInCart}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.foodBoxesShoppingCart.loading,
    products: state.products.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToBasket: (params, orderID) => dispatch(addToCart(params, orderID)),
    removeFromCart: (id) => dispatch(removeFromCart(id)),
    closeMiniBasket: () => dispatch(closeMiniBasket()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MiniBasketItem));