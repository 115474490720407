import * as actionType from './actionTypes';

const modalToggleSuccess = (modal) => {
  return {
    type: actionType.MODAL_TOGGLE_SUCCESS,
    modal,
  };
};

export const onModalToggle = (modal) => {
  return dispatch => {
    dispatch(modalToggleSuccess(modal));
  };
};

export const showBasketTray = (status) => {
  return {
    type: actionType.SHOW_BASKET_TRAY,
    status,
  };
};

export const toggleSiteWrapper = (status) => {
  return {
    type: actionType.TOGGLE_SITE_WRAPPER,
    status,
  };
};

export const updateTermsAndConditions = (data) => {
  return {
    type: actionType.FETCH_TERMS_AND_CONDITIONS,
    data,
  };
};

export const showDeliveryDatePicker = (status) => {
  return {
    type: actionType.SHOW_DELIVERY_DATE_PICKER,
    status,
  };
};