import { UPDATE_ACTIVE_NAV } from '../actions/navigationAction';

const initialState = {
  activeNav: null,
};

const navigationReducer = (state = initialState, action) => {
  switch(action.type) {
  case UPDATE_ACTIVE_NAV: {
    return {
      ...state,
      activeNav: action.payload,
    };
  }
  default:
    return state;
  }
};

export default navigationReducer;