import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import FloatingInput from '../../../common/floatingInput/FloatingInput';
import Spinner from '../../../common/spinner/Spinner';
import { updateUserData, updateUserDataLocally } from '../../../../actions/userActions';

class AccountNameField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      firstName: { value: props.user.firstName, error: '' },
      lastName: { value: props.user.lastName, error: '' },
      loading: false,
      editMode: false,
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.onInputFieldChanged = this.onInputFieldChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggleEditMode() {
    this.setState({
      firstName: { value: this.props.user.firstName, error: '' },
      lastName: { value: this.props.user.lastName, error: '' },
      editMode: !this.state.editMode,
    });
  }

  onInputFieldChanged(name, value) {
    this.setState({
      [name]: { value: value, error: false },
    });
  }

  nameIsValid(name) {
    let regex = /[a-zA-Z]$/;
    return regex.test(name);
  }

  handleValidation() {
    const { firstName, lastName } = this.state;
    let errorCount = 0;

    if (firstName.value.length <= 0) {
      firstName.error = 'First name is required';
      errorCount += 1;
    } else if (!this.nameIsValid(firstName.value)) {
      firstName.error = 'Must contain alphabets only';
      errorCount += 1;
    } else if (firstName.value.length < 2 || firstName.value.length > 20) {
      firstName.error = 'Must be between 2 and 20 characters long';
      errorCount += 1;
    }

    if (lastName.value.length <= 0) {
      lastName.error = 'Last name is required';
      errorCount += 1;
    } else if (!this.nameIsValid(lastName.value)) {
      lastName.error = 'Must contain alphabets only';
      errorCount += 1;
    } else if (lastName.value.length < 2 || lastName.value.length > 20) {
      lastName.error = 'Must be between 2 and 20 characters long';
      errorCount += 1;
    } 

    if (errorCount > 0) {
      this.setState({ firstName, lastName });
      return false;
    }
    return true;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      // let user = this.props.user;
      const { firstName, lastName } = this.state;
      let user = {
        ...this.props.user,
        firstName: firstName.value,
        lastName: lastName.value,
      };

      this.setState({ loading: true });
            
      this.props.updateUser(user)
        .then((resp) => {
          const { Success } = resp.payload;
          if (Success) {
            this.props.updateUserDataLocally(user);
            toastr.success('Name was updated successfully.');
            this.setState({
              editMode: false,
            });
          } else {
            toastr.error(this.props.error);
          }
        });
    }
  }

  render() {
    const { user, loading } = this.props;
    const { firstName, lastName, editMode } = this.state;

    return (
      <div className="account-details-field">
        <div className="non-edit-mode">
          <span className="icon-account" />
          <div className="text">{user.firstName} {user.lastName}</div>
          <button className="btn inverse-button edit-btn"
            onClick={this.toggleEditMode}
            disabled={loading}>
            {editMode ? 'Cancel' : 'Edit'}
          </button>
        </div>
        {editMode &&
                    <form name="createAccountForm"
                      className="edit-mode"
                      onSubmit={this.onSubmit}>
                      <div className="form-group double-input-fields">
                        <div className="left">
                          <FloatingInput
                            label="First name"
                            type="text"
                            id="firstName"
                            name="firstName"
                            error={firstName.error}
                            value={firstName.value}
                            inputChanged={this.onInputFieldChanged} />
                        </div>
                        <div className="right">
                          <FloatingInput
                            label="Last name"
                            type="text"
                            name="lastName"
                            error={lastName.error}
                            value={lastName.value}
                            inputChanged={this.onInputFieldChanged} />
                        </div>
                      </div>
                      <button type="submit"
                        className="btn btn-primary btn-block submit-btn spinner-button"
                        disabled={loading}>
                        <span className="text">Save</span>
                        <Spinner show={loading} />
                      </button>
                    </form>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
    user: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (params) =>  dispatch(updateUserData(params)),
    updateUserDataLocally: (user) => dispatch(updateUserDataLocally(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountNameField);