import React from 'react';
import { NavLink } from 'react-router-dom';
import Boat1 from '../../../../assets/images/pic1.jpg';

const AboutUs = () => {
  return (
    <div className="about-us"
      id="about-us">
      <div className="container">
        <div className="left">
          <div className="content">
            <div className="title">About Us</div>
            <p>
              Our dedicated team are passionate about fresh quality seafood coming only from sustainable sources, with full traceability, produced with the highest factory standards.
            </p>
            <p>
              Our team have over 40 years of experience in the seafood industry building partnerships with fishing vessels, suppliers and customers to supply the finest fish and shellfish which you can now order direct to your door.
            </p>
            <p>
              Our well invested production sites in Grimsby and Cornwall ensure the speediest catch to fork offering delivering quality, freshness and service.
            </p>
            <NavLink
              to={`${process.env.PUBLIC_URL}/register`}
              className="btn"
            >
              Get an Account
            </NavLink>
          </div>
        </div>
        <div className="right">
          <img src={Boat1}
            className="img-responsive"
            alt="bbs-boat"/>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;