import React from 'react';
import { connect } from 'react-redux';
import noImage from '../../../assets/images/placeholder-rectangle.png';
import toastr from 'toastr';
import ProductQuantity from '../productQuantity/ProductQuantity';
import { addToCart, removeFromCart } from '../../../actions/shoppingCartAction';
import { onModalToggle } from '../../../actions/commonActions';
import './ProductItem.css';

class ProductItem extends React.Component {
  constructor(){
    super();

    this.state = {};
  }

  addToBasket(productOptionId, quantity) {
    this.props.addToBasket({
      productOptionId: productOptionId,
      quantity: quantity,
    },
    this.props.shoppingCart.orderId,
    );
  }

  doRemoveFromCart(productOptionId) {
    this.props.removeFromCart(productOptionId)
      .then((resp) => {
        if (resp.payload.Success) {
          toastr.success(`${this.props.product.title} has been removed from your basket.`);
        } else if (resp.payload.ErrorMessage) {
          toastr.error(resp.payload.ErrorMessage);
        }
      });
  }

  getProductTitle() {
    const MAX_LENGTH = 45;
    const { product } = this.props;
    return (
      product.title && product.title.length > MAX_LENGTH ? `${product.title.substring(0, 44)}\u2026` : product.title
    );
  }    

  render() {
    const { product, isAddedToCart, quantityInCart, shoppingCart, onViewDetails, editMode, loading } = this.props;

    return (
      <div className={`${product.productId} recipe`}>
        <div 
          className="recipe-overlay"
          onClick={() => onViewDetails(product.friendlyUrl)}
        />
        <div className="image image-wrapper">
          <img
            src={product.imageUrl ? product.imageUrl : noImage} 
            alt={product.title} 
            onError={($event) => $event.target.src = noImage} 
            className="img-responsive"
          />
        </div>
        <div className="content-wrapper">
          <div className="details-wrapper">
            <div className="name-price-wrapper">
              <div className="name">{product.title}</div>
              <div className="price">
                <p>&pound;{product.price}</p>
              </div>
            </div>
            <div className="recipe-info">
              <p>Price Per {product.pricePer}</p>
              <p>SKU: {product.productCode}</p>
            </div>
          </div>
          <div className="action-wrapper">
            {
              editMode &&
              <ProductQuantity
                loading={loading}
                product={product}
                buttonText="Add to Order"
                shoppingCart={shoppingCart}
                onAddToCart={(productOptionId, quantity) => this.addToBasket(productOptionId, quantity)}
                onRemoveFromCart={(product) => this.doRemoveFromCart(product)}
                doLogin={() => this.props.toggleModal({ open: true, type: 'login' })}
                isAddedToCart={isAddedToCart}
                quantityInCart={quantityInCart}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    shoppingCart: state.foodBoxesShoppingCart.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToBasket: (params, orderID) => dispatch(addToCart(params, orderID)),
    removeFromCart: (id) => dispatch(removeFromCart(id)),
    toggleModal: (modal) => dispatch(onModalToggle(modal)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);
