import { combineReducers } from 'redux';
import user from './userReducer';
import deliveries, { loadingDeliveriesReducer as loadingDeliveries } from './deliveriesReducer';
import userAddresses from './userAddressesReducer';
import menuOpen from './mobileMenuReducer';
import { 
  modalToggleReducer as modal,
  toggleSiteWrapperReducer as showSiteWrapper, 
  fetchTermsAndConditionsReducer as termsAndConditions, 
  showDeliveryDatePickerReducer as showDeliveryDatePicker } from './commonReducers';
import products from './productsReducer';
import foodBoxesShoppingCart from './shoppingCartReducer';
import categories from './categoriesReducer';
import miniBasket from './miniBasketReducer';
import navigation from './navigationReducer';
import { toggleHeaderVisibilityReducer as showSiteHeader } from './headerReducer';
import filterOpen from './mobileFilterReducer';
import activeOrders from './activeOrdersReducer';
import deliveryDates from './deliveryDatesReducer';

const rootReducer = combineReducers({
  user,
  products,
  foodBoxesShoppingCart,
  deliveries,
  userAddresses,
  menuOpen,
  modal,
  showSiteWrapper,
  loadingDeliveries,
  termsAndConditions,
  showDeliveryDatePicker,
  categories,
  miniBasket,
  navigation,
  showSiteHeader,
  filterOpen,
  activeOrders,
  deliveryDates,
});

export default rootReducer;