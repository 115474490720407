import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import './FloatingTextArea.css';

class FloatingTextArea extends Component {
  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.onEnterKey = this.onEnterKey.bind(this);
  }

  componentDidMount() {
    for (let i = 0; i < $('.floating-textarea').length; i++) {
      let currentFluidInput = $('.floating-textarea')[i];

      // Add event listener to input fields
      $(currentFluidInput.getElementsByTagName('textarea')).focus('focus', function() {
        $(currentFluidInput.getElementsByTagName('label')).addClass('is-focused');
        $(currentFluidInput.getElementsByTagName('label')).removeClass('is-not-active');
      });
      $(currentFluidInput.getElementsByTagName('textarea')).focus('focus', function() {
        $(currentFluidInput.getElementsByTagName('label')).addClass('is-focused');
        $(currentFluidInput.getElementsByTagName('label')).removeClass('is-not-active');
      });
      $(currentFluidInput.getElementsByTagName('textarea')).blur('blur', function() {
        if ($(this)[0].value && $(this)[0].value.length <= 0) {
          $(currentFluidInput.getElementsByTagName('label')).removeClass('is-focused');
        } else if(!$(this)[0].value) {
          $(currentFluidInput.getElementsByTagName('label')).removeClass('is-focused');
        }
        $(currentFluidInput.getElementsByTagName('label')).addClass('is-not-active');
      });
    }
  }

  onInputChange(e) {
    this.props.inputChanged(this.props.name, e.target.value);
    this.setState({ isEmpty: false });
  }
    
  onEnterKey(e) {
    if (e.keyCode === 13 && e.which === 13) {
      if (this.props.enterKeyPressed) {
        this.props.enterKeyPressed();
      }
    }
  }

  render() {
    const { label, type, id, name, placeholder, value, error, disabled, maxLength } = this.props;

    return (
      <div className="floating-textarea">
        <label className={`${value && value.length > 0 ? 'is-focused' : ''}`}>{label}</label>
        <textarea 
          type={type} 
          id={id}
          name={name}
          placeholder={placeholder}
          className={`form-control ${error && error.length > 0 ? 'has-error': ''}`}
          value={value} 
          autoComplete={name === 'password' ? 'off' : name}
          disabled={disabled}
          onKeyUp={this.onEnterKey}
          onChange={this.onInputChange} 
          maxLength={maxLength}/>
        {error && error.length > 0 && <span className="error">{error}</span>}
      </div>
    );
  }
}

FloatingTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  inputChanged: PropTypes.func.isRequired,
};

export default FloatingTextArea;