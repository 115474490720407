import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import $ from 'jquery';
import ShoppingCartItems from '../common/shoppingCartItems/ShoppingCartItems';
import ShoppingCartSummary from '../common/shoppingCartSummary/ShoppingCartSummary';
import DeliveryDetails from './deliveryDetails/DeliveryDetails';
import PaymentDetail from './paymentDetail/PaymentDetail';
import { getPaymentRequest } from '../../actions/shoppingCartAction';
import Spinner from '../common/spinner/Spinner';
import './ShoppingCartPage.css';
import { updateActiveNav } from '../../actions/navigationAction';
import priceUtils from '../../shared/priceUtils';

class ShoppingCartPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      paymentRequest: null,
      discountCode: { value: '', error: '' },
      toggleMobileSummary: false,
    };

    this.onDeliveryDetailsUpdated = this.onDeliveryDetailsUpdated.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.updateActiveNav(this.props.location);

    document.title = 'Shopping cart - Big Blue Sea';
  }

  onDeliveryDetailsUpdated() {
    let shoppingCart = this.state.shoppingCart;
    shoppingCart.shoppingCartStep = 2;
    this.setState({ shoppingCart: shoppingCart });
  }

  onProceedToPaymentDetails() {
    this.props.getPaymentRequest()
      .then(resp => {
        this.setState({ 
          step: 2, 
          paymentRequest: resp.payload,
        });
      });
  }

  render() {
    const { step, paymentRequest, discountCode, toggleMobileSummary } = this.state;
    const { shoppingCart, loading } = this.props;
        
    return (
      <div className="shopping-cart-page-wrapper">
        <div className="contact-banner"></div>
        <div className="shopping-cart-page">
          <div className="cart-header">
            <ul>
              <li className={`delivery-step ${step === 1 || step > 1 ? 'active' : ''}`}
                onClick={() => this.setState({ shoppingCart: { step: Number(1) } })}>
                <span>Delivery</span>
                <span className={step <= 1 ? 'circle-icon' : 'checked-icon'} />
              </li>
              <li className={step === 2 || step > 2 ? 'active' : ''}>
                <span>Payment</span>
                <span className={step <= 2 ? 'circle-icon' : 'checked-icon'} />
              </li>
              <li className={step === 3 || step > 3 ? 'active' : ''}>
                <span>Confirmation</span>
              </li>
            </ul>
          </div>
                    
          {
            loading &&
            <div className="spinner-wrapper">
              <Spinner show
                fontSize={25} />
            </div>
          }
                    
          <div className="cart-content">
            <div className="left-side">
              {
                step === 1 && 
                <DeliveryDetails 
                  shoppingCart={shoppingCart} 
                  updateDeliveryDetails={this.onDeliveryDetailsUpdated} 
                  discountCode={discountCode} 
                  step={step}
                  savingDiscountCode={loading}
                  onProceedToPaymentDetails={() => this.onProceedToPaymentDetails()}
                />
              }
              {
                step === 2 && 
                <PaymentDetail
                  paymentRequest={paymentRequest}
                  onTryAgain={() => this.setState({ step: 1 })}
                />
              }
            </div>
            <div className={`right-side ${toggleMobileSummary ? 'show': ''}`}>
              <div className="basket-summary">
                <div className="checkout-header">
                  <div className="sub-title">Order summary</div>
                </div>
                <ShoppingCartSummary
                  showDiscount
                  shoppingCartStep={step}
                  isShoppingCart
                />
              </div>                         
              <div className="sub-title">
                Products
              </div>
              <ShoppingCartItems
                isShoppingCartPage={false}
                step={step}
              />
            </div>
          </div>
          <div className="mobile-basket-summary">
            <button className="btn mobile-summary-toggle"
              onClick={() => {
                if (this.state.toggleMobileSummary) {
                  $('body').css('overflow', 'auto');
                } else {
                  $('body').css('overflow', 'hidden');
                }
                return this.setState({ toggleMobileSummary: !toggleMobileSummary });
              }}>
              <div className="box-summary">Order Summary - {priceUtils.FormatCurrencyPence(shoppingCart.orderTotal)}</div>
              {
                shoppingCart.deliveryDate &&
                <div className="delivery-date">
                  Delivery date: {moment(shoppingCart.deliveryDate).format('dddd Do MMMM')}
                </div> 
              }
              <span className={toggleMobileSummary ? 'chevron-arrow-down-icon' : 'chevron-arrow-up-icon'} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shoppingCart: state.foodBoxesShoppingCart.data,
    loading: state.foodBoxesShoppingCart.loading,
    user: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentRequest: () => dispatch(getPaymentRequest()),
    updateActiveNav: (nav) => dispatch(updateActiveNav(nav)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartPage);
