import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import FloatingInput from '../../components/common/floatingInput/FloatingInput';
import Spinner from '../../components/common/spinner/Spinner';
import LoginLogo from '../../assets/images/login-logo.png';
import OrderlyLogo from '../../assets/images/orderly_logo.png';
import { login } from '../../actions/userActions';
import { toggleHeaderVisibility } from '../../actions/headerAction';
import { getCartForFirstDeliveryDate } from '../../actions/shoppingCartAction';
import { getProducts } from '../../actions/productsActions';
import { getProductCategories } from '../../actions/categoriesAction';

// Stylesheet
import './Login.css';
import { getDeliveryDates } from '../../actions/deliveryDatesActions';

class Login extends Component {
  constructor() {
    super();
        
    this.state = {
      username: { value: '', error: '' },
      password: { value: '', error: '' },
      loading: false,
      loginError: '',
      revealPassword: false,
      passwordResetCompleted: false,
      resettingPassword: false,
    };

    this.onInputFieldChanged = this.onInputFieldChanged.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.toggleHeaderVisibility(false);
  }

  componentWillUnmount() {
    this.resetForm();
    this.props.toggleHeaderVisibility(true);
  }

  resetForm() {
    this.setState({
      username: { value: '', error: '' },
      password: { value: '', error: '' },
      loading: false,
      loginError: '',
      revealPassword: false,
      passwordResetCompleted: false,
      resettingPassword: false,
    });
  }

  onInputFieldChanged(name, value) {
    this.setState({
      [name]: { value: value, error: false },
      loginError: '',
    });
  }

  emailIsValid(email) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return regex.test(email);
  }

  handleValidation() {
    const { username, password } = this.state;
    let errorCount = 0;

    if (username.value.length <= 0) {
      username.error = 'Email address is required';
      errorCount += 1;
    } else if(!this.emailIsValid(username.value)) {
      username.error = 'Invalid email address entered';
      errorCount += 1;
    }

    if (password.value.length <= 0) {
      password.error = 'Password is required';
      errorCount += 1;
    } 

    if (errorCount > 0) {
      this.setState({ username, password });
      return false;
    }
    return true;
  }

  onLogin(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      const { username, password } = this.state;
      let credentials = {
        emailAddress: username.value,
        password: password.value,
      };
      this.props.login(credentials)
        .then((resp) => {
          const { Success } = resp.payload;
          if (Success) { 
            this.props.history.push(process.env.PUBLIC_URL);
            this.props.getCartForFirstDeliveryDate();
            this.props.dispatchGetDeliveryDates();
            this.props.getProductCategories();
            this.props.getProducts();
          }
        })
        .catch((error) => {
          console.log('Login error', error);
        });
    }
  }

  render() {
    const { loading, error } = this.props;
    const { username, password, revealPassword } = this.state;

    return (
      <div className="bbs-login">
        <div className="login-container">
          <div className="left">
            <img src={LoginLogo}
              alt="Big Blue Sea Logo"/>
            <div className="text">
              <h2>Wholesale Ordering Portal</h2>
              <h4>Delivering Quality Sustainable Seafood to Businesses</h4>
            </div>
            <p className="orderly-info">
                            Powered by Orderly. Our supply chain technology drives responsibility, generating long-term economic value and sustainability.
              <a href="https://orderly.io/"
                className="login-link"
                target="blank"> Learn More About Orderly.</a>
            </p>
          </div>
          <div className="right">
            <div className="login-panel">
              <h3>Sign in</h3>
              <p>Please sign in to access the Order Management Portal.</p>
              <form name="loginForm"
                onSubmit={this.onLogin}>
                <FloatingInput
                  label="Email address"
                  type="email"
                  name="username"
                  error={username.error}
                  value={username.value}
                  inputChanged={this.onInputFieldChanged}
                  autoComplete="off" 
                />
                <div className="password-wrapper">
                  <FloatingInput
                    label="Password"
                    type={revealPassword ? 'text' : 'password'}
                    name="password"
                    error={password.error}
                    value={password.value}
                    inputChanged={this.onInputFieldChanged} 
                    autoComplete="off"/>
                  <div className="eye-icon"
                    onClick={() => this.setState({ revealPassword: !this.state.revealPassword })}>
                    <span className={revealPassword ? 'no-visibility-icon' : 'visibility-icon'} />
                  </div>
                </div>
                {
                  error && 
                  <div className={'alert alert-danger text-center'}>{error}</div>
                }
                <div className="forgot-password">
                  <p>
                    <NavLink to={`${process.env.PUBLIC_URL}/forgot-password`}>
                      Forgot Password?
                    </NavLink>
                  </p>
                </div>
                <p>By signing in you confirm that you accept our <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`}>Terms and Conditions</a> and <a href="https://my.morrisons.com/privacy-policy">Privacy Policy</a></p>
                <div className="button-wrapper">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn-block spinner-button primary-button"
                  >
                    <span className="text">Sign in</span>
                    <Spinner show={loading} />
                  </button>
                </div>
              </form>
              <div className="not-registered">
                <p>Need an account?</p>  
                <p> 
                  <NavLink to={`${process.env.PUBLIC_URL}/register`}>
                    Register
                  </NavLink>
                </p>
              </div>
            </div>
            <div className="orderly-logo">
              <a href="https://orderly.io/"
                className="login-link"
                target="blank">                         
                <img src={OrderlyLogo}
                  alt="Orderly logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (params) => dispatch(login(params)),
    toggleHeaderVisibility: (status) => dispatch(toggleHeaderVisibility(status)),
    getCartForFirstDeliveryDate: () => dispatch(getCartForFirstDeliveryDate()),
    getProductCategories: () => dispatch(getProductCategories()),
    getProducts: () => dispatch(getProducts()),
    dispatchGetDeliveryDates: () => dispatch(getDeliveryDates()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));