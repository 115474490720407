import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Spinner from '../common/spinner/Spinner';
import ProductsPage from './ProductsPage';
import { openMiniBasket } from '../../actions/miniBasketAction';
import { clearShoppingCart } from '../../actions/shoppingCartAction';
import './Products.css';
import { updateActiveNav } from '../../actions/navigationAction';

class Products extends React.Component {
  constructor(){
    super();

    this.state = {
      shoppingCart: {
        deliveryDate: '',
        items: [],
      },
      pageLoaded: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.updateActiveNav(this.props.location);
  }

  render() {
    const { shoppingCart, loading, isCatalogue } = this.props;
    return (
      <div className="product-catalogue">
        {
          loading &&
          <div className="spinner-wrapper">
            <Spinner show
              fontSize={25}/>
          </div>
        }
        <ProductsPage
          shoppingCart={shoppingCart}
          onDeliveryDateChanged={(deliveryDate) => this.setState({
            shoppingCart: {
              ...shoppingCart,
              deliveryDate: deliveryDate,
            },
          })}
          onShowMiniBasket={() => this.props.openMiniBasket(shoppingCart)}
          isCatalogue={isCatalogue}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shoppingCart: state.foodBoxesShoppingCart.data,
    loading: state.foodBoxesShoppingCart.loading || state.user.loading || state.products.loading,
    user: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openMiniBasket: (shoppingCart) => dispatch(openMiniBasket(shoppingCart)),
    clearShoppingCart: () => dispatch(clearShoppingCart()),
    updateActiveNav: (nav) => dispatch(updateActiveNav(nav)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Products));