import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import FloatingInput from '../../../common/floatingInput/FloatingInput';
import Spinner from '../../../common/spinner/Spinner';
import { updateUserData, updateUserDataLocally } from '../../../../actions/userActions';

class AccountTelephoneField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      phone: { value: props.user.telephoneNumber, error: '' },
      loading: false,
      editMode: false,
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.onInputFieldChanged = this.onInputFieldChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggleEditMode() {
    this.setState({
      phone: { value: this.props.user.telephoneNumber, error: '' },
      editMode: !this.state.editMode,
    });
  }

  onInputFieldChanged(name, value) {
    this.setState({
      [name]: { value: value, error: false },
    });
  }

  phoneNumberIsValid(phoneNumber) {
    let regex = /^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}|\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4}|\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3})|\d{5}\)?[\s-]?\d{4,5}|8(?:00[\s-]?11[\s-]?11|45[\s-]?46[\s-]?4\d))(?:(?:[\s-]?(?:x|ext\.?\s?|#)\d+)?)$/;
    return regex.test(phoneNumber);
  }

  handleValidation() {
    const { phone } = this.state;
    let errorCount = 0;

    if (phone.value.length <= 0) {
      phone.error = 'Phone number is required';
      errorCount += 1;
    } else if (!this.phoneNumberIsValid(phone.value)) {
      phone.error = 'Invalid phone number entered';
      errorCount += 1;
    }

    if (errorCount > 0) {
      this.setState({ phone });
      return false;
    }
    return true;
  }

  onSubmit(e) {
    e.preventDefault();        
    if (this.handleValidation()) {
      const { phone } = this.state;
      let user = {
        ...this.props.user,
        telephoneNumber: phone.value,
      };

      this.setState({ loading: true });
      this.props.updateUser(user)
        .then((resp) => {
          const { Success } = resp.payload;
          if (Success) {
            this.props.updateUserDataLocally(user);
            toastr.success('Phone number was updated successfully.');
            this.setState({
              editMode: false,
            });
          } else {
            toastr.error(this.props.error);
          }
        });
    }
  }

  render() {
    const { user, loading } = this.props;
    const { phone, editMode } = this.state;

    return (
      <div className="account-details-field">
        <div className="non-edit-mode">
          <span className="icon-phone" />
          <div className="text">{user.telephoneNumber}</div>
          <button className="btn inverse-button edit-btn" 
            onClick={this.toggleEditMode}
            disabled={loading}>
            {editMode ? 'Cancel' : 'Edit'}
          </button>
        </div>
        {editMode && 
                    <form name="createAccountForm"
                      className="edit-mode"
                      onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <FloatingInput
                          label="Phone"
                          type="text"
                          id="phone"
                          name="phone"
                          error={phone.error}
                          value={phone.value}
                          inputChanged={this.onInputFieldChanged} />
                      </div>
                      <button type="submit" 
                        className="btn btn-primary btn-block submit-btn spinner-button"
                        disabled={loading}
                        style={{ marginTop: '25px' }}>
                        <span className="text">Save</span>
                        <Spinner show={loading} />
                      </button>
                    </form>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
    user: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (params) =>  dispatch(updateUserData(params)),
    updateUserDataLocally: (user) => dispatch(updateUserDataLocally(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountTelephoneField);