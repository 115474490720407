import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearShoppingCart } from '../../../actions/shoppingCartAction';

class PaymentDetail extends Component {  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { paymentRequest } = this.props;
    if (paymentRequest.Success && paymentRequest.Status === 'FREE_COUPON') {
      this.props.clearShoppingCart();
    }
  }

  render() {
    const { paymentRequest } = this.props;
    return (
      <div className="payment-page-iframe">
        <div className="sub-title text-center">Secure Payment</div>
        {paymentRequest.Success && paymentRequest.Status !== 'FREE_COUPON' &&
                    <iframe 
                      src={paymentRequest.RedirectUrl} 
                      title="Payment gateway" 
                      height="800" 
                      width="100%"
                      style={{ border: 'none' }} />
        }
        {paymentRequest.Success && paymentRequest.Status === 'FREE_COUPON' &&
                    <div className="free-coupon-wrapper">
                      <div className="payment-success" />
                      <div className="success-text">Payment successful. Thank you for shopping with us.</div>
                      <div className="order-number-text">Your order number is: {paymentRequest.MerchantTransactionId}</div>
                    </div>
        }
        {!paymentRequest.Success && paymentRequest.Errors &&
                    <div>
                      <div className="alert alert-danger">
                        <label>Payment request Errors:</label>
                        {paymentRequest.Errors &&
                                <ul style={{ listStyle: 'none', padding: '0' }}>
                                  {paymentRequest.Errors.map((error, index) => <li key={index}>{error.Message}</li>)}
                                </ul>
                        }
                      </div>
                      <button type="button" 
                        className="btn inverse-button" 
                        onClick={() => this.props.onTryAgain()}>
                            Try again
                      </button>
                    </div>
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearShoppingCart: () => dispatch(clearShoppingCart()),
  };
};

export default connect(null, mapDispatchToProps)(PaymentDetail);