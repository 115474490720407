import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PanelGroup, Panel } from 'react-bootstrap';
import './Faq.css';
import FAQContent from './FAQContent';
import { updateActiveNav } from '../../actions/navigationAction';

class Faq extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      open: true,
      activeKey: '',
    };  
    
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    this.props.updateActiveNav(this.props.location);
    let hash = this.props.location.hash.replace('#', '');
    if (hash) {
      const element = document.querySelector(`#${hash}`);
      if (element) {
        this.setState({ activeKey: hash });
        element.scrollIntoView();
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  render() {
    return (
      <div className="faq">
        <div className="contact-banner">
        </div>
        <div className="faq-content container">
          <div className="faq-header">
            <h4>How can we help?</h4>
            <h1>FAQs</h1>
          </div>
          <PanelGroup
            accordion
            id="faqs"
            activeKey={this.state.activeKey}
            onSelect={this.handleSelect}
          >
            {FAQContent.map((faqContent, index) => 
              <Panel eventKey={faqContent.id || index}
                key={index}
                id={faqContent.id ? faqContent.id : ''}
                defaultactivekey="ageVerification">
                <Panel.Heading className="question">
                  <Panel.Title toggle><h3>{faqContent.title}</h3></Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <div dangerouslySetInnerHTML={{ __html: faqContent.body }} />
                </Panel.Body>
              </Panel>,
            )}
          </PanelGroup>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveNav: (nav) => dispatch(updateActiveNav(nav)),
  };
};

export default connect(null, mapDispatchToProps)(Faq);
