import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import FloatingInput from '../../../common/floatingInput/FloatingInput';
import AppDialog from '../../../common/dialog/AppDialog';
import { requestResetPassword } from '../../../../actions/userActions';
import Spinner from '../../../common/spinner/Spinner';
import './PasswordUpdate.css';

class PasswordUpdate extends Component {
  constructor() {
    super();

    this.state = {
      editMode: false,
      currentPassword: '',
      password: '',
      confirmPassword: '',
      error: {
        value: false,
        message: '',
      },
      dialog: {
        title: '',
        message: '',
        isOpen: false,
      },
      resettingPassword: false,
    };

    this.onPasswordInputChange = this.onPasswordInputChange.bind(this);
    this.cancelPasswordEdit = this.cancelPasswordEdit.bind(this);
    this.onSavePassword = this.onSavePassword.bind(this);
    this.onShowResetDialog = this.onShowResetDialog.bind(this);
    this.onDialogConfirm = this.onDialogConfirm.bind(this);
  }

  onPasswordInputChange(name, value) {
    this.setState({ [name]: value });
  }

  cancelPasswordEdit() {
    this.setState({
      editMode: false,
      currentPassword: '',
      password: '',
      confirmPassword: '',
    });
  }

  onShowResetDialog() {
    let dialog = this.state.dialog;
    dialog.title = 'Change password';
    dialog.message = `Are you sure you want to change the password? A password reset email will be sent to: ${this.props.user.emailAddress}? `;
    dialog.isOpen = true;
    this.setState({ dialog: dialog });
  }

  onDialogConfirm(status) {
    if (status) {
      this.onResetPassword();
    }
    this.resetDialog();
  }

  resetDialog() {
    this.setState({
      dialog: {
        title: '',
        message: '',
        isOpen: false,
      },
    });
  }

  onSavePassword() {
    const { password, confirmPassword, error } = this.state;
        
    // Check password and confirm password match.
    if (!password) {
      error.value = true;
      error.message = 'Password is required';
      this.setState({ error: error });
      return;
    }
    else if (password !== confirmPassword) {
      error.value = true;
      error.message = 'Passwords do not match';
      this.setState({ error: error });
      return;
    }
  }

  onResetPassword() {
    this.props.requestResetPassword(this.props.user.emailAddress)
      .then((resp) => {
        const { Success, ErrorMessage } = resp.payload;
        if (Success) {
          toastr.success('Password reset email sent successfully.');
        } else {
          toastr.error(ErrorMessage);
        }
      })
      .catch(() => {
        console.log('Reset password error');
        toastr.error('Failed to reset password');
      });
  }

  render() {
    const { editMode, dialog, loading } = this.state;
    return (
      <div className="change-password">
        <h4>Change password</h4>
        <p className={editMode ? 'hide' : ''}>If you need to change your password, click below - we&apos;ll send you instructions on how to amend it.</p>
        <div className={`password-edit-panel ${!editMode ? 'hide' : ''}`}>
          <FloatingInput
            label="Current password"
            type="password"
            name="currentPassword"
            value={this.state.currentPassword}
            inputChanged={this.onPasswordInputChange} />
          <FloatingInput
            label="New password"
            type="password"
            name="password"
            value={this.state.password}
            inputChanged={this.onPasswordInputChange} />
          <FloatingInput
            label="Confirm new password"
            type="password"
            name="confirmPassword"
            value={this.state.confirmPassword}
            inputChanged={this.onPasswordInputChange} />
        </div>
        <div className="password-edit-buttons">
          <button className="btn inverse-button change-btn spinner-button"
            disabled={loading}
            onClick={this.onShowResetDialog}>
            <span className="text">Change password</span>
            <Spinner show={loading}
              width="15px"
              height="15px" />
          </button>
          <button className={`btn inverse-button cancel ${!editMode ? 'hide' : ''}`}
            onClick={this.cancelPasswordEdit}>Cancel</button>
          <button className={`btn ${!editMode ? 'hide' : ''}`}
            onClick={this.onSavePassword}>Save</button>
        </div>
        {dialog.isOpen && 
                    <AppDialog title={dialog.title}
                      message={dialog.message}
                      onConfirm={this.onDialogConfirm} />
        } 
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
    user: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestResetPassword: (email) => dispatch(requestResetPassword(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordUpdate);