import React from 'react';
import FloatingInput from '../floatingInput/FloatingInput';

const AddressEntry = ({ address, handleInputChange }) => (
  <div className="delivery-address">
    {address.recipientName &&
      <div className="form-group">
        <FloatingInput
          label="Recipient Name"
          type="text"
          name="recipientName"
          error={address.recipientName.error}
          value={address.recipientName.value}
          inputChanged={(name, value) => handleInputChange(name, value)} />
      </div>
    }
    {address.firstName && address.lastName &&
      <div className="form-group double-input-fields">
        <div className="left">
          <FloatingInput
            label="First Name"
            type="text"
            name="firstName"
            error={address.firstName.error}
            value={address.firstName.value}
            inputChanged={(name, value) => handleInputChange(name, value)} />
        </div>
        <div className="right">
          <FloatingInput
            label="Last Name"
            type="text"
            name="lastName"
            error={address.lastName.error}
            value={address.lastName.value}
            inputChanged={(name, value) => handleInputChange(name, value)} />
        </div>
      </div>
    }
    {address.line1 &&
      <div className="form-group">
        <FloatingInput
          label="Address line 1"
          type="text"
          name="line1"
          error={address.line1.error}
          value={address.line1.value}
          inputChanged={(name, value) => handleInputChange(name, value)} />
      </div>
    }
    {address.line2 &&
      <div className="form-group">
        <FloatingInput
          label="Address line 2"
          type="text"
          name="line2"
          error={address.line2.error}
          value={address.line2.value}
          inputChanged={(name, value) => handleInputChange(name, value)} />
      </div>
    }
    {address.city &&
      <div className="form-group">
        <FloatingInput
          label="City"
          type="text"
          name="city"
          error={address.city.error}
          value={address.city.value}
          inputChanged={(name, value) => handleInputChange(name, value)} />
      </div>
    }
    {address.postcode &&
      <div className="form-group double-input-fields">
        <div className="left">
          <FloatingInput
            label="Postcode"
            type="text"
            name="postcode"
            error={address.postcode.error}
            value={address.postcode.value}
            inputChanged={(name, value) => handleInputChange(name, value)} />
        </div> 
      </div>
    }
  </div>
);

export default AddressEntry;
