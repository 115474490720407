import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Route } from 'react-router-dom';
import * as deliveriesActions from '../../actions/deliveriesActions';
import './Account.css';

import AccountDetailsPage from './details/AccountDetailsPage';
import OrderHistory from './deliveries/OrderHistory';
import UpcomingOrders from './deliveries/UpcomingOrders';
import AddressDetails from './details/addressDetails/AddressDetails';
import Spinner from '../common/spinner/Spinner';
import { updateActiveNav } from '../../actions/navigationAction';

class Account extends Component {    
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.updateActiveNav(this.props.location);
    document.title = 'Account - Big Blue Sea';
  }

  render() {
    const { match, loading, loadingDeliveries, loadingSubscriptions } = this.props;
    return(
      <div className="account-details">
        <div className="contact-banner"/>
        <div className="container">
          <div className="account-wrapper">
            <nav>
              <ul>
                <li><NavLink exact
                  to={`${process.env.PUBLIC_URL}/account`}
                  activeclassname="active">My Details</NavLink></li>
                <li><NavLink to={`${process.env.PUBLIC_URL}/account/delivery-addresses`}
                  activeclassname="active">Delivery Addresses</NavLink></li>
                <li><NavLink to={`${process.env.PUBLIC_URL}/account/deliveries`}
                  activeclassname="active">Order History</NavLink></li>
                <li><NavLink to={`${process.env.PUBLIC_URL}/account/upcoming-orders`}
                  activeclassname="active">Upcoming Orders</NavLink></li>
              </ul>
            </nav>
            <div className="account-content">
              {
                loading || 
                loadingDeliveries || 
                loadingSubscriptions &&
                <div className="spinner-wrapper">
                  <Spinner show
                    fontSize={25} />
                </div>
              }
              <Route exact
                path={`${match.url}/deliveries`}
                component={OrderHistory}/>
              <Route exact
                path={`${match.url}/upcoming-orders`}
                component={UpcomingOrders}/>
              <Route exact
                path={`${match.url}/delivery-addresses`}
                component={AddressDetails} />                                   
              <Route exact
                path={`${match.url}/`}
                component={AccountDetailsPage} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    loading: state.user.loading,
    loadingDeliveries: state.loadingDeliveries,
    loadingSubscriptions: state.loadingSubscriptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveries: () => dispatch(deliveriesActions.getDeliveries()),
    updateActiveNav: (nav) => dispatch(updateActiveNav(nav)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);