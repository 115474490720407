import {
  OPEN_MINI_BASKET,
  CLOSE_MINI_BASKET,
} from '../actions/miniBasketAction';

const initialState = {
  show: false,
  shoppingCart: {
    items: [],
  },
};

const MiniBasketReducer = (state = initialState, action) => {
  switch (action.type) {
  case OPEN_MINI_BASKET: {
    return {
      ...state,
      show: true,
      shoppingCart: action.payload,
    };
  }
  case CLOSE_MINI_BASKET: {
    return {
      ...initialState,
    };
  }
  default: {
    return state;
  }
  }
};

export default MiniBasketReducer;
