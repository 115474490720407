import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import { closeMiniBasket } from '../../../actions/miniBasketAction';
import MiniBasketItem from './MiniBasketItem';
import './MiniBasket.css';
import { clearShoppingCart, removeFromCart } from '../../../actions/shoppingCartAction';
import priceUtils from '../../../shared/priceUtils';

class MiniBasket extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      shoppingCart: props.shoppingCart,
      loading: false,
      dialog: {
        title: '',
        message: '',
        type: '',
        isOpen: false,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shoppingCart !== this.props.shoppingCart) {
      this.setState({ shoppingCart: this.props.shoppingCart });
    }
    if (this.props.showMiniBasket) {
      $('html').addClass('modal-open');
      document.addEventListener('keydown', this.handleOnKeyDown);
    } else {
      $('html').removeClass('modal-open');
      document.removeEventListener('keydown', this.handleOnKeyDown);
    }
  }

  handleOnKeyDown (e) {
    if (e.key === 'Escape') {
      this.props.closeMiniBasket();
    }
  }

  onClosePanel() {
    this.props.closeMiniBasket();
  }

  getItemQuantityInCart(recipe) {
    const { shoppingCart } = this.props;
    const existingRecipe = shoppingCart.items.find(item => item.recipeBoxID === recipe.recipeBoxID) || null;
    return existingRecipe ? existingRecipe.quantity : 0;
  }

  getBasketTotal() {
    const { shoppingCart } = this.props;
    return shoppingCart.items.reduce((a, b) => {
      return a + (b.price * b.quantity);
    }, 0);
  }

  onClearBasket() {
    this.props.clearShoppingCart();
    this.props.closeMiniBasket();
  }

  onConfirmBasket() {    
    this.setState({ loading: true });
    this.props.history.push(`${process.env.PUBLIC_URL}/shopping-cart`);
    this.props.closeMiniBasket();
  }

  shoppingCartIsValid() {
    const { shoppingCart } = this.props;
    let errorCount = 0;

    if (!shoppingCart.deliveryDate) {
      errorCount += 1;
    }

    return errorCount <= 0;
  }

  render() {
    const { showMiniBasket, shoppingCart } = this.props;

    return (
      <div className={`mini-basket ${showMiniBasket ? 'show' : ''}`}>
        <div className="underlay"
          onClick={() => this.onClosePanel()} />
        <div className={`slide-out-panel ${shoppingCart.editingExistingOrder ? 'editing' : ''}`}>
          <div className="panel-header">            
            <div className="left">Your Order</div>
            <div className="right"
              onClick={() => this.onClosePanel()}>
              <span className="icon icon-times"></span>
            </div>
          </div>
          <button
            className="btn clear-basket"
            onClick={() => this.onClearBasket()}
          >
            Clear Basket
          </button>
          <div className="recipes-wrapper">
            {
              shoppingCart.items && 
              shoppingCart.items.map((item, index) => 
                <MiniBasketItem
                  key={index}
                  product={item}
                  quantityInCart={this.getItemQuantityInCart(item)}
                  shoppingCart={shoppingCart}
                />,
              )
            }
            {
              shoppingCart.items && 
              shoppingCart.items.length <= 0 &&
              <div className="basket-warning">
                <span className="icon icon-info2" />
                <h4 className="text">
                  Basket empty. Add products to checkout
                </h4>
              </div>
            }
          </div>
          {
            showMiniBasket && 
            shoppingCart.items && 
            shoppingCart.items.length > 0 &&
            <div className="confirm-basket">
              <div className="button-wrapper">
                <button 
                  className="btn browse-recipes"
                  onClick={() => this.onClosePanel()}
                >
                  Back
                </button>
                {
                  !shoppingCart.editingExistingOrder &&    
                  <button
                    className="btn continue-to-checkout"
                    onClick={() => this.onConfirmBasket()}
                  >
                    {
                      shoppingCart.items.length > 0 
                        ? `${priceUtils.FormatCurrencyPence(shoppingCart.priceToPay)} - ` 
                        : ''
                    }
                      Continue to Checkout
                  </button>
                }
                {
                  shoppingCart.editingExistingOrder && 
                  shoppingCart.orderTotal > shoppingCart.originalOrderValue &&
                  <button
                    disabled={!this.shoppingCartIsValid()}
                    className="btn continue-to-checkout"
                    onClick={() => this.onConfirmBasket()}
                  >
                    Update Order
                  </button>
                }
                {
                  shoppingCart.editingExistingOrder && 
                  shoppingCart.orderTotal < shoppingCart.originalOrderValue &&
                  <button
                    disabled={!this.shoppingCartIsValid()}
                    className="btn continue-to-checkout"
                    onClick={() => this.onConfirmBasket()}
                  >
                    Process Refund
                  </button>
                }
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showMiniBasket: state.miniBasket.show,
    shoppingCart: state.foodBoxesShoppingCart.data,
    loading: state.foodBoxesShoppingCart.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeMiniBasket: () => dispatch(closeMiniBasket()),
    clearShoppingCart: () => dispatch(clearShoppingCart()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MiniBasket));
