import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ProfileImage.css';
import uploadFileToBlob, { baseUrl } from './image-upload.js';
import { BiUser } from 'react-icons/bi';
import { updateUserData, updateUserDataLocally } from '../../../../../actions/userActions';
import toastr from 'toastr';

class ProfileImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputKey: Math.random().toString(36),
      user: null,
      userProfileImageUrl: '',
      uploading: false,
    };

    this.onFileChange = this.onFileChange.bind(this);
  }

  async onFileChange(event) {
    var selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.type.includes('image')) {
      var blob = selectedFile.slice(0, selectedFile.size, selectedFile.type); 
      var subFolderName = this.props.user.userId;
      var newFile = new File([blob], `${subFolderName}/${selectedFile.name}`, { type: selectedFile.type });

      await uploadFileToBlob(newFile);

      let user = {
        ...this.props.user,
        userProfileImageUrl: `${baseUrl}/${subFolderName}/${selectedFile.name}`,
      };
      
      this.props.updateUser(user).then((resp) => {
        const { Success } = resp.payload;
        if (Success) {
          this.props.updateUserDataLocally(user);
        } else {
          toastr.error(this.props.error);
        }
      });

      this.setState({ user: user });
    } else {
      toastr.error('You can only upload image files');
    }
  }
    
  browseImage() {
    document.getElementById('profile-image-input').click();
  }
    
  render() {
    const { inputKey, user } = this.props;
    return (
      <div className="account-details-field">
        <div className="profile-image text"><h4>Profile Image</h4></div>
        <input type="file"
          id="profile-image-input"
          accept="image/*"
          onChange={this.onFileChange}
          key={inputKey || ''} />
        <button className="btn inverse-button"
          id="profile-image-browse"
          onClick={this.browseImage}>Browse</button>
        <div className="profile-image-display">
          { 
            user.userProfileImageUrl && 
            <img id="profileImage"
              alt="Profile Image"
              src={user.userProfileImageUrl}
              onClick={this.browseImage}/>
          }
          { 
            !user.userProfileImageUrl && 
            <BiUser onClick={this.browseImage}/>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inputKey: state.inputKey,
    user: state.user.data,
    userProfileImageUrl: state.userProfileImageUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (params) =>  dispatch(updateUserData(params)),
    updateUserDataLocally: (user) => dispatch(updateUserDataLocally(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImage);