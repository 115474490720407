import React from 'react';
import Product from './Product';

const Products = () => {
  return (
    <div className="products"
      id="products">
      <div className="container">
        <div className="title">Most Popular</div>
      </div>
      <div className="container">
        <div id="products-menu">
          {categories.map((category) => (
            <div className="react-tabs__tab-panel"
              key={category.name}
              category={category}>
              {category.products.map((product) => (<Product key={product.name}
                product={product}/>))}
            </div>))
          } 
        </div>
      </div>
    </div>
  );
};

export default Products;

const categories = [
  {
    name: 'Most Popular',
    products: [
      {
        name: 'Scallop',
        desc: 'King Scallops - in half shell or meat only.',
      },
      {
        name: 'Monkfish',
        desc: 'Monkfish fillets.',
      },
      {
        name: 'Seabass',
        desc: 'Seabass fillets.',
      },
      {
        name: 'Salmon',
        desc: 'Salmon portions.',
      },
      {
        name: 'Hot Smoked Fish',
        desc: 'Hot smoked salmon or mackerel with a range of flavours.',
      },
      {
        name: 'Prawns',
        desc: 'Cold, warm water or raw king prawns.',
      },
    ],
  },
];