import React from 'react';
import './Spinner.css';

const Spinner = ({ show = false, color= '#0077BE', fontSize= '16px' }) => {
  return (
  // <span className={`spinner ${!show ? 'hide' : ''}`}
  //     style={{
  //         width: width,
  //         height: height,
  //         fill: color
  //     }} />
    <span className={`spinner-icon icon-spinner ${!show ? 'hide' : ''}`} 
      style={{
        color: color, 
        fontSize: fontSize,
      }} />
  );
};

export default Spinner;